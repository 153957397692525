import { useState } from 'react';
import { Input } from 'antd';

const TextAreaField = ( props ) => {
  const {
    autoSize = { minRows: 1, maxRows: 1 },
    disabled,
    charLimit,
    placeholder,
    value,
    onBlur,
    onChange,
    onFocus,

    name,
  } = props;

  const renderCountDisplay = ( count, maxLength ) => `${ count }/${ maxLength }`;

  const [ textAreaStatus, setTextAreaStatus ] = useState();

  if ( charLimit > 0 ) {
    return (
      <Input.TextArea
        autoSize={ autoSize }
        className={ `StringFieldForm${textAreaStatus === 'error' ? ' error' : ''}` }
        disabled={ disabled }
        value={ value }
        onChange={ onChange }
        placeholder={ placeholder }
        maxLength={ charLimit }
        status={ textAreaStatus }
        showCount={{
          formatter: ({ value, count, maxLength }) => {
            if (count === maxLength) {
              setTextAreaStatus(() => 'error');
              return `Character limit reached. ${ renderCountDisplay(count, maxLength) }`;
            }
            if (textAreaStatus === 'error') setTextAreaStatus(() => undefined);
            return renderCountDisplay(count, maxLength);
          }
        }}
        onBlur={ onBlur }
        onFocus={ onFocus }

        name={ name }
        data-label={ props['data-label'] }
        data-value={ props['data-value'] }
        data-access={ props['data-access'] }
      />
    );
  }
  if (autoSize.maxRows > 1) {
    return (
      <Input.TextArea
        autoSize={ autoSize }
        className='StringFieldForm'
        disabled={ disabled }
        value={ value }
        placeholder={ placeholder }
        onChange={ onChange }
        onBlur={ onBlur }
        onFocus={ onFocus }

        name={ name }
        data-label={ props['data-label'] }
        data-value={ props['data-value'] }
        data-access={ props['data-access'] }
      />
    );
  }
  return (
    <Input
      disabled={ disabled }
      value={ value }
      placeholder={ placeholder }
      onBlur={ onBlur }
      onChange={ onChange }
      onFocus={ onFocus }

      name={ name }
      data-label={ props['data-label'] }
      data-value={ props['data-value'] }
      data-access={ props['data-access'] }
    />
  );
}

export default TextAreaField;
