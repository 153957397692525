import { SiteAdmin } from "./SiteAdmin";

export const SiteAdminContainer = (props) => {
  const {
    portalUtils,
    siteAdminData,
    resetSiteAdminData
  } = props;

  if (!siteAdminData || !siteAdminData.data) {
    return null;
  }

  const {
    protocol_id,
    protocol_number,
    site_name,
    site_id,
    pi_name,
    given_site_id,
    ctms_id,
    country_code,
    version_history,
    version,
    site_icf_version,
    icf_version,
    country_list,
    is_edit_allowed
  } = siteAdminData.data;
  const siteAdminComponent = (
    <SiteAdmin
      protocolId={protocol_id}
      protocolNumber={protocol_number}
      siteName={site_name}
      siteId={site_id}
      PIName={pi_name}
      ctmsId={ctms_id}
      countryCode={country_code}
      givenSiteId={given_site_id}
      versionHistory={version_history}
      version={version}
      siteIcfVersions={site_icf_version}
      icfVersion={icf_version}
      countryList={country_list}
      isEditAllowed={is_edit_allowed}
    />
  );

  return portalUtils.attachSiteAdminContent(
    siteAdminComponent,
    siteAdminData.data,
    resetSiteAdminData,
  );
};
