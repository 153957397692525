import { useEffect, useState } from 'react';
import { displayNotification } from '../lib/notificationUtils';

export const NotificationComponent = props => { 
  const { classic } = props;
  const [ notificationData, setNotificationData ] = useState(null);

  useEffect(() => {
    if (!classic) {
      return;
    }

    classic.subscribe('NotificationComponent',
                      'notificationData', setNotificationData );
  }, [ classic, setNotificationData ]);

  if(!notificationData) {
    return null;
  }
  
  const { message, type, title, duration, notificationOptions} = notificationData;

  return displayNotification(message, type, title, duration, notificationOptions);
};
