import { useEffect, useState } from 'react';
import {
  findFieldReviewRole,
  FieldReviewRole,
} from '../../lib/roleUtils';
import { FieldReviewerButton } from './FieldReviewerButton';
import { Divider } from 'antd';
import { FieldReviewBadgeText, FieldReviewAction } from './constants';

export const FieldReviewBadge = props => {
  const {
    context,
    userData,
    isFieldReviewEnabled,
    fieldReview,
    protocolUserRole,
    fieldDefinitionId,
    isActive,
   } = props;

  const [isFieldReviewRoleReviewer, setIsFieldReviewRoleReviewer] = useState(false);
  const [fieldReviewRole, setFieldReviewRole] = useState(null);

  useEffect(() => {
    if (protocolUserRole) {
      setFieldReviewRole(() => findFieldReviewRole(protocolUserRole));
    }
  }, [protocolUserRole]);

  useEffect(() => {
    if (fieldReviewRole) {
      setIsFieldReviewRoleReviewer(() => fieldReviewRole === FieldReviewRole.Reviewer);
    }
  }, [fieldReviewRole]);

  if (!isFieldReviewEnabled || !fieldReviewRole) {
    return null;
  }

  const isFieldMarkedReviewed =
    fieldReview?.payload?.actions?.[0]?.action === FieldReviewAction.Review;

  const viewerText = isFieldMarkedReviewed ? FieldReviewBadgeText.Reviewed : FieldReviewBadgeText.Unreviewed;

  const reviewerButton =
    <FieldReviewerButton
      context={context}
      userData={userData}
      protocolUserRole={protocolUserRole}
      fieldReviewRole={fieldReviewRole}
      isActive={isActive}
      fieldReview={fieldReview}
      isFieldMarkedReviewed={isFieldMarkedReviewed}
      fieldDefinitionId={fieldDefinitionId}
    />;

  return (
    <div className="field-review-button-container">
      <Divider type="vertical" />

      <div
          className={`field-review-button-container field-query-button reviewable-field-button${isFieldReviewRoleReviewer ? ' reviewer' : ''}`}
          id={`reviewable-field-button-${fieldDefinitionId}`}
          data-testid='reviewable-field-button'
      >
        {
          isFieldReviewRoleReviewer ? reviewerButton : viewerText
        }
      </div>
    </div>
  );
};

export default FieldReviewBadge;