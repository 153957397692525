import { useInfiniteQuery } from 'react-query';
import { useWebSocketRequest } from './useWebSocketRequest';
import { v4 as uuid } from 'uuid';

const endpointNames = [
  'gui/subjectList',
];

const fetchData = async (sendRequest, parameters) => {

  const { endpoint, context, paging, sort, filter } = parameters;
  const messageId = uuid();

  const data = {
    messageId,
    name: endpoint,
    context,
    paging: {
      maxResults: paging.maxResults,
      exclusiveStartKey: paging.exclusiveStartKey
        ? paging.exclusiveStartKey
        : null,
    },
    sort,
    filter,
  };

  const response = await sendRequest(JSON.stringify(data));

  if (!response) {
    return;
  }

  const result = JSON.parse(response);

  return result;
};

const buildQueryKey = (generalParameters, pageSize ) => {

  const { endpoint, context, sort, filter } = generalParameters;

  return [
    endpoint,
    context,
    sort,
    filter,
    pageSize,
  ];
};

const fetchPage = async (sendRequest, generalParameters, paging) => {

  const { endpoint, context, sort, filter } = generalParameters;
  const { pageNumber, pageSize, lastKey } = paging;

  if (pageNumber < 1) {
    throw new Error(`Illegal pageNumber '${pageNumber}'`);
  }

  const response = await fetchData(sendRequest, {
    endpoint,
    context,
    paging: {
      exclusiveStartKey: lastKey ? lastKey : null,
      maxResults: pageSize,
    },
    sort,
    filter,
  });

  return { items: response.items, paging: response.paging };
};

export const useInfiniteDataRequest = ({
  endpoint,
  context,

  sort,
  filter,

  pageSize,

  config,
}) => {
  const { sendRequest } = useWebSocketRequest();

  if (!endpointNames.includes(endpoint)) {
    throw new Error(`unrecognized endpoint name '${endpoint}'`);
  }

  const queryKey = buildQueryKey(
    { endpoint, context, sort, filter },
    pageSize,
  );
  
  const queryFunction = async ({ pageParam }) => {

    const data = await fetchPage(
      sendRequest,
      {
        endpoint,
        context,
        sort,
        filter,
      },
      {
        pageNumber : pageParam?.pageNumber || 1,
        lastKey: pageParam?.lastKey,
        pageSize,
      }
    );    
    return data;
  }; // queryFunction

  const result = useInfiniteQuery(queryKey, queryFunction, {
    ...config,
    getNextPageParam: (lastPage, pages) => {

      if (!lastPage?.paging?.lastEvaluatedKey) {
        return undefined;
      }

      return {
        pageNumber: pages?.length + 1,
        lastKey: lastPage?.paging?.lastEvaluatedKey,
      };
    },
    keepPreviousData: true,
  });

  return result;
};
