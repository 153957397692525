import { ProtocolComponents } from "./protocol/ProtocolComponents";
import { VersionComponents } from "./version/VersionComponents";
import { IcfComponents } from "./icf/IcfComponents";
import { SiteAdminComponents } from "./sites/SiteAdminComponents";
import { ApiScopeComponents } from "./apiScope/apiScopeEditor/ApiScopeComponents";
import { ApiScopeAssociateComponents } from "./apiScope/apiScopeAssociate/ApiScopeAssociateComponents";

export const AdminComponents = props => {
  const { classic, portalUtils } = props;

  return (
    <>
      <ProtocolComponents classic={classic} portalUtils={portalUtils}/>
      <VersionComponents classic={classic} portalUtils={portalUtils} />
      <IcfComponents classic={classic} portalUtils={portalUtils} />
      <SiteAdminComponents classic={classic} portalUtils={portalUtils} />
      <ApiScopeComponents classic={classic} portalUtils={portalUtils} />
      <ApiScopeAssociateComponents classic={classic} portalUtils={portalUtils} />
    </>
  );
};
