import {useState, useEffect} from 'react';

import {
  Button,
  Checkbox,
  Tooltip,
  Table,
} from 'antd';

import {
  HistoryOutlined,
  InfoCircleOutlined,
  GlobalOutlined,
  FileOutlined,
} from '@ant-design/icons';

import ReactMarkdown from 'react-markdown';

import {
  isOkRoleForPerformanceGuidanceView,
  isOkRoleForPerformanceGuidanceModify,
} from '../../lib/roleUtils';

import { ReviewStatus } from '../../lib/dataUtils';
import { QueryStatus } from '../DataItemQueries';

import {
  saveGuidance,
  getFieldHistory,
  getApplicationEnvironment,
} from '../../legacy/LegacyFacade';

import './Field.scss';
import './Guidance.scss';

const GuidanceHistory = props => {

  const {
    isHistoryVisible,
    siteInfo,
    protocolVersionId,
    guidanceData,
  } = props

  const [ historyData, setHistoryData ] = useState(null);

  useEffect(() => {

    const fetchHistoryData = async () => {

      const newHistoryData = await getFieldHistory({
        protocolId: siteInfo.protocol_id,
        siteId: siteInfo.siteId,
        protocolVersionId,
        protocolVersionName: guidanceData.protocol_branch,
        fieldId: guidanceData.field_instance_id,
      });

      setHistoryData( newHistoryData );

    };

    if( !isHistoryVisible ){
      return;
    }

    if(historyData
       && historyData?.field?.data
       && historyData.field.data.length === 0
       ) {
      // history response is here, field has never been filled in
      return;
    }

    if(historyData && guidanceData
       && historyData.field.date_updated === guidanceData.data?.[0].date ){
      return;
    }

    fetchHistoryData();

  }, [
    guidanceData,
    protocolVersionId,
    siteInfo.protocol_id,
    siteInfo.siteId,
    isHistoryVisible,
    historyData,
  ]);

  if(!isHistoryVisible) {
    return null;
  }

  if(!historyData) {
    return null;
  }

  const displayDataEntryMode = raw => {
    if(!raw || raw === ''){
      return 'Transcription';
    }
    switch (raw) {
      case 'source': return 'Source';
      case 'transcription': return 'Transcription';
      default: return raw;
    }
    // eslint-disable-next-line no-unreachable
    throw new Error('Unreachable code!');
  };

  const columns = [
    {
      title: 'When',
      dataIndex: 'date',
      key: 'date',
      render: text => {
        return new Date(text).toLocaleString();
      },
    },
    {
      title: 'Who',
      dataIndex: 'user',
      key: 'user'
    },
    {
      title: 'Why',
      dataIndex: 'rfc',
      key: 'rfc',
      render: text => {
        return text || 'No Previous Value';
      },
    },
    {
      title: 'Data Entry Mode',
      dataIndex: 'demode',
      key: 'demode',
      render: text => {
        return displayDataEntryMode(text);
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: text => {
        return text === true ? 'checked' : 'unchecked';
      },
    }
  ];

  return (
    <Table
      className='History'
      dataSource={historyData?.field?.data ?? []}
      columns={columns}
      pagination={false}
      rowKey={record => record?.date}
    />
  );
};

const HistoryGuidance = props => {
  const {
    guidanceData,

    protocolVersionId,
    siteInfo,
    children,

    onBlur,
    onPointerEnter,
    onPointerLeave,

    className,
    isGuidanceValueChanged,
    isHistoryVisible,
  } = props;

  return (
    <div
      className={`${ className ? className + ' ' :''
        }HistoryField${
        isGuidanceValueChanged ? ' field-value-is-changed' : ''
        }`}
      onBlur={onBlur}
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
    >
      <div className='field-content'>
        {children}
      </div>
      <GuidanceHistory
        isHistoryVisible={isHistoryVisible}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        guidanceData={guidanceData}
      />
    </div>
    );
};

const downloadDocument = (fileName, protocolId, branchName) => {

  const environment = getApplicationEnvironment();

  const doc = {
    protocol_id: protocolId,
    branch_name: branchName,
    file_name: fileName
  };

  const bdoc = btoa(JSON.stringify(doc));
  const pdfurl = environment.rest + '/document/get/' + bdoc;

  window.open(pdfurl, 'pdfviewer');
};

const GuidanceIcons = props => {
  const {
    fieldDefinition,
    setIsHistoryVisible,
    isHistoryVisible,
    branchData,
    siteInfo,
  } = props;

  const isDocumentExists = documentName => {
    if( !branchData?.document_list ) {
      return false;
    }
    return documentName in branchData.document_list;
  };

  return <>
          {fieldDefinition.info && <Tooltip title={fieldDefinition.info}>
            <Button className='guidance-icon-button'>
              <InfoCircleOutlined />
            </Button>
          </Tooltip>}
          { isDocumentExists(fieldDefinition.document) &&
            <Button
              onClick={() => downloadDocument(fieldDefinition.document,siteInfo.protocol_id, branchData.name)}
              className='guidance-icon-button'>
                <FileOutlined />
            </Button>}
          {fieldDefinition.link &&
            <Button type='link'
                    target={fieldDefinition.link}
                    href={fieldDefinition.link}
                    className='guidance-icon-button'>
              <GlobalOutlined />
            </Button>}
          <Button className='guidance-icon-button'
                  onClick={() => {
                    setIsHistoryVisible(!isHistoryVisible);
                  }}>
            <HistoryOutlined />
          </Button>
        </>;
};

const GuidanceRowReadOnly = props => {
  const {
    fieldDefinition,
    fieldData,
    protocolVersionId,
    branchData,
    siteInfo,
  } = props;
  const [ isHistoryVisible, setIsHistoryVisible ] = useState(false);
  return (
    <HistoryGuidance
      key={'guidance-history-' + fieldDefinition.id}
      guidanceDefinition={fieldDefinition}
      guidanceData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      setIsHistoryVisible={setIsHistoryVisible}
      isHistoryVisible={isHistoryVisible}
    >
      <div
        className={(fieldData
                    && fieldData?.data?.length > 0
                    && fieldData?.data[0]?.value
                  ) ? 'isChecked' : 'isNotChecked'}
      >
        <div className='markdown-wrapper'>
          <ReactMarkdown className='react-markdown'>
            {fieldDefinition.text}
           </ReactMarkdown>
        </div>
        <GuidanceIcons
          fieldDefinition={fieldDefinition}
          setIsHistoryVisible={setIsHistoryVisible}
          siteInfo={siteInfo}
          branchData={branchData}
          isHistoryVisible={isHistoryVisible}
        />
      </div>
    </HistoryGuidance>
  );
};

const GuidanceRow = props => {
  const {
    fieldDefinition,
    fieldData,
    onChange,
    protocolVersionId,
    siteInfo,
    branchData,
  } = props;

  const [ isHistoryVisible, setIsHistoryVisible ] = useState(false);
  return (
    <HistoryGuidance
      key={'guidance-history-' + fieldDefinition.id}
      guidanceDefinition={fieldDefinition}
      guidanceData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      setIsHistoryVisible={setIsHistoryVisible}
      isHistoryVisible={isHistoryVisible}
    >
      <div
        className={( fieldData
                     && fieldData?.data?.length > 0
                     && fieldData?.data[0]?.value
                   )
                   ? 'isChecked'
                   : 'isNotChecked'}>
          <Checkbox
            id={fieldDefinition.id}
            checked={( fieldData
                       && fieldData?.data?.length > 0 )
                       && fieldData?.data[0]?.value
                     }
            onChange={e => onChange(e)}
          >
            <ReactMarkdown className='react-markdown'>
              {fieldDefinition.text}
            </ReactMarkdown>
          </Checkbox>
          <GuidanceIcons  fieldDefinition={fieldDefinition}
                          setIsHistoryVisible={setIsHistoryVisible}
                          branchData={branchData}
                          siteInfo={siteInfo}
                          isHistoryVisible={isHistoryVisible}
          />
      </div>
    </HistoryGuidance>
  );
};

const getTitle = type => {

  const titleLookup = {
    regular: 'Guidance',
    source: 'Source Guidance',
    cra: 'Monitoring Guidance',
    dm: 'Data Management Guidance', // not in protocol-configuration-schema.json
    safety: 'Safety Guidance',
  };

  return titleLookup[type] ?? `${type} Guidance`;

}

const GuidanceField = props => {

  const {

    fieldDefinition,

    procedureData,
    procedureSelection,

    protocolVersionId,
    siteInfo,
    branchData,

    isReadOnlyView,
    onChange,

  } = props;

  const fieldData = procedureData.fields.find(
    f => f.field_id === fieldDefinition.id
    && f.procedure_row === procedureSelection.index
  );

  const itemProps = {
    fieldDefinition,
    fieldData,
    protocolVersionId,
    siteInfo,
    branchData,
  };

  if(isReadOnlyView) {

    return (
      <GuidanceRowReadOnly
      {...itemProps}
      />
    );

  }

  return (
    <GuidanceRow
      {...itemProps}
      onChange={onChange}
    />
  );
};

const GuidanceSection = props => {

  const {

    type,
    fieldDefinitions,
    procedureData,
    procedureSelection,

    protocolVersionId,
    siteInfo,
    branchData,
    onChange,


    userRole,
    isSubjectLogRowLocked,
    reviewStatus,
    procedureQueryItems,

  } = props;

  const isRoleHasModifyPrivilege
                      = isOkRoleForPerformanceGuidanceModify(userRole, type);
  const isRoleHasViewPrivilege
                        = isOkRoleForPerformanceGuidanceView(userRole, type);

  if(!isRoleHasModifyPrivilege && !isRoleHasViewPrivilege) {
    return null;
  }

  // assert: role has read permission at least

  const isEditView = isRoleHasModifyPrivilege
                     && !isSubjectLogRowLocked
                     && ((![ ReviewStatus.Reviewed, ReviewStatus.Submitted ]
                            .includes( reviewStatus ))
                        ||
                        procedureQueryItems.some( queryItem =>
                          queryItem.status === QueryStatus.Open)
                        );
  const isReadOnlyView = !isEditView;

  return (
    <div className='GuidanceSection'>
      <h4>{ getTitle(type) }</h4>
      {
        fieldDefinitions.map( fieldDefinition => (
        <GuidanceField
          {...{
            key: `guidance-row-parent-${fieldDefinition.id}`,
            fieldDefinition,

            procedureData,
            procedureSelection,

            protocolVersionId,
            siteInfo,
            branchData,

            isReadOnlyView,
            onChange,
          }} />
        ))
      }

    </div>
  );
};

export const Guidance = props => {

  const {

    userRole,

    protocolVersionId,
    siteInfo,
    procedureData,
    procedureSelection,

    guidance,
    branchData,

    isSubjectLogRowLocked,
    reviewStatus,
    procedureQueryItems,

    refreshSubjectLogProcedureData,
    calculateNewProcedureState,

  } = props;

  if( !guidance ) {
    return null;
  }

  const onChange = e => {
    const procedureState =  calculateNewProcedureState( e.target.id, e.target.value );
    const fieldData = procedureData.fields.find(
      field => field.field_id === e.target.id &&
      field.procedure_row === procedureSelection.index);

    saveGuidance({
      fieldDefinitionId: `:${e.target.id}::`,
      supplementaryData: {
        fieldId: fieldData.field_instance_id,
        value: e.target.checked,
        procedureState,
        done: e.target.checked,
        validation: fieldData.validation_digest,
      },
    }).then( () => {
      refreshSubjectLogProcedureData();
    });
  }; // onChange

  const guidanceTypes = Object.keys( guidance );
  return guidanceTypes.map( type => (
    <GuidanceSection {...{
      key: `guidance-${ type }`,
      type,
      fieldDefinitions: guidance[type],
      procedureData,
      procedureSelection,

      protocolVersionId,
      siteInfo,
      branchData,
      onChange,

      userRole,
      isSubjectLogRowLocked,
      reviewStatus,
      procedureQueryItems,
   }}/>
 ));
};
