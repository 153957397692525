import { useMutation, useQueryClient } from 'react-query';
import { onError as displayError} from '../../lib/notificationUtils';
import { updateFieldReview } from '../../lib/fetchers/restApiHttpFetchers';
import { getFieldReviewsQueryKey } from './useGetFieldReviews';

export const useUpdateFieldReview = (context, etag, action) => {
  const queryClient = useQueryClient();

  // isSiteLocked is not used on the backend for field reviews
  const { isSiteLocked, ...rest } = context;
  const cacheKey = getFieldReviewsQueryKey(rest);

  const mutateFn = async () => {
    await updateFieldReview(
      rest,
      etag,
      action
    );
  };

  const onSuccess = async () => {

    queryClient.invalidateQueries(cacheKey);

  };

  const onError = async (error) => {
    if (error.status === 409) {
      
      queryClient.invalidateQueries(cacheKey);

    }

    displayError(error.message || 'unknown', { message: 'Error on field review update' });
    
  };

  return useMutation(
    mutateFn, 
    {
      onSuccess,
      onError,
    }
  );
};
