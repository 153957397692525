import {
  useCallback,
} from 'react';
import WebSocketClient from 'websocket-as-promised';
import { getApplicationEnvironment, resetSessionTimeout } from '../legacy/LegacyFacade';

export const useWebSocketRequest = props => {

  const applicationEnvironment = getApplicationEnvironment();

  const sendRequest = useCallback(async sdata => {

    const data = JSON.parse( sdata );

    const ws = new WebSocketClient( applicationEnvironment.ws, {
      packMessage: data => JSON.stringify( data ),
      unpackMessage: data => JSON.parse( data ),
      extractRequestId: data => data.messageId,
      attachRequestId: (data, requestId ) => ({ ...data,
                                                messageId: requestId }),
    });

    await ws.open();
    const result = await ws.sendRequest(data, { requestId: data.messageId });
    resetSessionTimeout();
    await ws.close();
    return JSON.stringify(result);

  }, [ applicationEnvironment.ws ]);

  return { sendRequest };
};

