/**

  dataUtils.jss
     utilities for extracting values from complicated data structures

     similar to protocolDefinitionUtils, except for data instead of
     definitions

 */

export const ReviewStatus = Object.freeze({
  Undefined: 'undefined',
  Open: 'open',
  ReviewComment: 'query',
  Reviewed: 'reviewed',
  Submitted: 'submitted',
});

export const ReviewAction = Object.freeze({
  AnswerQuery: 'answerQuery',
  Reviewed: 'reviewed',
  Open: 'open',
  Reopen: 'reopen',
  Query: 'query',
  Submitted: 'submitted',
});

export const ReviewType = Object.freeze({
  Visit: 'visit',
  Subject: 'subject',
  Site: 'site',
});

export const reviewStatusFromSubjectLogProcedureData = ( reviewData,
                                                         rowIndex ) => {

  // action is subjectLogProcedureData?.review?.[0]?.actions?.[0]?.action
  // follows review_get_icon

  if(!Array.isArray(reviewData)) {
    throw new Error('reviewStatusFromSubjectLogProcedureData: expected reviewData to be an array');
  }

  if(reviewData.length === 0) {
    return ReviewStatus.Undefined; // just started?
  }

  const reviewRecord = reviewData.find(r => r.procedure_row === rowIndex);
  return reviewRecord?.actions?.[0]?.action // assumes time desc
         ?? ReviewStatus.Undefined;
};


