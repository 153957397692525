import { useCallback, useMemo, useEffect } from 'react';
import { Modal } from 'antd';

export const useClassicConfirmMessages = props => {

  const { classic } = props;

  const [ modal, contextHolder ] = Modal.useModal();

  const defaultModalProps = useMemo(() => (
    {
      title:'Confirm',
      description: '',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: null,
      onCancel: null,
    }) , []);

  const receiveNewConfirmDialog = useCallback( extendedProps => {

    const { type, content: scontent, ...props } = extendedProps;

    // assume classic-side content is string, possibly html-formatted
    const content = (
      <div dangerouslySetInnerHTML={{__html: scontent}} />
    );

    if( type === 'info' ) {
      modal.info({
        ...defaultModalProps,
        ...props,
        content,
      });
      return;
    }


    modal.confirm({
      ...defaultModalProps,
      ...props,
      content,
    });

  }, [ modal, defaultModalProps ]);

  useEffect(() => {
    if(!classic) {
      return;
    }
    classic.subscribe('useClassicConfirmMessages', 'confirmDialog',
                       receiveNewConfirmDialog);
  }, [ classic, receiveNewConfirmDialog  ]);

  return [ contextHolder ];

};
