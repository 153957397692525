import { useUpdateFieldReview } from '../../hook/http-restapi/useUpdateFieldReview';
import { FieldReviewActionText, FieldReviewAction, FieldReviewBadgeText } from './constants';

export const UpdateFieldReviewButton = props => {
  const {
    userData,
    protocolUserRole,
    isActive,
    fieldReview,
    isFieldMarkedReviewed,
    fieldDefinitionId,
  } = props;

  const reviewAction = isFieldMarkedReviewed ? FieldReviewAction.Unreview : FieldReviewAction.Review;
  const activeText = isFieldMarkedReviewed ? FieldReviewActionText.MarkUnreviewed : FieldReviewActionText.MarkReviewed;
  const inactiveText = isFieldMarkedReviewed ? FieldReviewBadgeText.Reviewed : FieldReviewBadgeText.Unreviewed;

  const fieldReviewMarkUpdateHook = useUpdateFieldReview(
    { ...fieldReview?.context },
    fieldReview?.etag,
    {
      date: Date.now(),
      action: reviewAction,
      author: {
        email: userData?.user?.email,
        firstName: userData?.user?.first_name,
        lastName: userData?.user?.last_name,
        role: protocolUserRole
      }
    }
  );

  const updateFieldReviewRequest = () => {
    if (fieldReviewMarkUpdateHook.isLoading) {
      return null;
    };
    
    return fieldReviewMarkUpdateHook.mutate();
  };

  return (
    <div data-testid='update-field-review-button'>
      {(isActive ?
        <div
          className='field-review-update-unreview-button reviewable-field-update-button'
          id={`reviewable-field-update-button-${fieldDefinitionId}`}
          data-testid='reviewable-field-update-unreview-button'
          onClick={updateFieldReviewRequest}
        >
          {activeText}
        </div>
        : inactiveText
      )}
    </div>
  );
};

export default UpdateFieldReviewButton;