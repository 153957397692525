import ReadOnlyProtocolForm from "./ReadOnlyProtocolForm";
import { VIEW } from '../../constants';

export const ProtocolContainer = (props) => {
  const {
    portalUtils,
    protocolAdminUserData,
    resetProtocolAdminUserData
  } = props;

  if(!protocolAdminUserData) {
    return null;
  }

  const { data, protocolId, action } = protocolAdminUserData;
  const { protocols } = data;
  const protocol = (
    protocols.filter(
      protocol => protocol.protocol_id === protocolId)
  )[0];
  const protocolNumber = protocol.protocol_number;
  const allowSubjectEnroll = protocol.subject_enroll[0].allow;
  const disallowSubjectEnrollReason = protocol.subject_enroll[0].reason ?? '';

  let protocolInteraction;
  if (action === VIEW) {
    protocolInteraction = (
      <ReadOnlyProtocolForm
        protocolNumber={protocolNumber}
        allowSubjectEnroll={allowSubjectEnroll}
        disallowSubjectEnrollReason={disallowSubjectEnrollReason}
      />
    );
  }

  return portalUtils.attachAdminProtocolContent(
    protocolInteraction,
    protocolAdminUserData,
    resetProtocolAdminUserData,
  );
}
