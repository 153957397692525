import { useQuery } from 'react-query';
import { getFieldReviews } from '../../lib/fetchers/restApiHttpFetchers';

export const getFieldReviewsQueryKey = (context) => { 
  const getContext = (({ protocolId, siteId, subjectId, visitId }) => ({  protocolId, siteId, subjectId, visitId }))(context);
  
  return [ 'getFieldReviews', getContext ];
};

export const useGetFieldReviews = (context) => {

  const queryFn = () => getFieldReviews(context);

  return useQuery(
    getFieldReviewsQueryKey(context),
    queryFn,
    {
      keepPreviousData: true,
      enabled: Boolean(context?.subjectId)
    }
  );
};
