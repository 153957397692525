export const FieldReviewActionText = Object.freeze({
  MarkReviewed: 'Mark reviewed',
  MarkUnreviewed: 'Mark unreviewed',
});

export const FieldReviewBadgeText = Object.freeze({
  Reviewed: 'Reviewed',
  Unreviewed: 'Unreviewed',
});

export const FieldReviewAction = Object.freeze({
  Review: 'review',
  Unreview: 'unreview',
});