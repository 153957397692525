import { useMutation, useQueryClient } from 'react-query';
import { onError as displayError} from '../../lib/notificationUtils';
import { createFieldReview } from '../../lib/fetchers/restApiHttpFetchers';
import { getFieldReviewsQueryKey } from './useGetFieldReviews';

export const useCreateFieldReview = (context, action) => {
  const queryClient = useQueryClient();

  // isSiteLocked is not used on the backend for field reviews
  const { isSiteLocked, ...rest } = context;
  const mutateFn = async () => {
    await createFieldReview(rest, action);
  };

  const onSuccess = async () => {

    const cacheKey = getFieldReviewsQueryKey(rest);

    queryClient.invalidateQueries(cacheKey);

  };

  const onError = async (error) => {

    displayError(error.message || 'unknown', { message: 'Error on field review create' });
    
  };

  return useMutation(
    mutateFn, 
    {
      onSuccess,
      onError,
    }
  );
};

