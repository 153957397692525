import { useEffect, useState } from "react";
import { ApiScopeEditorContainer } from "./ApiScopeEditorContainer";

export const ApiScopeComponents = (props) => {
  const { classic, portalUtils } = props;
  const [ apiScopeData, setApiScopeData ] = useState( null );

  useEffect(() => {
    classic.subscribe('ApiScopeComponents', 'adminApiScopeEditView', setApiScopeData);
  }, [classic]);

  const resetScopeData = () => setApiScopeData( null );

  return (
    <ApiScopeEditorContainer {...{
      portalUtils,
      apiScopeData,
      resetScopeData,
    }}/>
  );
};
