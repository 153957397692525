import { Icf } from "./Icf";

export const IcfContainer = (props) => {
  const {
    portalUtils,
    icfData,
    resetIcfData
  } = props;

  if (!icfData || !icfData.data) {
    return null;
  }

  const { protocol_id, protocol_number, icf_versions, is_edit_allowed } = icfData.data;
  const icfComponent = (
    <Icf
      protocolId={protocol_id}
      protocolNumber={protocol_number}
      icfVersions={icf_versions}
      isEditAllowed={is_edit_allowed}
    />
  );

  return portalUtils.attachIcfContent(
    icfComponent,
    icfData.data,
    resetIcfData,
  );
}
