import { useState } from 'react';

import {
  Modal,
  Button,
  Space,
} from 'antd';

import {
  HistoryOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';

import {
  buildReviewStatusDialog,
  submitProcedureReviewDialogForm,
  buildReviewStatusHistory,
  setTabContentVisibility,
} from '../../legacy/LegacyFacade';

import {
  protocolUserRoleFromUserData,
  isOkRoleForDataEntryModify,
  isOkRoleForReviewAction,
} from '../../lib/roleUtils';

import {
  isProcedureHasState,
  getProcedureState,
} from '../../lib/procedureFormUtils';

import {
  reviewStatusFromSubjectLogProcedureData,
  ReviewStatus,
} from '../../lib/dataUtils';

import { FieldActionsDisplayDrawerControl } from './FieldActionsDisplayDrawerControl';

const ReviewDialog = props => {

  const {
    dialog,
    setDialog,
    isReviewDialogVisible,
    setIsReviewDialogVisible,
    procedureDefinition,
    subjectLogProcedureSelection,
    userRole,
    isSubjectLogRowLocked,
    refreshSubjectLogProcedureData,
    fieldValidations,
  } = props;

  if(!isReviewDialogVisible) {
    return null;
  }

  const destroy = () => {

    setDialog(null);
    setIsReviewDialogVisible( false );
  }

  if(!isReviewDialogVisible) {
    return null;
  }

  const isSubmitButtonRequired = isOkRoleForReviewAction(userRole)
                                 && !isSubjectLogRowLocked;

  if(!isSubmitButtonRequired) {
    return (
      <Modal
        title={
            <h4>{procedureDefinition.name} Entry (Row {
            subjectLogProcedureSelection.index + 1})</h4>
        }
        visible={ true }
        footer={[
          <Button onClick={destroy} type='primary' >OK</Button>
        ]}

      >
        <div className='ReviewDialogContent'
             dangerouslySetInnerHTML={{ __html: dialog, }}
        />
      </Modal>
    );
  };

  const submitDialogForm = () => {

    submitProcedureReviewDialogForm('subject', {
      procedureId: procedureDefinition.id,
      procedureRowNumber: subjectLogProcedureSelection.index,
    }, fieldValidations)
    .then(() => {
      refreshSubjectLogProcedureData();
      destroy();
    })
    .catch( error => {
      console.log({
        what: 'error: ReviewDialog.submitDialogForm',
        error,
      });
      destroy();
    });
  };

  return (
    <Modal
      title={
          <h4>{procedureDefinition.name} Entry (Row {
          subjectLogProcedureSelection.index + 1})</h4>
      }
      visible={ true }
      onCancel={destroy}
      okText='Submit'
      onOk={ submitDialogForm }
    >
      <div className='ReviewDialogContent'
           dangerouslySetInnerHTML={{ __html: dialog, }}
      />
    </Modal>
  );
};

const ReviewHistory = props => {

  const {
    dialog,
    isReviewHistoryVisible,
    setIsReviewHistoryVisible,
  } = props;

  if(!isReviewHistoryVisible) {
    return null;
  }

  const destroy = () => setIsReviewHistoryVisible( false );

  return (
    <Modal
      className='ReviewHistory'
      title={
          <h4>Review History</h4>
      }
      visible={isReviewHistoryVisible }
      onCancel={destroy}
      cancelText='Close'
      okButtonProps={{ className: 'ok-button' }}
    >
      <div className='ReviewHistoryContent'
           dangerouslySetInnerHTML={{ __html: dialog, }}
      />
    </Modal>
  );
};

const reviewStatusLabelFromSubjectLogProcedureData = ( reviewData, rowIndex ) => {

  const reviewStatus = reviewStatusFromSubjectLogProcedureData( reviewData,
                                                          rowIndex );

  switch( reviewStatus) {

    case ReviewStatus.Open: return 'Open';
    case ReviewStatus.ReviewComment: return 'Review Comment';
    case ReviewStatus.Reviewed: return 'Reviewed';
    case ReviewStatus.Submitted: return 'Submitted';
    case ReviewStatus.Undefined: return 'Undefined';
    default: throw new Error(`Unrecognized review status '${reviewStatus}'`);

  }
};

const ProcedureStateIndicator = props => {

  const { procedureDefinition, fieldsData } = props;

  if(!isProcedureHasState(procedureDefinition, 0)) {
    return null;
  }

  const state = getProcedureState(  procedureDefinition, fieldsData, 0);


    if(state?.isDefaultProcedureState) {
      return (
        <Button className='incomplete-state'>{ state.state }</Button>
      );
    }

    if(!state?.final) {
      return (
        <Button className='in-between-state'>{ state.state }</Button>
      );
    }

    return (
      <Button className='final-state'>{ state.state }</Button>
    );

};

export const SimplifiedSubjectLogProcedureViewHeader = props => {

  const {

    userData,

    procedureDefinition,

    context,
    protocolData,
    currentProcedureData,
    currentProcedureSelection,

    fieldsData,
    isSubjectLogRowLocked,

    queryListRequest,
    getFieldReviewsRequest,

  } = props;

  // this state should, perhaps, be shared with Field
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =
                                                              useState(false);

  const userRole = protocolUserRoleFromUserData(
    userData, context.protocolId);

  const isViewReadOnly = isSubjectLogRowLocked
                         || !isOkRoleForDataEntryModify(userRole);

  const viewType = isViewReadOnly ? 'View': 'Edit';

  return (
    <div className='header'>

      <div className='title'>
        <h4>{procedureDefinition.name} {viewType} Entry (Row {
          currentProcedureSelection.index + 1})</h4>
      </div>

      <div className='tool-bar'>
        <Space>
          <ProcedureStateIndicator
            procedureDefinition={procedureDefinition}
            fieldsData={fieldsData}
          />

          <FieldActionsDisplayDrawerControl
            {...{

              userData,

              context,
              protocolData,
              subjectLogProcedureData: currentProcedureData,

              procedureQueryItems: queryListRequest?.data?.items,
              fieldReviewItems: getFieldReviewsRequest?.data,
              isQueryDisplayVisible,
              isSubjectLogRowLocked,

              setIsQueryDisplayVisible,
            }}
          />
        </Space>
      </div>
    </div>
  );

};


export const SubjectLogProcedureViewHeader = props => {

  const {

    userData,

    procedureDefinition,

    context,
    protocolData,
    currentProcedureData,
    currentProcedureSelection,

    fieldsData,
    isSubjectLogRowLocked,

    queryListRequest,
    getFieldReviewsRequest,
    resetSubjectLogProcedureSelection,

    refreshSubjectLogProcedureData,

  } = props;

  // this state should, perhaps, be shared with Field
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =
                                                              useState(false);

  const [ reviewDialogMarkup, setReviewDialogMarkup ] = useState(null);
  const [ isReviewDialogVisible, setIsReviewDialogVisible ] = useState(false);
  const [ reviewHistoryMarkup, setReviewHistoryMarkup ] = useState(null);
  const [ isReviewHistoryVisible,
       setIsReviewHistoryVisible ] = useState(false);

  const showRowList = () => {
    resetSubjectLogProcedureSelection();
    setTabContentVisibility( true );
  };

  const showReviewHistory = ( procedureId, rowIndex ) => {
    // title: 'Review History'
    const view = buildReviewStatusHistory({
      procedureId,
      rowIndex,
    });
    setReviewHistoryMarkup( view );
    setIsReviewDialogVisible( false );
    setIsReviewHistoryVisible( true );

  };

  const showReviewDialog = ( procedureId, rowIndex ) => {
    const dialog = buildReviewStatusDialog({
      procedureId,
      rowIndex,
    });

    setReviewDialogMarkup( dialog );
    setIsReviewHistoryVisible( false );
    setIsReviewDialogVisible( true );
  };

  const userRole = protocolUserRoleFromUserData(
    userData, context.protocolId);

  const isViewReadOnly = isSubjectLogRowLocked
                         || !isOkRoleForDataEntryModify(userRole);

  const viewType = isViewReadOnly ? 'View': 'Edit';


  return (
    <div className='header'>

      <Button
        className='return-control'
        icon={<ArrowLeftOutlined/>}
        ghost={true}
        onClick={showRowList}
      />

      <div className='title'>
        <h4>{procedureDefinition.name} {viewType} Entry (Row {
          currentProcedureSelection.index + 1})
          {isSubjectLogRowLocked && (
            <span
              class="glyphicon glyphicon-lock"
              aria-hidden="true"
              style={{ marginLeft: '10px', color: 'black', fontSize: '20px' }}
            >
            </span>
          )}
        </h4>
      </div>

      <div className='tool-bar'>
        <Space>
          <ProcedureStateIndicator
            procedureDefinition={procedureDefinition}
            fieldsData={fieldsData}
          />

          { currentProcedureData?.review
            && currentProcedureData?.review?.length > 0
            && (
          <span>


            <Button
              onClick={() => showReviewDialog(
                               currentProcedureData.procedure_id,
                               currentProcedureSelection.index )}

              className='review-button'
            >
              Review:<b>{' ' +
                reviewStatusLabelFromSubjectLogProcedureData(
                  currentProcedureData?.review,
                  currentProcedureSelection.index
                  )
                }</b>
            </Button>

            <ReviewDialog
              dialog={reviewDialogMarkup}
              setDialog={setReviewDialogMarkup}
              isReviewDialogVisible={isReviewDialogVisible}
              setIsReviewDialogVisible={setIsReviewDialogVisible}
              procedureDefinition={procedureDefinition}
              subjectLogProcedureSelection={currentProcedureSelection}
              userRole={userRole}
              isSubjectLogRowLocked={isSubjectLogRowLocked}
              refreshSubjectLogProcedureData={refreshSubjectLogProcedureData}
              fieldValidations={fieldsData.reduce( (acc, field) => ({
                ...acc,
                [field.field_instance_id]: field.validation_digest,
              }), {})}
            />

            <Button
              className='split-right'
              onClick={() => showReviewHistory(
                               currentProcedureData.procedure_id,
                               currentProcedureSelection.index )}
            >
              <HistoryOutlined />
            </Button>

            <ReviewHistory
              dialog={reviewHistoryMarkup}
              isReviewHistoryVisible={isReviewHistoryVisible}
              setIsReviewHistoryVisible={setIsReviewHistoryVisible}
            />
          </span>

          )}
          <FieldActionsDisplayDrawerControl
            {...{

              userData,

              context,
              protocolData,
              subjectLogProcedureData: currentProcedureData,

              procedureQueryItems: queryListRequest?.data?.items,
              fieldReviewItems: getFieldReviewsRequest?.data,
              isQueryDisplayVisible,
              isSubjectLogRowLocked,

              setIsQueryDisplayVisible,
            }}
          />
        </Space>
      </div>
    </div>
  );

};
