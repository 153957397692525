import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Modal, Input, Typography } from 'antd';
import ReasonForChangeDialog from '../procedure/ReasonForChangeDialog';
import './EditSubjectButton.scss';

const EditSubjectButton = ({
  prevSubjectDisplayName,
  subjectId,
  siteContext,
}) => {
  const [isSubjectEditModalOpen, setIsSubjectEditModalOpen] = useState(false);
  const [isRfcModalOpen, setIsRfcModalOpen] = useState(false);
  const [subjectDisplayName, setSubjectDisplayName] = useState(
    prevSubjectDisplayName
  );

  const saveSubjectIdChange = (newDisplayName, reasonForChange) => {
    const wsMessage = {
      id: uuid(),
      protocol_id: siteContext.protocolId,
      protocol_instance: siteContext.protocolInstance,
      protocol_branch: siteContext.protocolBranch,
      site_id: siteContext.siteId,
      subject_id: subjectId,
      rfc: reasonForChange,
      name: 'subject.edit',
      display_id: newDisplayName,
    };
    window.ws_send(wsMessage);
  };

  const showModal = () => {
    setIsSubjectEditModalOpen(true);
  };

  const handleSubjectIdModalSave = () => {
    setIsSubjectEditModalOpen(false);
    setIsRfcModalOpen(true);
  };

  const handleSubjectIdModalCancel = () => {
    setSubjectDisplayName(prevSubjectDisplayName);
    setIsSubjectEditModalOpen(false);
  };

  return (
    <>
      <Button
        type='link'
        onClick={showModal}
        className='edit-subject-id-button'
        data-testid='edit-subject-button'
      >
        Edit
      </Button>
      <Modal
        title='Edit subject'
        open={isSubjectEditModalOpen}
        onCancel={handleSubjectIdModalCancel}
        footer={[
          <Button
            type='primary'
            key='save'
            onClick={handleSubjectIdModalSave}
            disabled={!subjectDisplayName}
          >
            Save
          </Button>,
          <Button key='cancel' onClick={handleSubjectIdModalCancel}>
            Cancel
          </Button>,
        ]}
        data-testid='edit-subject-modal'
      >
        <Typography.Title level={5}>Subject ID</Typography.Title>
        <Input
          value={subjectDisplayName}
          onChange={(e) => {
            setSubjectDisplayName(e.target.value);
          }}
          data-testid='edit-subject-input'
        />
        <Typography.Text type='secondary'>
          Please enter the Subject ID as prescribed for this study.
        </Typography.Text>
      </Modal>
      <ReasonForChangeDialog
        isShowReasonForChangeDialog={isRfcModalOpen}
        setIsShowReasonForChangeDialog={setIsRfcModalOpen}
        fieldName='Subject ID'
        currentValue={subjectDisplayName}
        previousValue={prevSubjectDisplayName}
        saveData={saveSubjectIdChange}
        valueToSave={subjectDisplayName}
        handleCancel={() => setSubjectDisplayName(prevSubjectDisplayName)}
      />
    </>
  );
};

export default EditSubjectButton;
