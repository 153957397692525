import { notification } from 'antd';
import { capitalizeFirstLetter } from './stringUtils';

export const NotificationTypes = Object.freeze({
  Success: 'Success',
  Error: 'Error',
  Warning: 'Warning',
  Open: 'Open',
  Info: 'Info',
});

export const onError = (error, notificationOptions) => {
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }

  notification.error({
    duration: 0, // manual close
    top: 70, // px, to avoid classic navbar
    message: 'Error', // title
    ...notificationOptions,
    description: message,
  });
};

/**
 * This function displays the 'antd' notification component.
 * See: https://ant.design/components/notification
 *
 * @param {string} message - The main message/content of the notification box.
 * @param {string} [type] - The type of 'antd' notification to display. Defaults to 'Info' if a valid type is not provided.
 * @param {string} [title] - Title of the notification box. Defaults to the "type" if a title is not provided.
 * @param {number} [duration] - Time in seconds after which the notification is closed. Defaults to "0" which means it must be manually closed.
 * @param {...object} [notificationOptions] - Additional antd notification config options to be provided: https://ant.design/components/notification
 *
 * @example
 *
 *     displayNotification("This is a good message", "Success")
 */
export const displayNotification = (message, type, title, duration, notificationOptions) => {
  if (!message) {
    console.log("displayNotification called without a message.");
    return;
  }

  const notificationType = Object.values(NotificationTypes)
                            .find(val => val.toLowerCase() === type?.toLowerCase()) ?? NotificationTypes.Info;

  const notificationConfig = 
  {
    top: 70, // px, to avoid classicnavbar
    message: title ? title : capitalizeFirstLetter(notificationType.toLowerCase()), // title
    description: message, // content
    duration: duration ? duration : 0, // 0 is manual close.
    ...notificationOptions
  };

  switch (notificationType.toLowerCase())
  {
    case NotificationTypes.Success.toLowerCase():
      notification.success(notificationConfig);
      break;
    case NotificationTypes.Error.toLowerCase():
      notification.error(notificationConfig);
      break;
    case NotificationTypes.Warning.toLowerCase():
      notification.warning(notificationConfig);
      break;
    case NotificationTypes.Open.toLowerCase():
      notification.open(notificationConfig);
      break;
    case NotificationTypes.Info.toLowerCase():
    default:
      notification.info(notificationConfig);
      break;
  }
}



