import React from 'react';
import { Modal, Button } from 'antd';
import StackTrace from 'stacktrace-js';
import { getApplicationEnvironment, getCsrf } from '../../legacy/LegacyFacade';
import { v4 as uuid } from 'uuid';

class ErrorBoundary extends React.Component {
  userData = null;
  adminMode = null;
  trainingDone = null;
  userType = null;
  protocolData = null;
  visitContext = null;
  procedureContext = null;
  onlySourceUploadUser = null;
  errorId = null;
  breadCrumb = null;

  setUserData(value){
    this.userData = value?.user;
  }

  setAdminMode(value){
    this.adminMode = value;
  }

  setTrainingDone(value){
    this.trainingDone = value;
  }

  setUserType(value){
    this.userType = value;
  }

  setProtocolData(value){
    this.protocolData = value?.protocol_list[0];
  }

  setVisitContext(value){
    this.visitContext = value;
  }

  setProcedureContext(value){
    this.procedureContext = value;
  }

  setOnlySourceUploadUser(value){
    this.onlySourceUploadUser = value;
  }

  setBreadCrumb(value){
    this.breadCrumb = value;
  }

  constructor(props) {
    super(props);

    this.state = {
                    isError: false,
                    classic: props.classic,
                  };

    this.state.classic.subscribe('ErrorBoundary', 'protocolListData',
                                  val => this.setUserData(val));
    this.state.classic.subscribe('ErrorBoundary', 'adminMode',
                                    val => this.setAdminMode(val));
    this.state.classic.subscribe('ErrorBoundary', 'trainingDone',
                                    val => this.setTrainingDone(val));
    this.state.classic.subscribe('ErrorBoundary', 'userType',
                                    val => this.setUserType(val));
    this.state.classic.subscribe('ErrorBoundary', 'protocolData',
                                    val => this.setProtocolData(val));
    this.state.classic.subscribe('ErrorBoundary', 'enterVisitContext',
                                    val => this.setVisitContext(val));
    this.state.classic.subscribe('ErrorBoundary', 'enterProcedureContext',
                                    val => this.setProcedureContext(val));
    this.state.classic.subscribe('ErrorBoundary', 'onlySourceUploadUser',
                                    val => this.setOnlySourceUploadUser(val));
    this.state.classic.subscribe('ErrorBoundary', 'breadCrumb',
                                    val => this.setBreadCrumb(val));
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error) {
    this.errorId = uuid();
    const csrf = getCsrf();
    const applicationEnvironment = getApplicationEnvironment();

    const stackframes = await StackTrace.fromError(error);
    const stackText = stackframes.splice(0, 20)
                        .map(frame => frame.toString())
                        .join('\n');

    this.setState({
       classic: this.state.classic,
       isError: true,
       message: error.message,
       error,
       stackText,
     });

    const errorData = {
      name: 'React Exception',
      client_error_id: this.errorId,
      message: error.message,
      stackTrace: stackText,
      browser: window.navigator.userAgent,
      language: (window.navigator.userLanguage || window.navigator.language),
      nav: {
        url: this.breadCrumb,
        user_info: this.userData,
        admin_mode: this.adminMode,
        training_done: this.trainingDone,
        user_type: this.userType,
        procedure_id: this.visitContext?.data?.visit?.protocol_branch && this.procedureContext?.procedureId,
        protocol_branch: this.protocolData?.protocol_branch,
        protocol_subject_version: this.visitContext?.data?.visit?.protocol_branch,
        onlySourceUploadUser: this.onlySourceUploadUser,
      }
    };

    this.sendData({
      date: new Date(),
      status: 'error',
      ...errorData
    }, applicationEnvironment, csrf);
  }
  sendData = async (values, applicationEnvironment, csrf) => {
    const data = { ...values };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=UTF-8", "CSRF-Token": csrf },
      body: JSON.stringify(data)
    };

    await fetch(`${applicationEnvironment.rest}/error`, requestOptions)
    console.log('[Client Error sent successfully]')
  }

  handleOk = () => {
    this.setState({
      classic: this.state.classic,
      isError: false,
    });
  };

  render() {
    const { isError, stackText, message } = this.state;

    if (isError) {
      // space between "contact" and "<a" is intentional
      return (
        <>
          <Modal  title='Alert'
            destroyOnClose={true}
            visible={true}
            footer={[
              <Button onClick={this.handleOk} type='primary' >OK</Button>
            ]}
          >
            <p>
              There was a client error. Please refresh your browser
              and try again. If it occurs again, please
              contact <a
 
                href="mailto:crsupport@flatiron.com">
                  crsupport@flatiron.com
                </a> with error id: {this.errorId}

            </p>
            <p>
              <b>Message: </b> { message }
            </p>
            <b>Stack Trace</b>
            <pre>{stackText}</pre>
          </Modal>
          {this.props.children}
        </>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default ErrorBoundary;
