import {useEffect, useState} from "react";
import { ProtocolContainer } from "./ProtocolContainer";

export const ProtocolComponents = (props) => {
  const { classic, portalUtils } = props;
  const [
    protocolAdminUserData,
    setProtocolAdminUserData
  ] = useState(null);

  useEffect(() => {
    classic.subscribe(
      'ProtocolComponents',
      'protocolAdminUserData',
      setProtocolAdminUserData
    );
  }, [ classic ]);

  const resetProtocolAdminUserData = () => {
    setProtocolAdminUserData( null );
  };

  return (
    <ProtocolContainer {...{
      portalUtils,
      protocolAdminUserData,
      resetProtocolAdminUserData
    }}/>
  );
};
