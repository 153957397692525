import { useState } from 'react';
import moment from 'moment';
import { Modal, Row, Col, Button, Divider } from 'antd';

import './SourceDocumentDisplay.scss';
import { FIELD_DATA_SOURCES } from './procedureConstants';
import { UserRole } from '../../lib/roleUtils';

export const SourceDocumentDisplay = (props) => {
  const { protocolId, fieldId, fieldSourceType, sourceDocuments, formType, userRole } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const allowedRoles = new Set([UserRole.Site, UserRole.Monitor]);
  const shouldShowSourceDocDisplay =
    sourceDocuments 
    && sourceDocuments.length > 0 
    && allowedRoles.has(userRole)
    && fieldSourceType === FIELD_DATA_SOURCES.IDENTIFIED_BY_FLATIRON;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      { shouldShowSourceDocDisplay && (
        <>
          <Button
            type='link'
            id='source-document-display-link'
            data-testid='source-document-display-link'
            onClick={showModal}
          >
            View source documents used by Flatiron
          </Button>
          <Modal
            className='source-document-modal'
            title={`Source documents used for this ${formType}`}
            onCancel={handleModalClose}
            open={isModalOpen}
            footer={[
              <Button
                key='close-source-document-display'
                type='primary'
                onClick={handleModalClose}
              >
                Close
              </Button>,
            ]}
            width={700}
          >
            <Row>
              <Col span={12} className='source-document-table-header'>
                Document Category
              </Col>
              <Col span={12} className='source-document-table-header'>
                Document Date
              </Col>
            </Row>
            {sourceDocuments.map((sourceDocument, index) => (
              <>
                <Divider className='source-document-divider' />
                <Row>
                  <Col
                    span={12}
                    id={`source-document-category-${protocolId}-${fieldId}-${index}`}
                    className='source-document-table-row'
                  >
                    {sourceDocument.document_category}
                  </Col>
                  <Col
                    span={12}
                    id={`source-document-category-${protocolId}-${fieldId}-${index}`}
                    className='source-document-table-row'
                  >
                    {moment(sourceDocument.document_date).format('L')}
                  </Col>
                </Row>
              </>
            ))}
          </Modal>
        </>
      )}
    </>
  );
};
