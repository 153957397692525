import { FieldReviewRole } from '../../lib/roleUtils';
import FieldReviewButton from './FieldReviewButton';

export const FieldReviewerButton = props => {
  const {
    context,
    userData,
    protocolUserRole,
    fieldReviewRole,
    isActive,
    fieldReview,
    isFieldMarkedReviewed,
    fieldDefinitionId,
  } = props;

  if (!userData || (fieldReviewRole !== FieldReviewRole.Reviewer)) {
    return null;
  }

  return (
    <div id='field-review-action-button' data-testid='field-review-action-button'>
      <FieldReviewButton
        isActive={isActive}
        context={context}
        fieldReview={fieldReview}
        isFieldMarkedReviewed={isFieldMarkedReviewed}
        userData={userData}
        fieldDefinitionId={fieldDefinitionId}
        protocolUserRole={protocolUserRole}
      />
    </div>
  );
};