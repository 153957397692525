import { useEffect, useState } from "react";
import { SiteAdminContainer } from "./SiteAdminContainer";

export const SiteAdminComponents = (props) => {
  const { classic, portalUtils } = props;
  const [ siteAdminData, setSiteAdminData ] = useState( null );

  useEffect(() => {
    classic.subscribe('SiteAdminComponents', 'siteAdminData', setSiteAdminData);
  }, [classic]);

  const resetSiteAdminData = () => setSiteAdminData( null );

  return (
    <>
      <SiteAdminContainer {...{
        portalUtils,
        siteAdminData,
        resetSiteAdminData
      }}/>
    </>
  );
};
