import { useState, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useWebSocketRequest } from './useWebSocketRequest';
import { v4 as uuid } from 'uuid';

const endpointNames = [
  'gui/queryList',
  'gui/queryCountList'
];

class PageOvershootError extends Error {}

const fetchData = async (sendRequest, parameters) => {

  const {
          endpoint,
          context,
          paging,
          sort,
          filter
  } = parameters;

  const messageId = uuid();
  
  const data = {
    messageId,
    name: endpoint,
    context,
    paging: {
      maxResults: paging.maxResults,
      exclusiveStartKey: paging.exclusiveStartKey ,
    },
    sort,
    filter,
  };

  const response = await sendRequest(
    JSON.stringify(data)
  );

  if (!response) {
    return;
  }

  const result = JSON.parse(response);
  if(messageId !== result?.messageId) {
    throw new Error(`Expected response ID '${messageId}', found '${result.messageId}'`);
  }
  return result;
};

const buildQueryKey = (
     generalParameters,
     pageSize,
     pageNumber
) => {

  const {
          endpoint,
          context,
          sort,
          filter
  } = generalParameters;

  return [
    endpoint,
    context,
    {
      pageSize,
      pageNumber,
    },
    sort,
    filter,
  ];

};

const objectEquals = (a, b) => {

  if(!a && !b) {
    return true;
  }
  if( (!a && !!b) || (!!a && !b) ) {
    return false;
  }

  const ka = Object.keys(a);
  const kb = Object.keys(b);

  if(   (!ka.every(k => k in b))
     || (!kb.every(k => k in a)) ) {
    return false;
  }

  if( !ka.every(k => a[k] === b[k]) ){
    return false;
  }
  return true;

};

const queryKeyEquals = (a, b) => {

  if( (!a && !!b) || (!!a && !b) ) {
    return false;
  }
  if(a.length !== b.length) {
    return false;
  }
  return a[0] === b[0] // endpoint
         && objectEquals(a[1], b[1]) // context
         && objectEquals(a[2], b[2]) // paging
         && objectEquals(a[3], b[3]) // sort
         && objectEquals(a[4], b[4]); // filter

};

const isEquivalentPagedQuerySet = (a, b) => {

  if( (!a && !!b) || (!!a && !b) ) {
    return false;
  }
  if(a.length !== b.length) {
    return false;
  }
  // endpoint, context and filter match, same pageSize
  // ignore pageNumber and sort
  return a[0] === b[0] // endpoint
         && objectEquals(a[1], b[1]) // context
         && a[2].pageSize === b[2].pageSize
         && objectEquals(a[4], b[4]) // filter

};

const writeTotalResultsToCache = (queryClient,
                                  generalParameters,
                                  pageSize,
                                  totalResults,
                                  ) => {

  const queryKey = buildQueryKey(
    generalParameters,
    pageSize,
    1
  );

  const state = queryClient.getQueryState( queryKey );
  if(!state) {
    return;
  }

  state.localMetadata = { ...state.localMetadata, totalResults };

};

const readTotalResultsFromCache = (queryClient,
                                  generalParameters,
                                  pageSize,
                                  ) => {

  const queryKey = buildQueryKey(
    generalParameters,
    pageSize,
    1
  );

  const state = queryClient.getQueryState( queryKey );
  return state?.localMetadata?.totalResults;

};

const hasCache = (queryClient,
                  generalParameters,
                  pageSize,
                  pageNumber
                 ) => {

  return !!getCache(queryClient,
                  generalParameters,
                  pageSize,
                  pageNumber);
};

const getCache = (queryClient,
                  generalParameters,
                  pageSize,
                  pageNumber
                 ) => {

  const queryKey = buildQueryKey(
    generalParameters,
    pageSize,
    pageNumber
  );

  return queryClient.getQueryData( queryKey );
};

const putCache = (queryClient,
                  generalParameters,
                  pageSize,
                  pageNumber,
                  data
                  ) => {

  const queryKey = buildQueryKey(
    generalParameters,
    pageSize,
    pageNumber
  );
  queryClient.setQueryData( queryKey, data );

};

const fetchPage = async (
                            queryClient,
                            sendRequest,
                            generalParameters,
                            paging,
                            setTotalResults

                          ) => {

  const {
          endpoint,
          context,
          sort,
          filter
  } = generalParameters;

  const { pageNumber, pageSize } = paging;

  if(pageNumber < 1) {
    throw new Error(`Illegal pageNumber '${pageNumber}'`);
  }

  if(hasCache(queryClient, generalParameters, pageSize, pageNumber)) {
    return getCache(queryClient, generalParameters, pageSize, pageNumber);
  }

  if(pageNumber === 1) {
    const response = await fetchData(sendRequest, {
          endpoint,
          context,
          paging: {
            exclusiveStartKey: null,
            maxResults: pageSize,
          },
          sort,
          filter
    });
    putCache(queryClient, generalParameters, pageSize, pageNumber, response);

    if(!response.paging.lastEvaluatedKey) {
      const totalResults = pageSize * (pageNumber - 1) + response.items.length;
      writeTotalResultsToCache(
                      queryClient, generalParameters, pageSize, totalResults);
      setTotalResults(totalResults);
    }

    return getCache(queryClient, generalParameters, pageSize, pageNumber);
  }

  // pageNumber > 1, page is not in cache

  // recursive step,
  // if previous page not in cache, fetchPage() it

  if(!hasCache(queryClient, generalParameters, pageSize, pageNumber - 1)) {

    await fetchPage (
                        queryClient,
                        sendRequest,
                        generalParameters,
                        {
                          pageNumber: pageNumber - 1,
                          pageSize,
                        },
                        setTotalResults
                      );
  }

  const previousResponse = getCache(queryClient,
                                    generalParameters,
                                    pageSize,
                                    pageNumber - 1);
  const lastEvaluatedKey = previousResponse?.paging?.lastEvaluatedKey;

  if(!lastEvaluatedKey) {

    const totalResults = pageSize * (pageNumber - 2)
                         + previousResponse.items.length;

    writeTotalResultsToCache(
                      queryClient, generalParameters, pageSize, totalResults);
    setTotalResults(totalResults);

    throw new PageOvershootError(`Page overshoot: set totalResults to ${totalResults}`);

  }

  const response = await fetchData(sendRequest, {
        endpoint,
        context,
        paging: {
          exclusiveStartKey: lastEvaluatedKey,
          maxResults: pageSize,
        },
        sort,
        filter
    });

  if(!response.paging.lastEvaluatedKey) {
    const totalResults = pageSize * (pageNumber - 1) + response.items.length;
    writeTotalResultsToCache(
                      queryClient, generalParameters, pageSize, totalResults);
    setTotalResults(totalResults);
  }

  putCache(queryClient, generalParameters, pageSize, pageNumber, response);
  return getCache(queryClient, generalParameters, pageSize, pageNumber);

};

const setTotalResultsIfNecessary = ({
    totalResults,
    previousKey, queryKey,
    endpoint, context, sort, filter, paging,
    queryClient, setPreviousKey, setTotalResults,
                                    }) => {

  if( queryKeyEquals(previousKey, queryKey) ) {
    return;
  }

  setPreviousKey( queryKey );

  if(isEquivalentPagedQuerySet(previousKey, queryKey)) {
    return;
  }

  const cachedTotalResults = readTotalResultsFromCache(
                               queryClient,
                               { endpoint, context, sort, filter },
                               paging.pageSize);


  if(!cachedTotalResults || totalResults === cachedTotalResults) {
    return;
  }

  setTotalResults(cachedTotalResults);

};

export const usePagingDataRequest = argv => {
  const {

    endpoint,
    context,
  
    paging,
    sort,
    filter,

    config: clientConfig,

    totalResultsFromResponse,

  }  = argv;

  const [ previousKey, setPreviousKey] = useState([]);
  const [ totalResults, setTotalResults ] = useState(0);
  const queryClient = useQueryClient();
  const { sendRequest } = useWebSocketRequest();

  if(!totalResultsFromResponse){
    throw new Error(`totalResultsFromResponse is not been defined`);
  }

  if (!endpointNames.includes(endpoint)) {
    throw new Error(`unrecognized endpoint name '${endpoint}'`);
  }

  const queryKey = useMemo(() => buildQueryKey(
    { endpoint, context, sort, filter },
    paging.pageSize,
    paging.pageNumber
  ), [
    context,
    endpoint,
    filter,
    paging.pageNumber,
    paging.pageSize,
    sort,
  ]);

  setTotalResultsIfNecessary({
    totalResults,
    previousKey, queryKey,
    endpoint, context, sort, filter, paging,
    queryClient, setPreviousKey, setTotalResults,
  });

  const queryFunction = useCallback( async (params, ...more) => {

    const data = await fetchPage(
                                  queryClient,
                                  sendRequest,
                                  {
                                    endpoint,
                                    context,
                                    sort,
                                    filter
                                  },
                                  paging,
                                  setTotalResults,
                                );

    if(paging.pageNumber === 1 || totalResults === 0) {

      const provisionalTotalResults = totalResultsFromResponse(data);
      writeTotalResultsToCache(
                                queryClient,
                                {
                                  endpoint,
                                  context,
                                  sort,
                                  filter
                                },
                                paging.pageSize,
                                provisionalTotalResults);

      setTotalResults(provisionalTotalResults);
    }
    return data;

  }, [
    context,
    endpoint,
    filter,
    paging,
    queryClient,
    sendRequest,
    sort,
    totalResults,
    totalResultsFromResponse,
  ]); // queryFunction

  const config = useMemo(() => ({
      staleTime: 5 * 60 * 1000,
      ...clientConfig,
      keepPreviousData: true,
  }), [ clientConfig ]);

  const result = useQuery(
    queryKey,
    queryFunction,
    config,
  );

  return {
    ...result,
    totalResults
  };
};
