import { useState, useEffect, useCallback } from 'react';
import {
  PageHeader,
  Pagination,
} from 'antd';

import { usePagingDataRequest } from '../hook/usePagingDataRequest';
import { QueryTable } from './QueryTable';
import { QueryDownloadButton } from './QueryDownloadButton';

import {
         Query,
         SiteRoleAnswerForm,
         DmRoleCloseOrReplyForm,
         SiteRoleReplyForm,
       } from './DataItemQueries';

import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';
import'./ProtocolLevelQueryList.scss';

const DEFAULT_PAGING_STATE = {
  currentPageNumber: 1,
  pageSize: 10,
};

const DEFAULT_SORT_STATE = {
  columnKey: 'status', // antd sort
  order: 'ascend', // antd sort
};

const apiSortStateFromAntdSortState = (antdSortState,
                                       principalQueryRole)  => {

  const isAscending = antdSortState.order === 'ascend';

  switch (antdSortState.columnKey) {
    case 'status':
      return {
        field: principalQueryRole === 'initiator'
         ? 'dm-status'
         : 'site-status',
         isAscending,

      };

    case 'date':
      return {
        field: 'date',
        isAscending,
      };

    default:
      throw new Error(`Unrecognized key '${antdSortState.columnKey}'`);
  }

};

const totalResultsFromResponseByFilterState = filterState => {
  // returns a function from response to integer
  return response => {
    if(filterState?.field === 'status'){
      return response?.queryCount[filterState.value];
    }
    return response?.queryCount?.total ?? 5;
  };
};

const displayQuery = (userData, queryItem) => {

  const role = protocolUserRoleFromUserData( userData, queryItem.context.protocolId );
  const principalQueryRole = findQueryRole(role);
  const allowClose = (role, initiatingRole) => {
    if(role === 'dm' && (initiatingRole === 'api' || initiatingRole === 'site')){ //add site too because of query migration script
      return true;
    }

    if(role !== queryItem.initiatingRole){
      return false;
    }

    return true;
  };

  return (
    <Query
        key={queryItem.etag}
        queryItem={queryItem}
        isExpanded={true}
        isCollapsible={false}
        >

    {
      principalQueryRole === 'recipient'
      && queryItem.status === 'open'
      && !queryItem?.supplement?.locks?.isLocked
      && <SiteRoleAnswerForm
           queryItem={queryItem}
           userData={userData}
         />

    }
    {
      principalQueryRole === 'recipient'
      && queryItem.status === 'answered'
      && !queryItem?.supplement?.locks?.isLocked
      && <SiteRoleReplyForm
           queryItem={queryItem}
           userData={userData}
         />

    }
    {
      principalQueryRole === 'initiator'
      && queryItem.status !== 'closed'
      && !queryItem?.supplement?.locks?.isLocked
      && <DmRoleCloseOrReplyForm
           queryItem={queryItem}
           userData={userData}
           allowClose={allowClose(role, queryItem.initiatingRole)}
          />

    }

    </Query>

  );
};

const onQueryTableChange = async (tableState,
                                      pagination, filters, sorter, extra) => {

  const {

    pageSize,

    setCurrentPageNumber,
    setPageSize,
    setSortState,

  } = tableState;

  switch (extra.action) {

    case 'paginate':

      if(pagination.pageSize !== pageSize) {

        setCurrentPageNumber(1);
        setPageSize(pagination.pageSize);

      } else {

        setCurrentPageNumber(pagination.current);
      }
      break;

    case 'sort':

      setSortState({
        columnKey: sorter?.columnKey,
        order: sorter?.order,
      });
      setCurrentPageNumber(1);
      break;

    case 'filter':
      throw new Error('Filtering is handled in onFilterChanged');

    default:
      throw new Error(`Unrecognized table action '${extra.action}'`);
  }
};

const isSingleSiteAccess = (principalAccessData, protocolId) => {
  const protocolAccessData = principalAccessData.find(
    p => p.protocol_id === protocolId);
  return protocolAccessData.all_sites !== 1
     && protocolAccessData.associated_sites.length === 1;
};

const createDefaultContextFromAccessData = (context, principalAccessData) => {

  const protocolAccessData = principalAccessData.find(
    p => p.protocol_id === context.protocolId);

  if(protocolAccessData.all_sites === 1
     || protocolAccessData.associated_sites.length !== 1) {
    return context;
  }

  const principalSiteId = protocolAccessData.associated_sites[0];

  if(context?.siteId) {

    if(context.siteId !== principalSiteId) {
      throw new Error(`Expected site ID '${ principalSiteId }', found '${
                                            context.siteId }'`);
    }
    return context;

  }

  return {
    ...context,
    siteId: principalSiteId,
  };

};

export const ProtocolLevelQueryList = props => {

  const {
    context: protocolContext,
    userData,
    siteData,
  } = props;

  const [ pageSize, setPageSize ] = useState(DEFAULT_PAGING_STATE.pageSize);
  const [ currentPageNumber, setCurrentPageNumber ] =
                         useState(DEFAULT_PAGING_STATE.currentPageNumber);
  const [ filterState, setFilterState ] = useState({});
  const [ sortState, setSortState ] = useState(DEFAULT_SORT_STATE);
  const [ context, setContext ] = useState(createDefaultContextFromAccessData(
                                            protocolContext, userData.roles));

  const clearContext = useCallback(() => setContext(
    createDefaultContextFromAccessData( protocolContext, userData.roles)
  ), [ protocolContext, userData.roles ]);

  const principalQueryRole = findQueryRole(
          protocolUserRoleFromUserData(userData, context.protocolId));

  const {
    isLoading,
    data: queryData,
    totalResults,
  } = usePagingDataRequest({
      endpoint: 'gui/queryList',
      context,
      paging: {
        pageNumber: currentPageNumber,
        pageSize,
      },
      sort: {
        ...apiSortStateFromAntdSortState(sortState, principalQueryRole),
      },
      filter: {
        ...filterState,
      },
      config: {
        enabled: !!protocolContext?.protocolId,
        retry: 5,
        retryOnMount: true,
      },

      totalResultsFromResponse: totalResultsFromResponseByFilterState( filterState ),
    });

  useEffect(() => {

    setContext(() =>
      createDefaultContextFromAccessData( protocolContext, userData.roles));

  }, [ protocolContext, userData.roles ]);

  useEffect(() => {

    // this block compensates for a filtered case in which a provisional
    // totalResults value is greater than the real totalResults, and the user
    // has asked for a page beyond the true page count

    if(currentPageNumber > Math.max(1, Math.ceil(totalResults / pageSize ))){
      setCurrentPageNumber(() => Math.ceil(totalResults / pageSize ));
    }

  }, [ currentPageNumber, pageSize, totalResults ]);

  const onFilterChange = useCallback(( field, value ) => {

    if(filterState?.field === field && filterState?.value === value) {
      return;
    }

    if(value === null) {
      setFilterState({});
      return;
    }

    setFilterState({
      field,
      value,
    });

  }, [ filterState ]);

  const onPaginationChange = async ( page, newPageSize ) => 
    onQueryTableChange({

        pageSize, // general scope, hence name change for newPageSize

        setCurrentPageNumber,
        setPageSize,
        setSortState,

      }, { current: page, pageSize: newPageSize }, null, null, { action: 'paginate' });



  const onTableChange = async ( pagination, filters, sorter, extra ) =>

    onQueryTableChange({

        pageSize,

        setCurrentPageNumber,
        setPageSize,
        setSortState,

      }, pagination, filters, sorter, extra);

  const siteItems = siteData.sites.map( site => ({
    context: {
      protocolId: site.protocol_id,
      protocolVersionName: site.protocol_branch[0].branch,
      siteId: site.site_id,
      isLocked: site.isLocked,
    },
    siteNumber: site.given_site_id,
    siteName: site.site_name,
    piName: site.pi_name,
  }));

/*

if context.subjectId then use the siteId to find the site name in siteData
then to find the subject name do what?

*/
  const siteLabel = siteData.sites.filter( site => site.site_id === context.siteId)[0]?.given_site_id;

  return (
    <div className='ProtocolLevelQueryList'>
      <PageHeader
        title='Queries'
        extra={
          <div className='toolbars'>
            <QueryDownloadButton 
              protocolLabel={siteData.protocol_number}
              siteLabel={siteLabel}
              filterState={filterState}
              sortState={ apiSortStateFromAntdSortState(
                            sortState, principalQueryRole)}
              context={context}
            >
              Download listing
            </QueryDownloadButton>
            <div className='toolbar'>
              <Pagination {...{
                onChange: onPaginationChange,
                defaultPageSize: 10,
                current: currentPageNumber,
                pageSize,
                total: totalResults,
                // hideOnSinglePage can leave user stranded if
                // totalItemCount active and true total equals pageSize,
                // because of our adaptive total
                hideOnSinglePage: false,
                size: 'small',
                }}
              />

            </div>
          </div>
        }
      />

      <QueryTable {...{
        // data
        queryItems: queryData?.items,

        // data for table controls
        context,
        siteItems,
        filterState,

        // configuration
        isLoading,
        isHideColumnSite: isSingleSiteAccess(userData.roles,
                          context.protocolId),

        // callbacks
        onTableChange,
        displayQuery: queryItem => displayQuery( userData, queryItem ),
        onFilterChange,

        // control
        setContext,
        clearContext,
      }}/>
    </div>
  );
};
