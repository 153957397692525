import {useEffect, useState} from "react";
import { ViewJsonDataContainer } from "./ViewJsonDataContainer";
import { VersionContainer } from "./VersionContainer";
import { DocumentsDataContainer } from "./DocumentsDataContainer";

export const VersionComponents = (props) => {
  const { classic, portalUtils } = props;
  const [ versionData, setVersionData ] = useState( null );
  const [ viewJsonData, setViewJsonData ] = useState( null );
  const [ documentsData, setDocumentsData ] = useState( null );

  useEffect(() => {
    classic.subscribe('VersionComponents', 'versionData', setVersionData);
    classic.subscribe('VersionComponents', 'viewJsonData', setViewJsonData);
    classic.subscribe('VersionComponents', 'documentsData', setDocumentsData);
  }, [classic]);

  const resetVersionData = () => setVersionData( null );
  const resetViewJsonData = () => setViewJsonData( null );
  const resetDocumentsData = () => setDocumentsData( null );

  useEffect(() => {
    if (versionData) {
      resetViewJsonData();
      resetDocumentsData();
    }
  }, [versionData]);

  useEffect(() => {
    if (viewJsonData) {
      resetVersionData();
      resetDocumentsData();
    }
  }, [viewJsonData]);

  useEffect(() => {
    if (documentsData) {
      resetVersionData();
      resetViewJsonData();
    }
  }, [documentsData]);

  return (
    <>
      <VersionContainer {...{
        portalUtils,
        versionData,
        resetVersionData
      }}/>
      <ViewJsonDataContainer {...{
        portalUtils,
        viewJsonData,
        resetViewJsonData
      }}/>
      <DocumentsDataContainer {...{
        portalUtils,
        documentsData,
        resetDocumentsData
      }}/>
    </>
  );
};
