import UpdateFieldReviewButton from './UpdateFieldReviewButton';
import { useEffect, useState } from 'react';
import CreateFieldReviewButton from './CreateFieldReviewButton';

const FieldReviewButton = (props) => {
  const {
    userData,
    isActive,
    protocolUserRole,
    fieldDefinitionId,
    isFieldMarkedReviewed,
  } = props;

  const [ isFieldReviewExisting, setIsFieldReviewExisting ] = useState(false);

  useEffect(() => {
    if (props.fieldReview) {
        setIsFieldReviewExisting(() => true);
    }
  }, [props.fieldReview]);

  const createFieldReviewButton = <CreateFieldReviewButton
    isActive={isActive}
    context={props.context}
    userData={userData}
    protocolUserRole={protocolUserRole}
    fieldDefinitionId={fieldDefinitionId}
  />;
  const updateFieldReviewButton = <UpdateFieldReviewButton
    isActive={isActive}
    isFieldMarkedReviewed={isFieldMarkedReviewed}
    fieldReview={props.fieldReview}
    userData={userData}
    protocolUserRole={protocolUserRole}
    fieldDefinitionId={fieldDefinitionId}
  />;

  return (
    <>
      {
        isFieldReviewExisting
        ? updateFieldReviewButton
        : createFieldReviewButton
      }
    </>
  );
};

export default FieldReviewButton;