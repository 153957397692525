import { useState } from 'react';
import { Modal, Input, Select, Descriptions, Alert } from 'antd';

import { INTENTIONALLY_LEFT_BLANK } from './constants';

const ReasonForChangeDialog = (props) => {
  // follows classic/js/src/visit.page.js rfc_show()

  const { Option } = Select;

  const {
    isShowReasonForChangeDialog,
    fieldName,
    currentValue,
    previousValue,
    setIsShowReasonForChangeDialog,
    saveData,
    valueToSave,
    handleCancel,
  } = props;

  const [selectorValue, setSelectorValue] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  if (!isShowReasonForChangeDialog) {
    return null;
  }

  return (
    <Modal
      className='ReasonForChangeDialog'
      title={
        <h3>
          Reason For Change for <em>{fieldName}</em>
        </h3>
      }
      open={isShowReasonForChangeDialog}
      maskClosable={false}
      onCancel={() => {
        handleCancel();
        setIsShowReasonForChangeDialog(() => false);
      }}
      okText='Confirm Change'
      cancelText='Cancel Change'
      onOk={() => {
        const reasonForChange =
          selectorValue === 'Other' ? textAreaValue : selectorValue;
        if (reasonForChange === null || reasonForChange === '') {
          setIsAlertVisible(true);
          return;
        }
        saveData(valueToSave, reasonForChange);
        setIsShowReasonForChangeDialog(() => false);
      }}
      okButtonProps={{
        disabled: selectorValue === 'Other' && !textAreaValue?.trim()
      }}
    >
      <div>
        {isAlertVisible && (
          <Alert
            className='reason-dialog-alert'
            type='error'
            message='Please select a reason'
            closable='true'
          />
        )}
        <Descriptions column={1} bordered={true} size='small'>
          <Descriptions.Item label='Current Value'>
            {currentValue || INTENTIONALLY_LEFT_BLANK}
          </Descriptions.Item>
          <Descriptions.Item label='Previous Value'>
            {previousValue}
          </Descriptions.Item>
        </Descriptions>
        <Select
          className='reason-selector'
          placeholder='Please Select Reason for Change'
          onSelect={(value) => {
            if (value !== 'Other') {
              setIsAlertVisible(() => false);
            }
            setSelectorValue(() => value);
          }}
        >
          <Option value='Data entry error' />
          <Option value='Misheard the value' />
          <Option value='Mistyped the value' />
          <Option value='Other' />
        </Select>
        {selectorValue === 'Other' && (
          <Input.TextArea
            className='other-reason-input'
            placeholder='Other Reason'
            value={textAreaValue}
            onChange={(e) => {
              const value = e.target.value;
              if (
                selectorValue === 'Other' &&
                value !== null &&
                value?.length > 0
              ) {
                setIsAlertVisible(() => false);
              }
              setTextAreaValue(() => e.target.value);
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default ReasonForChangeDialog;
