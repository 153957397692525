import { useEffect } from "react";

export const useVisitProcedureTextFieldLimiters = ( protocolData ) => {
  useEffect(() => {
    const procedureData = protocolData?.protocol_list?.[0]?.study?.procedure;
    const fieldFlatMap = procedureData?.flatMap(( procedure ) => procedure?.dataentry?.regular);
    const limitedFields = fieldFlatMap?.filter(( field ) => field?.character_limit > 0 && (field?.type === 'string' || field.other));
    const limitedFieldInputData = limitedFields?.map(( field ) => {
      console.log({ field });
      if ( field?.other ) {
        return  {
          id: `input-:${ field?.id }::-other`,
          limit: field?.character_limit,
        };
      }
      return {
        id: `input-:${ field?.id }::`,
        limit: field?.character_limit,
      };
    });

    limitedFieldInputData?.forEach(( limitedFieldInputObj ) => {
      const escapedLimitedInputElementId = limitedFieldInputObj.id.replaceAll(/:/g, '\\:');
      const charLimit = limitedFieldInputObj.limit;

      if (escapedLimitedInputElementId.includes( '-other' )) {
        const inputOtherFieldElementList = document.querySelectorAll(`[id|='${ escapedLimitedInputElementId }']`);
        inputOtherFieldElementList?.forEach(( inputOtherFieldElement ) => {
          inputOtherFieldElement?.setAttribute('maxLength', charLimit);
          const limiterOtherFieldElement = inputOtherFieldElement?.nextElementSibling;
          limiterOtherFieldElement.removeAttribute('hidden');
          limiterOtherFieldElement.textContent = `${ inputOtherFieldElement.value.length }/${ charLimit }`;
          inputOtherFieldElement?.addEventListener('input', ( event ) => {
            limiterOtherFieldElement.textContent = `${ event.target.value.length }/${ charLimit }`;
          });
          inputOtherFieldElement?.addEventListener('keydown', ( event ) => {
            if ( event.target.value.length >= charLimit ) {
              limiterOtherFieldElement?.classList.add('error');
              limiterOtherFieldElement.textContent = `Character limit reached. ${ event.target.value.length }/${ charLimit }`;
            }
          });
        });
      }

      if ( !escapedLimitedInputElementId.includes( '-other' )) {
        const inputTextFieldElement = document.querySelector( `textarea[id='${ escapedLimitedInputElementId }']` );
        if ( inputTextFieldElement ) {
          inputTextFieldElement?.setAttribute('maxLength', charLimit);
          const limiterTextFieldElement = inputTextFieldElement?.nextElementSibling;
          limiterTextFieldElement.removeAttribute('hidden');
          limiterTextFieldElement.textContent = `${ inputTextFieldElement.value.length }/${ charLimit }`;
          inputTextFieldElement?.addEventListener('input', ( event ) => {
            limiterTextFieldElement.textContent = `${ event.target.value.length }/${ charLimit }`;
          });
          inputTextFieldElement?.addEventListener('keydown', ( event ) => {
            if ( event.target.value.length >= charLimit ) {
              limiterTextFieldElement?.classList.add('error');
              limiterTextFieldElement.textContent = `Character limit reached. ${ event.target.value.length }/${ charLimit }`;
            }
          });
        }
      }
    });
  }, [ protocolData?.protocol_list ]);
};