const isLockedFromLockArray = lock => {

  if(!lock) {
    return false;
  }

  const latestDate = Math.max( ...lock?.map( i => i.date ) );
  if(!latestDate) {
    return false;
  }

  return lock.find( i => i.date === latestDate )?.action ?? false;
};

const isSubjectLocked = ( subjectData, context ) => {

  if (!subjectData?.subjectId) {
    throw new Error('No subject data available');
  }

  if(!subjectData.subjectId === context?.subjectId) {
    throw new Error(`No data for subject '${context?.subjectId}'`); 
  }

  if(context.isSiteLocked){
    return true;
  }

  return isLockedFromLockArray( subjectData?.subjectInfo?.subject?.lock );
};

export const isVisitLocked = ( subjectData, context ) => {

  if(context.isSiteLocked){
    return true;
  }

  if( isSubjectLocked(subjectData, context) ) {
    return true;
  }

  // could also use enterVisitContext subscription to 'visit.data' message
  // at visitInfo.data.visit.lock
  // visitInfo also has subject_id at visitInfo.data.visits.subject_id

  if (!subjectData?.subjectInfo?.visits) {
    throw new Error('No visit data available');
  }

  if(subjectData.subjectInfo.visits.length === 0) { // new visit
    return false;
  }

  const visit = subjectData.subjectInfo.visits.find(
    v => context.visitId === v.visit_instance_id);

  if(!visit) {
    return false; // new visit, but subject has had other visits
  }

  return isLockedFromLockArray( visit?.lock );
};

export const isSubjectLogRowLocked = ( subjectData, subjectLogProcedureData,
                                                           context ) => {

  // could also use subjectLogProcedureSelection for lock (but not proc
  // validation): { index, level, crossout, lock }

  // subjectLogProcedureData also has subject id at
  // subjectLogProcedureData.subject_id 

  if(context.isSiteLocked){
    return true;
  }

  if( isSubjectLocked( subjectData, context )) {
    return true;
  }

  if(context.visitId) {
    throw new Error('Not subject log context');
  }

  if(!subjectLogProcedureData.procedure_id === context?.procedureId) {
    throw new Error(`No data for procedure '${context?.procedureId}'`); 
  }

  const row = subjectLogProcedureData?.evidence?.find(
    i => i.row === context.procedureRowNumber);

  if(!('row' in row)) { // didn't find anything
    throw new Error(`No data for procedure '${context?.procedureId
                        }', row '${ context.procedureRowNumber }'`); 
  }

  return isLockedFromLockArray( row?.lock );
  
};


