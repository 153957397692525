import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { PortalUtils } from './legacy/PortalUtils';
import { ProtocolComponents } from './components/ProtocolComponents';
import { SiteComponents } from './components/SiteComponents';
import { SubjectLogComponents } from './components/SubjectLogComponents';
import { SubjectLogRowComponents } from './components/SubjectLogRowComponents';
import { VisitComponents } from './components/VisitComponents';
import { ProcedureComponents } from './components/ProcedureComponents';
import { ProcedureSubjectLogComponents } from './components/ProcedureSubjectLogComponents';
import { SubjectComponents } from './components/SubjectComponents';
import { AdminComponents } from './components/admin/AdminComponents';
import { NotificationComponent } from './components/NotificationComponents.js';
import EditCheckNotifications from './components/EditCheckNotifications';
import ErrorBoundary from './components/error/ErrorBoundary';
import { useClassicConfirmMessages } from './hook/useClassicConfirmMessages';

import 'antd/dist/antd.min.css';
import './App.scss';

export const App = () => {

  const [
    classicConfirmMessageContextHolder
  ] = useClassicConfirmMessages({ classic: window.PubSubManager });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
      },
    }
  });

  return (
    <ErrorBoundary classic={window.PubSubManager}>
      <QueryClientProvider client={queryClient}>
        { classicConfirmMessageContextHolder }

        <EditCheckNotifications />
        <ProtocolComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <SiteComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <SubjectLogComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <SubjectLogRowComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <VisitComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <SubjectComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <ProcedureComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <AdminComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <ProcedureSubjectLogComponents
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        /> 

        <NotificationComponent
          classic={window.PubSubManager}
          portalUtils={PortalUtils}
        />

        <ReactQueryDevtools/>
      </QueryClientProvider>
    </ErrorBoundary>
  );

};
