import {
  protocolUserRoleFromUserData,
  isOkRoleForDataEntryView,
  isOkRoleForDataEntryModify,
} from '../../lib/roleUtils';

import { Field } from './Field';

export const DataEntryView = props => {

  const {
    userData,

    context,
    protocolData,
    protocolVersionId,
    siteInfo,
    procedureData,
    subjectLogProcedureSelection,

    procedureDefinition,

    fieldsData,

    isDataSaveInProgress,
    isSubjectLogRowLocked,
    reviewStatus,

    procedureQueryItems,
    fieldReviewItems,

    setIsDataSaveInProgress,
    refreshSubjectLogProcedureData,
    calculateNewProcedureState,

  } = props;

  const userRole = protocolUserRoleFromUserData(
    userData, context.protocolId);

  // procedure-dataentry-regular-modify
  const isEditView = isOkRoleForDataEntryModify(userRole) 
                     && !isSubjectLogRowLocked;

  // procedure-dataentry-regular-view
  if(!isEditView && !isOkRoleForDataEntryView( userRole )){
    return null;
  }
  // looks like log-subject-modify is handled in the classic table listing

  return (
    <div className='procedure-data-entry'>
      <h4>Data Entry</h4>
      { fieldsData.map(fieldData => (
        <Field {...{
          key: fieldData.field_instance_id,
          userData,

          context,
          protocolData,
          protocolVersionId,
          siteInfo,
          procedureData,
          fieldData,
          subjectLogProcedureSelection,

          procedureDefinition,

          procedureQueryItems,
          fieldReviewItems,

          isDataSaveInProgress,
          isSubjectLogRowLocked,
          reviewStatus,

          setIsDataSaveInProgress,
          refreshSubjectLogProcedureData,
          calculateNewProcedureState,

        }}/>
      )) }
    </div>
  );

};


