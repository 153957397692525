import { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Form, Select } from "antd";
import './ApiScope.scss';

export const ApiScopeSelectionForm = props => {
  const {
    apiScopeData,
    form,
    onFinish,
    onCancel,
    children,
  } = props;

  const {
    association_val: initialScopeSelectionData,
    procedures: proceduresUnsorted,
    is_edit_allowed: isEditAllowed,
  } = apiScopeData;

  const {
    odm_procedure_list: initiallySelectedOdmExportProcedures,
    import_procedure_list: initiallySelectedOdmUpdateProcedures,
    import_procedure_all: strIsInitiallySelectAllOdmUpdateProcedures,
    ...initialCategorySelectionData
  } = initialScopeSelectionData;

  const [ isOdmExportSelectorDisabled,
    setIsOdmExportSelectorDisabled ] = useState(
    !Boolean(initialCategorySelectionData.odmexport)
  );
  const [ odmExportSelection, setOdmExportSelection ] = useState( initiallySelectedOdmExportProcedures );
  const [ odmUpdateSelection, setOdmUpdateSelection ] = useState();

  const procedureOptions = useMemo( () => {
    const procedures = JSON.parse(JSON.stringify( proceduresUnsorted ));
    procedures.sort((a, b) => a.name < b.name ? -1 : 1 );
    return procedures.map(i => ({ value: i.id, label: i.name }));
  }, [ proceduresUnsorted ]);

  const initialFormData = {};
  for( const key of Object.keys( initialCategorySelectionData )) {
    if(initialCategorySelectionData[key] !== 'true') {
      continue;
    }
    initialFormData[ key ] = true;
  }

  initialFormData['odmexport-procedures'] =
    initiallySelectedOdmExportProcedures;

  const isInitiallySelectAllOdmUpdateProcedures =
    strIsInitiallySelectAllOdmUpdateProcedures === 'true';


  useEffect(() => {
    const v =
      isInitiallySelectAllOdmUpdateProcedures
        ? procedureOptions.map( i => i.value )
        : initiallySelectedOdmUpdateProcedures ?? [];
    setOdmUpdateSelection( v );
  }, [
    initiallySelectedOdmUpdateProcedures,
    isInitiallySelectAllOdmUpdateProcedures,
    procedureOptions,
  ]);

  // unlike odmUpdateSelection, initialFormData['odmupdate'] wont change

  initialFormData.odmupdate = isInitiallySelectAllOdmUpdateProcedures
    ? procedureOptions.map( i => i.value )
    : initiallySelectedOdmUpdateProcedures ?? [];

  return (
    <div className='ApiScopeSelectionForm'>
      <Form
        onFinish={ onFinish }
        initialValues={ initialFormData }
        form={ form }
      >
        { children }
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>Query Update</h4>
          <Form.Item
            name='query'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}> Query Update APIs </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>Query Read</h4>
          <Form.Item
            name='query_read'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}> Query Read APIs </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>SDTM Export</h4>
          <Form.Item
            name='export'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              SDTM Export APIs
            </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>Coding</h4>
          <Form.Item
            name='coding'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              Coding APIs
            </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>Site</h4>
          <Form.Item
            name='site'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              Site APIs
            </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>Subject</h4>
          <Form.Item
            name='subject'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              Add Subject API
            </Checkbox>
          </Form.Item>
          <Form.Item
            name='subject-schedule-start-visit'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              Schedule First Subject Visit API
            </Checkbox>
          </Form.Item>

          <Form.Item 
            name='subject_site_transfer'
            valuePropName='checked'
          >
            <Checkbox>
              Subject Site Transfer API
            </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>User</h4>
          <Form.Item
            name='user'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              User APIs
            </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>Custom Reports</h4>
          <Form.Item
            name='report'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              Custom Report APIs
            </Checkbox>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>ODM Export</h4>
          <Form.Item
            name='odmexport'
            valuePropName='checked'
          >
            <Checkbox
              disabled={!isEditAllowed}
              onChange={ e => {
                const isChecked = e.target.checked;
                const disabled = e.target.disabled;
                setIsOdmExportSelectorDisabled(!isChecked && !disabled);
                if( !isChecked ) {
                  setOdmExportSelection([]);
                  form.setFieldsValue({ 'odmexport-procedures': [], });
                }
              }}
            >
              ODM Export APIs
            </Checkbox>
          </Form.Item>
          <Form.Item
            name='odmexport-procedures'
          >
            <Select
              mode='multiple'
              allowClear={ true }
              options={ procedureOptions }
              value={ odmExportSelection }
              onChange={value => {
                form.setFieldsValue({
                  'odmexport-procedures': value,
                });
                setOdmExportSelection( value );
              }}
              defaultValue={initialFormData['odmexport-procedures']}
              disabled={ isOdmExportSelectorDisabled || !isEditAllowed }
            />
            <div>
              Please select procedures if you want to apply procedure specific
              access to ODM Form API. (By default it is available for all the
              forms.)
            </div>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>ODM Update</h4>
          <Form.Item
            name='odmupdate'
            valuePropName='checked'
          >
            <Select
              mode='multiple'
              options={[
                ...procedureOptions,
              ]}
              defaultValue={initialFormData['odmupdate']}
              value={ odmUpdateSelection }
              allowClear={ true }
              onChange={value => {
                form.setFieldsValue({
                  'odmupdate': value,
                });
                setOdmUpdateSelection( value );
              }}
              dropdownRender={ menu => (
                <>
                  <Button
                    className='select-dropdown-action'
                    type='primary'
                    onClick={() => {
                      const procedureValues = procedureOptions.map(
                        i => i.value);
                      form.setFieldsValue({
                        'odmupdate': procedureValues,
                      });
                      setOdmUpdateSelection( procedureValues );
                    }}
                  >
                    Select All
                  </Button>
                  { menu }
                </>
              )}
              disabled={!isEditAllowed}
            />
            <div>
              ODM Procedure Update API
            </div>
          </Form.Item>
        </div>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>System Config</h4>
          <Form.Item
            name='systemconfig'
            valuePropName='checked'
          >
            <Checkbox disabled={!isEditAllowed}>
              System Config APIs, needs to be enabled for any one protocol to
              get access
            </Checkbox>
          </Form.Item>
        </div>
        <Form.Item className='buttons'>
          <Button type='primary' htmlType='submit' hidden={!isEditAllowed}>
            Submit
          </Button>
          <Button htmlType='button' onClick={onCancel} hidden={!isEditAllowed}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
