import { Form, Select } from "antd";
import { sendAdminApiScopeAdd, classicCancelAction } from "../utils";
import { ApiScopeSelectionForm } from "../ApiScopeSelectionForm";
import '../ApiScope.scss';

export const ApiScopeAssociateEditor = props => {
  const {
    apiAssociateScopeData,
  } = props;

  const {
    apis: apiItems,
    protocol_number: protocolNumber,
    protocol_id: protocolId,
    is_edit_allowed: isEditAllowed,
    ...initialApiScopeData
  } = apiAssociateScopeData;

  const apiScopeData = {
    ...initialApiScopeData,
    association_val: {},
    is_edit_allowed: isEditAllowed,
  };

  const {
    procedures,
  } = apiScopeData;

  const [ form ] = Form.useForm();

  const apiOptions = apiItems.map(i => ({
    value: i.api_id, label: i.api_name,
  }));
  apiOptions.sort( (a, b) => a.label < b.label ? -1 : 1 );

  /* follows
       api_scope_add('9fba598a-b1df-464a-85e3-d4b2f85498be',
                     'Lighthouse GO44296');
  */
  const onFinish = values => {
    const {
      'odmexport-procedures': odmExportProcedureSelections,
      odmupdate: odmImportProcedureSelections,
      'associated-api': associatedApiId,
      ...scopeSelections
    } = values;

    sendAdminApiScopeAdd(
      isEditAllowed,
      protocolId,
      protocolNumber,
      associatedApiId,
      procedures.map( i => i.id ),
      scopeSelections,
      odmExportProcedureSelections ?? [],
      odmImportProcedureSelections ?? [],
    );
  };

  return (
    <div id='ApiAssociateScopeEditor'>
      <h3 id='admin-title' >{ protocolNumber } - Add API Scope</h3>
      <div className='scope-guidance'>
        Only select API scope for which client needs access.
      </div>
      <ApiScopeSelectionForm {...{
        apiScopeData,
        form,
        onFinish,
        onCancel: () => classicCancelAction(apiAssociateScopeData),
      }}>
        <div className='api-scope-category'>
          <h4 className='api-scope-category-title'>API to Associate</h4>
          <Form.Item
            name='associated-api'
            rules={[{
              required: true,
              message: 'Please select an API to associate',
            }]}
          >
            <Select
              options={ apiOptions }
              onChange={value => form.setFieldsValue({
                'associated-api': value,
              })}
            />
          </Form.Item>
        </div>
      </ApiScopeSelectionForm>
    </div>
  );
};
