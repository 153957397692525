export const DocumentsData = (props) => {
  const { protocolId, protocolNumber, branchName, docs, isEditAllowed } = props;
  return (
    <>
      <br />
      <h2 className="sub-header" id="admin-title">{protocolNumber} - Documents ({branchName})</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Updated</th>
              <th>Download</th>
              {isEditAllowed && <th>Update</th>}
            </tr>
          </thead>
          <tbody>
            {docs.map(doc => (
              <tr key={doc.name}>
                <td id="document-name" data-name={doc.display_name}>{doc.display_name}</td>
                <td id="document-date" data-name={doc.display_name} data-date={doc.date_updated_raw}>{doc.date_updated}</td>
                <td>
                  <a
                    name="document-download"
                    data-name={doc.display_name}
                    onClick={() => {
                      window.document_download(doc.name, protocolId, branchName);
                      return false;
                    }}
                  >
                    Link
                  </a>
                </td>
                {isEditAllowed && (
                  <td>
                    <a
                      name="document-upload"
                      data-name={doc.display_name}
                      onClick={() => {
                        window.document_update_show(
                          protocolId,
                          protocolNumber,
                          branchName,
                          doc.name,
                          doc.display_name,
                          'file',
                          isEditAllowed
                        );
                        return false;
                      }}
                    >
                      Upload File
                    </a>
                    &nbsp;/&nbsp;
                    <a
                      name="document-update"
                      data-name={doc.display_name}
                      onClick={() => {
                        window.document_update_show(
                          protocolId,
                          protocolNumber,
                          branchName,
                          doc.name,
                          doc.display_name,
                          'name',
                          isEditAllowed
                        );
                        return false;
                      }}
                    >
                      Update Name
                    </a>
                  </td>
                )}
              </tr>
            ))}
            {isEditAllowed && (
              <tr>
                <td colSpan="4">
                  <a
                    id="document-new"
                    className="btn btn-default"
                    onClick={() => {
                      window.document_add_show(protocolId, protocolNumber, branchName, true);
                      return false;
                    }}
                  >
                    + New Document
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
