import { StrictMode }  from 'react';
import { createRoot } from 'react-dom/client';

import './i18n';
import { App } from './App';

import './index.scss';

const root = createRoot(document.getElementById('react-app-root'));

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);


