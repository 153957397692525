export const Icf = (props) => {
  const { protocolId, protocolNumber, icfVersions, isEditAllowed } = props;
  return (
    <>
      <h2 className="sub-header" id="admin-title">{protocolNumber} - ICF</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Version Name</th>
            </tr>
          </thead>
          <tbody>
            {icfVersions.length ? icfVersions.map((icfVersion) => (
              <tr id="icf-row" key={icfVersion.version}>
                <td>
                  <a
                    id="icf-download"
                    name={icfVersion.version}
                    onClick={() => window.protocol_icf_download(protocolId, icfVersion.version)}
                  >
                    {icfVersion.version}
                  </a>
                </td>
                {isEditAllowed && (
                  <td>
                    <a
                      id="icf-upload"
                      name={icfVersion.version}
                      onClick={() => window.protocol_icf_upsert_show(protocolId, icfVersion.version, isEditAllowed)}
                    >
                      Update Document
                    </a>
                  </td>
                )}
              </tr>
            )) : (
              <tr>
                <td colSpan="5">No Associated ICFs</td>
              </tr>
            )}
            {isEditAllowed && (
              <tr>
                <td colSpan="4">
                  <a
                    id="icf-add"
                    className="btn btn-default"
                    onClick={() => {
                      window.protocol_icf_upsert_show(protocolId, null, isEditAllowed);
                      return false;
                    }}
                  >
                    + New Version
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
};
