import { ApiScopeEditor } from "./ApiScopeEditor";

export const ApiScopeEditorContainer = props => {
  const { portalUtils, apiScopeData, resetScopeData } = props;

  if(!apiScopeData) {
    return null;
  }

  return portalUtils.attachAdminApiScopeEditContent(
    <ApiScopeEditor {...{
      apiScopeData,
    }}/>,
    apiScopeData,
    resetScopeData,
  );
};
