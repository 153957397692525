import {
  Modal,
} from 'antd';

import {
  SimplifiedSubjectLogProcedureItemView,
} from './SubjectLogProcedureItemView';

import './ProcedureSubjectLogItemModal.scss';

export const ProcedureSubjectLogItemModal = props => {

  const {
    userData,

    protocolData,
    siteInfo,
    subjectData,
    subjectLogProcedureData,
    subjectLogProcedureSelection,

    refreshSubjectLogProcedureData,
    resetSubjectLogProcedureSelection,
  } = props;

  const showRowList = () => resetSubjectLogProcedureSelection();

  return (
    <Modal
      className='ProcedureSubjectLogItemModal'
      visible={ Boolean( subjectLogProcedureSelection ) }
      onCancel={ showRowList }
      cancelText={ 'Done' }
      closable={ false }
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div>
        <SimplifiedSubjectLogProcedureItemView { ...{
          userData,

          protocolData,
          siteInfo,
          subjectData,
          subjectLogProcedureData,
          subjectLogProcedureSelection,

          refreshSubjectLogProcedureData,

          } }/>
      </div>
    </Modal>

  );
};
