import { rights } from '../data/rights';

export const UserRole = Object.freeze({
  Auditor: 'auditor',
  Central: 'central',
  DM: 'dm',
  HomeNurse: 'home-nurse',
  HomeNurseManager: 'home-nurse-manager',
  Monitor: 'monitor',
  PI: 'pi',
  ReadOnly: 'read-only',
  Safety: 'safety',
  Site: 'site',
  Api: 'api',
});

export const FieldReviewRole = Object.freeze({
  Reviewer: 'reviewer',
  Viewer: 'viewer',
});

export const QueryRole = Object.freeze({
  Recipient: 'recipient',
  Initiator: 'initiator',
});

export const formatUserRole = role => {
  const map = {
    [UserRole.Auditor]: 'Auditor',
    [UserRole.Central]: 'Central Reader',
    [UserRole.DM]: 'Data Manager',
    [UserRole.HomeNurse]: 'Home Nurse',
    [UserRole.HomeNurseManager]: 'Home Nurse Manager',
    [UserRole.Monitor]: 'Monitor',
    [UserRole.PI]: 'Principal Investigator',
    [UserRole.ReadOnly]: 'Read Only User',
    [UserRole.Safety]: 'Safety User',
    [UserRole.Site]: 'Site User',
    [UserRole.Api]: 'API'
  };
  return map[role] ?? role; // accomodates 'fakerole'
};

export const findQueryRole = role => {

  const isInitiator = [ UserRole.DM, UserRole.Safety, UserRole.Monitor  ].includes(role);
  if(isInitiator) {
    return QueryRole.Initiator;
  }

  const isRecipient = [ UserRole.PI, UserRole.Site, UserRole.HomeNurse, UserRole.Central ].includes(role);
  if(isRecipient) {
    return QueryRole.Recipient;
  }

  return null;
};

export const findFieldReviewRole = role => {

  const isReviewer = role === UserRole.Monitor;
  if(isReviewer) {
    return FieldReviewRole.Reviewer;
  }
  
  const isViewer = [ UserRole.DM, UserRole.Safety, UserRole.Auditor, UserRole.ReadOnly  ].includes(role);
  if(isViewer) {
    return FieldReviewRole.Viewer;
  }

  return null;
};

export const protocolUserRoleFromUserData = (userData, protocolId) =>
  userData?.roles?.find(r => r?.protocol_id === protocolId)?.user_type;


const isUserHasRight = (right, role) =>
  rights[right].includes(role);

export const isOkRoleForDataEntryView = role =>
  isUserHasRight( 'procedure-dataentry-regular-view', role );

export const isOkRoleForDataEntryModify = role =>
  isUserHasRight( 'procedure-dataentry-regular-modify', role );

export const isOkRoleForEvidenceView = role =>
  isUserHasRight( 'certified-copy-view', role );

export const isOkRoleForEvidenceAdd = role =>
  isUserHasRight( 'certified-copy-add', role );

export const isOkRoleForInvalidatedEvidenceView = role =>
  isUserHasRight( 'certified-copy-invalidated-view', role );

export const isOkRoleForDataExport = role =>
  isUserHasRight( 'data_export', role );

export const isOkRoleForListingQuery = role =>
  isUserHasRight( 'listing_query', role );

export const isOkRoleForListingArtifact = role =>
  isUserHasRight( 'listing_artifact', role );

export const isOkRoleForPerformanceGuidanceView = (role, type) =>
  isUserHasRight( `performance-guidance-${type}-view`, role );

export const isOkRoleForPerformanceGuidanceModify = (role, type) =>
  isUserHasRight( `performance-guidance-${type}-modify`, role );

export const isOkRoleForReviewAction = role => {
  // as long as the subject log review dialog is rendered by
  // review_button_click(), we can rely on that function to perform individual
  // checks on the permissible actions.

  //However, we still need to know whether to show a submit action
  // ('obj.action_show')

  return isUserHasRight('review_close', role)
         || isUserHasRight('review_query', role)
         || isUserHasRight('review_reopen', role)
         || isUserHasRight('review_submit', role);
};
