import {v4 as uuid} from "uuid";
import { EDIT, ADD } from "../../constants";

const MessageNames = Object.freeze({
  ApiScopeUpdate: 'api.scope.update',
  AdminProtocolList:  'api.admin.protocol.list'
});

const sendAdminApiScopeAction = (
  action,
  isEditAllowed,
  protocolId,
  protocolNumber,
  apiId,
  procedureIds,
  scopeSelections,
  odmExportProcedureSelections,
  odmImportProcedureSelections,
) => {
  const selectedScopes = {};
  for( const k of Object.keys( scopeSelections )) {
    if( !Boolean(scopeSelections[k]) ) {
      continue;
    }
    selectedScopes[k] = 'true';
  }

  const isOdmProcedureImportAll = procedureIds.every(id => odmImportProcedureSelections.includes(id));

  const odmImportProcedureProperties =
    odmImportProcedureSelections.length === 0
      // if odmImportProcedureSelections is empty: no related properties
      ? {}
      : isOdmProcedureImportAll
        // if isOdmProcedureImportAll is true: no import_procedure_list
        ? {
          import_procedure_all: 'true',
        }
        : {
          import_procedure_all: 'false',
          import_procedure_list: odmImportProcedureSelections
        };

  const message = {
    id: uuid(),
    name: MessageNames.ApiScopeUpdate,
    action_type: action,
    protocol_id: protocolId,
    protocol_number: protocolNumber,
    is_edit_allowed: isEditAllowed,
    api_id: apiId,
    api_scope: {
      protocol_list: 'true',
      ...selectedScopes,
      odm_procedure_list: odmExportProcedureSelections,
      ...odmImportProcedureProperties,
    },
  };
  window.ws_send( message );
};

export const sendAdminApiScopeUpdate = (
  isEditAllowed,
  protocolId,
  protocolNumber,
  apiId,
  procedureIds,
  scopeSelections,
  odmExportProcedureSelections,
  odmImportProcedureSelections,
) => {
  sendAdminApiScopeAction(
    EDIT,
    isEditAllowed,
    protocolId,
    protocolNumber,
    apiId,
    procedureIds,
    scopeSelections,
    odmExportProcedureSelections,
    odmImportProcedureSelections,
  );
};

export const sendAdminApiScopeAdd = (
  isEditAllowed,
  protocolId,
  protocolNumber,
  apiId,
  procedureIds,
  scopeSelections,
  odmExportProcedureSelections,
  odmImportProcedureSelections,
) => {
  sendAdminApiScopeAction(
    ADD,
    isEditAllowed,
    protocolId,
    protocolNumber,
    apiId,
    procedureIds,
    scopeSelections,
    odmExportProcedureSelections,
    odmImportProcedureSelections,
  );
};

/**
 follows api_scope_protocol_list(
 'fe1bfd7a-9f87-4722-b793-91ce92447548', // protool_id
 'PRT543-01' // protocol_number
 );
 */
export const classicCancelAction = apiScopeData => {
  const {
    protocol_id: protocolId,
    protocol_number: protocolNumber,
    is_edit_allowed: isEditAllowed
  } = apiScopeData;

  window.ws_send({
    id: uuid(),
    name: MessageNames.AdminProtocolList,
    protocol_id: protocolId,
    protocol_number: protocolNumber,
    is_edit_allowed: isEditAllowed,
  });
};
