import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

export default ({notification, removeEditCheckNotification}) => {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const handleShowMore = e => {
    e.preventDefault();
    setShowFullMessage(true);
  };

  const truncateMessage = (message, maxLength) => {
    if (message.length <= maxLength) {
      return message;
    }
    return message.substring(0, maxLength) + '...';
  };

  return (
    <div className="edit-check-notification" key={notification.id}>
      <div className="edit-check-notification-content">
        <div className="edit-check-metadata">
          <div className="edit-check-category">Subject</div>
          <div className="edit-check-value">{notification.subject}</div>
        </div>
        <div className="edit-check-metadata">
          <div className="edit-check-category">Visit</div>
          <div className="edit-check-value">{notification.visit}</div>
        </div>
        <div className="edit-check-metadata">
          <div className="edit-check-category">Form</div>
          <div className="edit-check-value">{notification.procedure}</div>
        </div>
        {notification.is_field_level_query && (
          <div className="edit-check-metadata">
            <div className="edit-check-category">Field</div>
            <div className="edit-check-value">{notification.field}</div>
          </div>
        )}
        <div className="edit-check-metadata">
          <div className="edit-check-category">Message</div>
          <div className="edit-check-value">
            {
              showFullMessage ?
                notification.description :
                truncateMessage(notification.description, 69)
            }
            {!showFullMessage && notification.description.length > 69 && (
              <span>
                <a href="#" onClick={handleShowMore} style={{ marginLeft: '5px' }}>Show More</a>
              </span>
            )}
          </div>
        </div>
      </div>
      <CloseOutlined
        style={{ fontSize: '10px' }}
        onClick={() => removeEditCheckNotification(notification.id)}
        className="close-edit-check-notification-button"
      />
    </div>
  )
}
