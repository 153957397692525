const ReadOnlyProtocolForm = (props) => {
  const { protocolNumber, allowSubjectEnroll, disallowSubjectEnrollReason } = props;
  return (
    <>
      <h2 className="sub-header" id="admin-title">{protocolNumber} - View</h2>
      <form>
        <fieldset>
          <div className="form-group" id="protocol_number_div">
            <label htmlFor="protocol_number">Protocol Number</label>
            <div className="controls">
              <input type="text" id="protocol_number" value={protocolNumber} disabled />
            </div>
          </div>
          <div className="form-group" id="subject_add_allow">
            <label htmlFor="allow_subject_add">Allow Subject Enrollment</label>
            <div className="controls">
              <div className="checkbox">
                <label>
                  <input type="radio" name="allow_subject_add" value="true" checked={allowSubjectEnroll} disabled />
                    Yes
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="radio" name="allow_subject_add" value="false" checked={!allowSubjectEnroll} disabled />
                    No <input type="text" className="form-control" id="enroll_reason" placeholder="Status" value={disallowSubjectEnrollReason} disabled />
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  )
};

export default ReadOnlyProtocolForm;
