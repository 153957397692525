import { useEffect, useState } from "react";
import { IcfContainer } from "./IcfContainer";

export const IcfComponents = (props) => {
  const { classic, portalUtils } = props;
  const [ icfData, setIcfData ] = useState( null );

  useEffect(() => {
    classic.subscribe('IcfComponents', 'icfData', setIcfData);
  }, [classic]);

  const resetIcfData = () => setIcfData( null );

  return (
    <>
      <IcfContainer {...{
        portalUtils,
        icfData,
        resetIcfData
      }}/>
    </>
  );
};
