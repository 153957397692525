import { INTENTIONALLY_LEFT_BLANK } from './constants';
import { getReadOnlyContent } from './Field';
import ReasonForChangeDialog from './ReasonForChangeDialog';

const FieldReasonForChangeDialog = (props) => {
  // follows classic/js/src/visit.page.js rfc_show()
  const {
    isShowReasonForChangeDialog,
    fieldDefinition,
    fieldValue,
    fieldData,
    setIsShowReasonForChangeDialog,
    setFieldValue,
    setIsResetDisplayValue,
    saveData,
  } = props;

  const previousValue =
    'data' in fieldData
      ? 'value' in fieldData.data[0]
        ? getReadOnlyContent(
            fieldDefinition.type,
            fieldData.data[0].value,
            fieldDefinition,
            fieldData.data[0]
          )
        : INTENTIONALLY_LEFT_BLANK
      : '';

  const currentValue = fieldValue
    ? getReadOnlyContent(fieldDefinition.type, fieldValue, fieldDefinition, {
        // follows classic field_save()
        offset: new Date(fieldValue).getTimezoneOffset() * -60000,
      })
    : INTENTIONALLY_LEFT_BLANK;

  return (
    <ReasonForChangeDialog
      isShowReasonForChangeDialog={isShowReasonForChangeDialog}
      setIsShowReasonForChangeDialog={setIsShowReasonForChangeDialog}
      fieldName={fieldDefinition.text}
      currentValue={currentValue}
      previousValue={previousValue}
      saveData={saveData}
      valueToSave={fieldValue}
      handleCancel={() => {
        setFieldValue(() => fieldData?.data?.[0]?.value);
        setIsResetDisplayValue(() => true);
      }}
    />
  );
};

export default FieldReasonForChangeDialog;
