import { useEffect } from 'react';
import { getApplicationEnvironment } from "../legacy/LegacyFacade";

const useEditCheckWebSocket = ({
  queryClient,
  addEditCheckNotification,
  removeEditCheckNotification,
}) => {
	const applicationEnvironment = getApplicationEnvironment();

	useEffect(() => {
		let websocket;
		const connectWebSocket = () => {
			websocket = new WebSocket(applicationEnvironment.ws);
			websocket.onopen = () => {
				websocket.send(JSON.stringify({name: 'editcheck', action: 'subscribe'}))
			};
			websocket.onclose = () => {
				connectWebSocket();
			};
			websocket.onerror = (error) => {
				console.error('WebSocket Error: ', error);
			};
			websocket.onmessage = event => {
				const message = JSON.parse(event.data);
				if (message.name === "refresh_queries") {
					let { visit, procedure, field, is_field_level_query, procedure_row, subject, open, description } = message;

					if (procedure_row && visit === "Subject Log") {
						procedure = `${procedure} - Row ${parseInt(procedure_row) + 1}`;
					}

					const id = visit + procedure + field + subject;

					if (open) {
						removeEditCheckNotification(id)
						addEditCheckNotification({ visit, procedure, field, is_field_level_query, id, subject, description })
					} else {
						removeEditCheckNotification(id)
					}

					queryClient.invalidateQueries('gui/queryList');
				}
			};
		};

		connectWebSocket();

		return () => {
			websocket.close();
		};
	}, [
       applicationEnvironment.ws,
     ]);
};

export default useEditCheckWebSocket;
