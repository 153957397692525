import { useState, useEffect } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { requestSubjectHistory } from '../../legacy/LegacyFacade';

const SubjectIdHistory = (props) => {
  const {
    siteContext,
    subjectId,
    subjectIdHistory
  } = props;

  const [subjectIdChanges, setSubjectIdChanges] = useState([]);

  useEffect(() => {
    requestSubjectHistory({
      ...siteContext,
      subjectId,
    });
  }, [siteContext, subjectId]);

  useEffect(() => {
    if (!subjectIdHistory) {
      return;
    }

    const subjectIdHistoryCopy = subjectIdHistory.map(change => ({ ...change}));
    const firstChange = subjectIdHistoryCopy[subjectIdHistoryCopy.length-1];
    firstChange.rfc = firstChange.rfc || 'No Previous Value';
    setSubjectIdChanges(subjectIdHistoryCopy)
  }, [subjectIdHistory]);


  if (!subjectIdHistory) {
    return null;
  }
  
  const columns = [
    {
      title: 'When',
      dataIndex: 'date',
      key: 'date',
      render: (text, data) => {
        if (data?.transaction_date) {
          /* 
           * The transaction_date pushed from clinical pipe should take 
           * precedence over the date from the Vessel application
           */
          return moment(data.transaction_date).format('L, HH:mm:ss');
        }
        return moment(text).format('L, HH:mm:ss');
      },
    },
    {
      title: 'Who',
      dataIndex: 'who',
      key: 'who',
    },
    {
      title: 'Why',
      dataIndex: 'rfc',
      key: 'rfc',
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <Table
      className='subject-id-history'
      title={() => (
        <span className='history-table-title'>Subject ID History</span>
      )}
      dataSource={subjectIdChanges ?? []}
      columns={columns}
      pagination={false}
      rowKey={(record) => record?.date}
    />
  );
};

export default SubjectIdHistory;
