import { useState } from 'react';
import { capitalizeFirstLetter } from '../lib/stringUtils'
import {
  Card,
  Button,
  Radio,
  Input,
  Collapse,
  Form,
  Space,
  Badge,
} from 'antd';

import ReactTimeAgo from 'react-time-ago';

import { useDataUpdate } from '../hook/useDataUpdate';
import {
  findQueryRole,
  protocolUserRoleFromUserData,
  formatUserRole,
} from '../lib/roleUtils';

import './DataItemQueries.scss';

const capitalizeAllLetters = str => {
  return str.toUpperCase();
};

export const formatDate = timestamp =>
  <ReactTimeAgo date={new Date(timestamp)} />;

// note that the query statuses are not identical to the message actions
export const QueryStatus = {
  Open: 'open',
  Answered: 'answered',
  Closed: 'closed',
};

const presentationStatus = {
  [QueryStatus.Open]: 'Open',
  [QueryStatus.Answered]: 'Answered',
  [QueryStatus.Closed]: 'Closed',
};

const presentationMessageAction = {
  open: 'Opened',
  close: 'Closed',
  answer: 'Answered',
};

const Message = props => {

  const {
    author,
    date,
    content,
    action,
    clientQueryId,
  } = props;

  return (
    <div className='message'>
      <div className='author-details'>
        <span className='action'>
          {
            `${presentationMessageAction[action]} by `
          }
        </span>
        <span className='author-name'>
          {
            author?.apiName ? `${author.apiName}, ${capitalizeAllLetters(author.role ?? '')}` :
                      capitalizeFirstLetter(author.firstName ?? '')
          } {
            author?.apiName ? '' :capitalizeFirstLetter(author.lastName ?? '')
          }
        </span>, <span className='author-role'>
          {clientQueryId ? clientQueryId : formatUserRole(author.role)}
        </span>
      </div>
      <div className='date'>{formatDate(date)}</div>
      <div className='message-content'>
        {content}
      </div>
    </div>
  );
};

const MessageList = props => {

  const {
    className,
    principalQueryRole,
    thread,
    userData,
    clientQueryId,
  } = props;

  const sortedThread = JSON.parse(JSON.stringify(thread));
  sortedThread.sort((a, b) => b.date - a.date); // date DESC

  return (
    <div className={`message-list ${className ||''}`}>
      {sortedThread.map(message => {
        return (
          <Message
            userData={userData}
            clientQueryId={clientQueryId}
            key={`${message.author.email}-${message.date}`}
            principalQueryRole={principalQueryRole}
            {...message}
          />
        );
      })}
    </div>
  );
};

export const SiteRoleReplyForm = props => {

  const { queryItem: existingQueryItem, userData } = props;

  const [ form ] = Form.useForm();
  const [ isSubmitButtonActive, setIsSubmitButtonActive ] = useState(false);

  const queryUpdate = useDataUpdate({
                        endpoint: 'gui/queryUpdate',
                        invalidates: [
                          'gui/queryList',
                          'gui/queryCountList',
                          ],
                        });

  const updateData = async values => {

    await queryUpdate.mutateAsync({
      data: {

        etag: values.etag,
        date: values.date,
        status: values.status,
        supplement: values?.supplement,
        thread: values.thread,
        context: values.context,
        siteSerialNumber: values.siteSerialNumber,
        initiatingRole: values.initiatingRole,

      },
    },
    {
      onSuccess: async (data, variables, context) => {
        setIsSubmitButtonActive(false);
        form.resetFields();
      },
    });
  };

  const onFinish = values => {

    const {
      author,
      date,
      existingQueryItem,
      text,
      } = values;

    const message = {
      date,
      action: 'answer',
      content: text,
      author,
    };

    const queryItem = {
      ...existingQueryItem,
      date,
      status: 'answered',
      thread: [
        ...existingQueryItem.thread,
        message,
      ],
    };

    updateData(queryItem);

  };

  const onTextChange = e => {
    const value = e.target.value;
    setIsSubmitButtonActive(value.length > 0);
  };

  const author = {
    email: userData.user.email,
    firstName: userData.user.first_name,
    lastName: userData.user.last_name,
    affiliation: userData.user.org,
    role: protocolUserRoleFromUserData(userData,
                                       existingQueryItem.context.protocolId),
  };

  const date = Date.now();

  const { TextArea } = Input;

  return(
    <div className='SiteRoleReplyForm'>
      <Form
        className='reply'
        form={form}
        onFinish={onFinish}
        initialValues={{
          existingQueryItem,
          action: null,
          text: '',
          author,
          date,
        }}
      >
        <Form.Item
          name='existingQueryItem'
          hidden={true}
        >
          <span />
        </Form.Item>
        <Form.Item name='text'
            className='text'
        >
          <TextArea
            onChange={onTextChange}
            placeholder='Add response ...'
          />
        </Form.Item>
        <Form.Item name='author' hidden={true}>
          <span />
        </Form.Item>
        <Form.Item name='date' hidden={true}>
          <span />
        </Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          disabled={!isSubmitButtonActive}
        >Reply</Button>
      </Form>
    </div>
  );
};


export const DmRoleCloseOrReplyForm = props => {

  const { queryItem: existingQueryItem, userData, allowClose } = props;

  const [ form ] = Form.useForm();
  const [ isSubmitButtonActive, setIsSubmitButtonActive ] = useState(false);

  const queryUpdate = useDataUpdate({
                        endpoint: 'gui/queryUpdate',
                        invalidates: [
                          'gui/queryList',
                          'gui/queryCountList',
                        ],
                      });

  const updateData = async values => {

    await queryUpdate.mutateAsync({
      data: {

        etag: values.etag,
        date: values.date,
        status: values.status,
        supplement: values?.supplement,
        thread: values.thread,
        context: values.context,
        siteSerialNumber: values.siteSerialNumber,
        initiatingRole: values.initiatingRole,

      },
    },
    {
      onSuccess: async (data, variables, context) => {
        setIsSubmitButtonActive(false);
        form.resetFields();
      },
    });
  };

  const onFinish = values => {

    const {
      author,
      date,
      action,
      existingQueryItem,
      text,
      } = values;

    const message = {
      date,
      action,
      content: text,
      author,
    };

    const queryItem = {
      ...existingQueryItem,
      date,
      status: action === 'close' ? 'closed' : 'answered',
      thread: [
        ...existingQueryItem.thread,
        message,
      ],
    };

    updateData(queryItem);

  };


  const onTextChange = e => {
    const value = e.target.value;
    setIsSubmitButtonActive(value.length > 0);
  };

  const author = {
    email: userData.user.email,
    firstName: userData.user.first_name,
    lastName: userData.user.last_name,
    affiliation: userData.user.org,
    role: protocolUserRoleFromUserData(userData,
                                       existingQueryItem.context.protocolId),
  };

  const date = Date.now();

  const { TextArea } = Input;

  return(
    <div className='DmRoleCloseOrReplyForm'>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            existingQueryItem,
            text: '',
            author,
            date,
          }}
        >
          <Form.Item
            name='existingQueryItem'
            hidden={true}
          >
            <span />
          </Form.Item>
          <Form.Item name='action'
          className='options'
          hidden={true}
          >
          <Radio.Group
          >
            <Space direction='vertical'>
              <Radio value='close'>Close</Radio>
              <Radio value='open'>Reply</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

          <Form.Item name='text'
              className='text'
          >
            <TextArea
              onChange={onTextChange}
              placeholder={'Add response...'}
            />
          </Form.Item>
          <Form.Item name='author' hidden={true}>
            <span />
          </Form.Item>
          <Form.Item name='date' hidden={true}>
            <span />
          </Form.Item>
          <div className='control-bar'>
          { allowClose && <Button
              className='closeButton'
              value={'close'}
              onClick={(e)=> {
                form.setFieldsValue({
                  action: 'close',
                });
              }}
              htmlType='submit'
            >Mark as closed</Button>}
            <Button
              className='replyButton'
              value={'open'}
              onClick={(e)=> {
                form.setFieldsValue({
                  action: 'open'
                });
              }}
              id='replyButton'
              htmlType='submit'
              disabled={!isSubmitButtonActive}
            >Reply</Button>
          </div>
        </Form>
    </div>
  );
};

export const SiteRoleAnswerForm = props => {

  const { queryItem: existingQueryItem, userData, renderClassicProcedureView, context } = props;

  const [ form ] = Form.useForm();
  const [ isSubmitButtonActive, setIsSubmitButtonActive ] = useState(false);

  const queryUpdate = useDataUpdate({
                        endpoint: 'gui/queryUpdate',
                        invalidates: [
                          'gui/queryList',
                          'gui/queryCountList',
                        ],
                      });

  const updateData = async values => {

    await queryUpdate.mutateAsync({
      data: {

        etag: values.etag,
        date: values.date,
        status: values.status,
        supplement: values?.supplement,
        thread: values.thread,
        context: values.context,
        siteSerialNumber: values.siteSerialNumber,
        initiatingRole: values.initiatingRole,

      },
    },
    {
      onSuccess: async (data, variables, context) => {
        setIsSubmitButtonActive(false);
        form.resetFields();
      },
    });
  };

  const onFinish = async values => {

    const {
      author,
      date,
      existingQueryItem,
      text,
      } = values;

    const message = {
      date,
      action: 'answer',
      content: text,
      author,
    };

    const queryItem = {
      ...existingQueryItem,
      date,
      status: 'answered',
      thread: [
        ...existingQueryItem.thread,
        message,
      ],
    };

    await updateData(queryItem);
    // todo : only call next line if in visit
    if((context?.visitIdChar)){
      renderClassicProcedureView(context);
    }
  };

  const onTextChange = e => {
    const value = e.target.value;
    setIsSubmitButtonActive(value.length > 0);
  };

  const author = {
    email: userData.user.email,
    firstName: userData.user.first_name,
    lastName: userData.user.last_name,
    affiliation: userData.user.org,
    role: protocolUserRoleFromUserData(userData,
                                       existingQueryItem.context.protocolId),
  };

  const date = Date.now();


  const { TextArea } = Input;

  return (
    <div className='SiteRoleAnswerForm'>
      <Form
        className='reply'
        form={form}
        onFinish={onFinish}
        initialValues={{
          existingQueryItem,
          text: '',
          author,
          date,
        }}
      >
        <Form.Item
          name='existingQueryItem'
          hidden={true}
        >
          <span />
        </Form.Item>

        <Form.Item name='text' className='text' >
          <TextArea
            onChange={onTextChange}
            placeholder='Add response ...'
          />
        </Form.Item>

        <Form.Item name='author' hidden={true}>
          <span />
        </Form.Item>

        <Form.Item name='date' hidden={true}>
          <span />
        </Form.Item>

        <Button
          type='primary'
          htmlType='submit'
          disabled={!isSubmitButtonActive}
        >Reply</Button>
      </Form>
    </div>
  );
};

const QueryHeader = props => {

  const {
    queryItem,

  } = props;

  const clientQueryIdLabel = (queryItem.hasOwnProperty('supplement') &&
                              queryItem.supplement.hasOwnProperty('clientQueryId')) ?
                              `[${queryItem.supplement.clientQueryId}]` : '';

  const queryItemStatus = presentationStatus[queryItem.status];

  return (
    <div className='QueryHeader'>
      <b>{ queryItem.context.presentation.siteNumber
          }-{ queryItem.siteSerialNumber }</b>
      <Badge
        className={`status-indicator ${queryItem.status}`}
        text={`${queryItemStatus} ${clientQueryIdLabel}`}
        color='white'
       />
     </div>
  );
};

export const Query = props => {

  const {
    queryItem,
    isExpanded,
    isCollapsible = true,
    children,
  } = props;

  const { thread } = queryItem;
  thread.reverse();

  if(!isCollapsible) {
    return (
      <Card
        className='Query'
        title={ <QueryHeader queryItem={ queryItem } /> }
      >
        { children }
        <MessageList
          thread={thread}
          clientQueryId={queryItem.supplement?.clientQueryId && queryItem.supplement.clientQueryId}
        />
      </Card>
    );
  }

  return (
    <Collapse
      className='Query'
      accordion={true}
      defaultActiveKey={ isExpanded ? [ queryItem.etag ] : [] }
      expandIconPosition='end'
    >
      <Collapse.Panel
        header={ <QueryHeader queryItem={ queryItem } /> }
        key={queryItem.etag}
      >
        { children }
        <MessageList
          thread={thread}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export const FormDecoratedQuery = props => {

  const {
    queryItem,
    userData,
    renderClassicProcedureView,
    context,
  } = props;
  const role = protocolUserRoleFromUserData( userData, queryItem.context.protocolId );
  const principalQueryRole = findQueryRole(role);
  const allowClose = (role, initiatingRole) => {
    if(role === 'dm' && (initiatingRole === 'api' || initiatingRole === 'site')){ //add site too because of query migration script
      return true;
    }

    if(role !== queryItem.initiatingRole){
      return false;
    }

    return true;
  };

  return (
    <Query
      key={queryItem.etag}
      queryItem={queryItem}
      isExpanded={
           (principalQueryRole === 'initiator'
             && queryItem.status === 'answered')
        || (principalQueryRole !== 'initiator'
             && queryItem.status === 'open')
      }
    >
      {
        principalQueryRole === 'recipient'
        && queryItem.status === 'open'
        && <SiteRoleAnswerForm
             queryItem={queryItem}
             userData={userData}
             context={context}
             renderClassicProcedureView={renderClassicProcedureView}
           />

      }
      {
        principalQueryRole === 'recipient'
        && queryItem.status === 'answered'
        && <SiteRoleReplyForm
             queryItem={queryItem}
             userData={userData}
           />

      }
      {
         principalQueryRole === 'initiator'
         && queryItem.status !== 'closed'
         && <DmRoleCloseOrReplyForm
             queryItem={queryItem}
             userData={userData}
             allowClose={allowClose(role, queryItem.initiatingRole)}
            />

      }
    </Query>
  );

};

