import { useEffect, useCallback, useState } from 'react';
import { useDataRequest } from '../hook/useDataRequest';
import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';
import {
  isVisitLocked as checkIsVisitLocked,
} from '../lib/lockUtils';
import {
  FieldActionsControls,
  ProcedureQueryDisplayControl,
} from './FieldActionsDisplay';

import { refreshProcedure, } from '../legacy/LegacyFacade';
import { DataSourceIndicator } from './procedure/DataSourceIndicator';
import { HoverWrapperForClassicEnvironment } from './HoverWrapperForClassicEnvironment';
import { useGetFieldReviews } from '../hook/http-restapi/useGetFieldReviews';
import { VisitProcedureFieldReviewHistoryTables } from './procedure/VisitProcedureFieldReviewHistoryTables';
import { SourceDocumentDisplay } from './procedure/SourceDocumentDisplay';
import { FieldCountDisplay } from './FieldCountDisplay';
import {
  isProtocolDefinedAsEdcStudy,
  protocolDefinitionFromData,
  procedureDefinitionFromProtocolDefinition,
  isProcedureDefinitionHasFieldAlias,
} from '../lib/protocolDefinitionUtils';
import {
  isProcedureHasState,
} from '../lib/procedureFormUtils';
import {
  updateVisitData,
} from '../lib/visitDataUtils';

const ProcedureFieldCountDisplay = ({
  portalUtils,
  fieldSaveResponse,
  clearFieldSaveResponse,
  context,
  protocolData,
  visitInfo,
  siteInfo,
  ...rest
}) => {

  const [effectiveVisitInfo, setEffectiveVisitInfo] = useState(visitInfo);

  useEffect(() => {
    if(fieldSaveResponse) {
      setEffectiveVisitInfo(updateVisitData({
        visitInfo: effectiveVisitInfo,
        fieldSaveResponse,
      }));
      clearFieldSaveResponse();
    }
  }, [fieldSaveResponse, clearFieldSaveResponse, effectiveVisitInfo]);

  useEffect(() => {
    setEffectiveVisitInfo(visitInfo);
  }, [visitInfo]);

  if(!isProtocolDefinedAsEdcStudy({ protocolData, siteInfo })) {
    return null;
  }

  const wasProcedurePerformed = visitInfo?.data?.visit?.procedures?.find(
    p => p.procedure_id === context.procedureId
         && p?.repeat_label_idx?.toString()
            === context?.procedureRepeatIndex?.toString()
    )?.performance_status?.[0]?.status === '1';
  
  if(!wasProcedurePerformed) {
    return null;
  }

  const protocolVersionName = visitInfo?.data?.visit?.protocol_branch
    ?? siteInfo.protocolVersionName;
  const protocolDefinition = protocolDefinitionFromData(
    protocolData,
    siteInfo.protocol_id,
    protocolVersionName,
  );
  const procedureDefinition = procedureDefinitionFromProtocolDefinition(
    protocolDefinition, context.procedureId
  );
  const isProcedureDefinitionHasConditionalFieldCountRules = (
    isProcedureDefinitionHasFieldAlias(procedureDefinition)
  );
  const isProcedureDefinitionHasStateFormula = (
    isProcedureHasState(procedureDefinition)
  );

  if(isProcedureDefinitionHasStateFormula
     && !isProcedureDefinitionHasConditionalFieldCountRules) {
    return null;
  }

  return portalUtils.attachFieldCountDisplay(context, (
    <FieldCountDisplay{...{
      context,
      protocolData,
      visitInfo: effectiveVisitInfo,
      siteInfo,
      ...rest,
    }} />
  ));
};

const ProcedureQueryDisplayContent = props => {

  const {
    portalUtils,
    userData,

    protocolData,
    siteInfo,
    visitInfo,
    procedureInfo,

    procedureQueryItems,
    isVisitLocked,

    renderClassicProcedureView,
  } = props;

  if(!userData) {
    return null;
  }

  if(!procedureInfo) {
    return null;
  }

  const proceduresData = visitInfo?.data?.visit?.procedures?.filter(
    p => p.procedure_id === procedureInfo.procedureId);
  const procedureData = 'repeatIndex' in procedureInfo
                          && (Boolean(procedureInfo.repeatIndex) || Number.isFinite(procedureInfo.repeatIndex))
                          ? proceduresData.find(e => e.repeat_label_idx.toString() === procedureInfo.repeatIndex.toString() )
                          : proceduresData[0];

  if(!procedureData || procedureData.performance_status.length === 0) {
    return null;
  }

  return portalUtils.attachQueryButton(procedureInfo, (
    <ProcedureQueryDisplayControl {...{
      userData,

      protocolData,
      siteInfo,
      visitInfo,
      procedureInfo,

      procedureQueryItems,
      isVisitLocked,

      renderClassicProcedureView,
    }}/>
    ));
};

const ProcedureFieldActionsComponents = props => {
   const {

    portalUtils,

    userData,
    context,

    protocolData,
    siteInfo,
    subjectData,
    visitInfo,
    procedureInfo,

    renderClassicProcedureView,
    isSplitView,

  } = props;

  const visitIdChar = visitInfo?.id;

  const principalQueryRole = findQueryRole(
          protocolUserRoleFromUserData( userData, context.protocolId ));

  const queryListRequest = useDataRequest({
    endpoint: 'gui/queryList',
    context,
    config: { enabled: Boolean(context?.procedureId) },
    paging: {
      exclusiveStartKey: null,
      maxResults: null,
    },
    sort: {
      field: principalQueryRole === 'initiator'
        ? 'dm-status'
        : 'site-status',
      isAscending: true,
    },
    filter: {},
  });

  const getFieldReviewsRequest = useGetFieldReviews({
    protocolId: context.protocolId,
    siteId: context.siteId,
    subjectId: context.subjectId,
    visitId: context.visitId
  });

  if(!Boolean(queryListRequest?.data)) {
    return null;
  }

  if(!subjectData) {
    return null;
  }

  const isVisitLocked = checkIsVisitLocked( subjectData, context );

  return (
    <>
       <FieldActionsControls
         portalUtils={portalUtils}

         userData={userData}

         protocolData={protocolData}
         siteInfo={siteInfo}
         visitInfo={visitInfo}
         procedureInfo={procedureInfo}

         procedureQueryItems={queryListRequest?.data?.items}
         fieldReviewItems={getFieldReviewsRequest?.data}
         isVisitLocked={isVisitLocked}
         renderClassicProcedureView={renderClassicProcedureView}
         visitIdChar={visitIdChar}
         isSplitView={isSplitView}
       />

       <ProcedureQueryDisplayContent
         portalUtils={portalUtils}

         userData={userData}

         protocolData={protocolData}
         siteInfo={siteInfo}
         visitInfo={visitInfo}
         procedureInfo={procedureInfo}

         procedureQueryItems={queryListRequest?.data?.items}
         isVisitLocked={isVisitLocked}

         renderClassicProcedureView={renderClassicProcedureView}
       />

      <VisitProcedureFieldReviewHistoryTables
        portalUtils={portalUtils}
        siteInfo={siteInfo}
        userData={userData}
        fieldReviewItems={getFieldReviewsRequest?.data}
      />
    </>
  );
};

const ProcedureComponentDataSourceIndicator = (props) => {
  const { portalUtils, context, ...childProps } = props;
  return portalUtils.attachFieldSourceIndicator(
    context,
    <HoverWrapperForClassicEnvironment
      className='data-source-indicator-classic'
      renderHoverableComponent={({dataSource, isSplitView}, isActive) => {
        return (
          <DataSourceIndicator dataSource={dataSource} isSplitView={isSplitView} isActive={isActive} />
        );
      }}
      {...childProps}
    />
  );
};

const ProcedureComponentDataSourceIndicators = (props) => {
  const { portalUtils, context, visitInfo, isSplitView } = props;
  const fieldData = visitInfo.data.fields.filter((field) => field.data);
  return (
    <>
      {fieldData.map((field) =>
        field?.data?.[0]?.demode ? (
          <ProcedureComponentDataSourceIndicator
            key={ field.field_instance_id }
            portalUtils={portalUtils}
            context={{
              procedureId: context.procedureId,
              fieldId: field.field_instance_id,
            }}
            dataSource={field.data[0].demode}
            isSplitView={isSplitView}
          />
        ) : null
      )}
    </>
  );
};

export const ProcedureComponentSourceDocumentDisplay = (props) => {
  const { portalUtils, context, visitInfo, isFieldHistoryVisible, userData} = props;
  const fieldData = visitInfo.data.fields.filter((field) => field.data);
  const userRole = protocolUserRoleFromUserData(userData, context.protocolId);

  return (
    <>
      { isFieldHistoryVisible && (fieldData.map((field) => {
        const sourceDocumentContext = {
          procedureId: context.procedureId,
          fieldId: field.field_instance_id
        }
        return field?.data?.[0]?.source_documents ? (
          portalUtils.attachFieldSourceDocumentLink(
            sourceDocumentContext,
            <SourceDocumentDisplay
              procedureId={context.procedureId}
              fieldId={field.field_instance_id}
              fieldSourceType={field.data[0].demode}
              sourceDocuments={field.data[0].source_documents}
              formType='CRF'
              userRole={userRole}
            />
          )
        ) : null
      }))}
    </>
  )
};

export const ProcedureComponents = props => {

  const { classic, portalUtils } = props;

  const [siteInfo, setSiteInfo] = useState( null );
  const [subjectData, setSubjectData] = useState( null );
  const [visitInfo, setVisitInfo] = useState( null );
  const [procedureInfo, setProcedureInfo] = useState( null );
  const [userData, setUserData] = useState( null );
  const [protocolData, setProtocolData] = useState( null );
  const [splitModeData, setSplitModeData] = useState( null );
  // eslint-disable-next-line no-unused-vars
  const [procedureReviewData, setProcedureReviewData] = useState( null );
  const [isFieldHistoryVisible, setIsFieldHistoryVisible] = useState(false);
  const [fieldSaveResponse, setFieldSaveResponse] = useState(null);

  useEffect(() => {
    if (!classic) {
      return;
    }

    classic.subscribe('ProcedureComponents', 'protocolListData', setUserData);
    classic.subscribe('ProcedureComponents', 'enterSiteContext', setSiteInfo);
    classic.subscribe('ProcedureComponents', 'enterSubjectContext',
                                                    setSubjectData);
    classic.subscribe('ProcedureComponents', 'enterVisitContext', setVisitInfo);
    classic.subscribe('ProcedureComponents', 'enterProcedureContext',
                                                              setProcedureInfo);
    classic.subscribe('ProcedureComponents', 'protocolData', setProtocolData);
    classic.subscribe('ProcedureComponents', 'splitMode', setSplitModeData);
    // next subscription is to force update on review status change
    classic.subscribe('ProcedureComponents', 'procedureReviewData',
                                              setProcedureReviewData);
    classic.subscribe('ProcedureComponents', 'isFieldHistoryVisible',
                                              setIsFieldHistoryVisible);
    classic.subscribe('ProcedureComponents', 'fieldSaveResponse',
                                              setFieldSaveResponse);

  }, [classic]);

  const clearFieldSaveResponse = useCallback(() => {
    setFieldSaveResponse(null);
  }, [setFieldSaveResponse]);

  if(!visitInfo?.data?.visit?.procedures) {
    if(procedureInfo !== null) {
      setProcedureInfo(null);
    }
    return null;
  }

  if(!siteInfo || !visitInfo || !procedureInfo || !userData) {
    return null;
  }

  const context = {
    protocolId: siteInfo?.protocol_id,
    siteId: siteInfo?.siteId,
    subjectId: visitInfo?.data?.visit?.subject_id,
    visitId: visitInfo?.data?.visit?.visit_instance_id,
    procedureId: procedureInfo?.procedureId,
    procedureRepeatIndex: procedureInfo?.repeatIndex,
    isSiteLocked: siteInfo?.isLocked,
  };

  const renderClassicProcedureView = (context) => {
    refreshProcedure(context);
  };

  return (
    <>
      <ProcedureFieldCountDisplay
        portalUtils={portalUtils}
        context={context}
        protocolData={protocolData}
        siteInfo={siteInfo}
        visitInfo={visitInfo}
        fieldSaveResponse={fieldSaveResponse}
        clearFieldSaveResponse={clearFieldSaveResponse}
      />
      <ProcedureFieldActionsComponents
        portalUtils={portalUtils}
        context={context}
        userData={userData}
        protocolData={protocolData}
        siteInfo={siteInfo}
        subjectData={subjectData}
        visitInfo={visitInfo}
        procedureInfo={procedureInfo}
        renderClassicProcedureView={renderClassicProcedureView}
        isSplitView={splitModeData}
      />
      <ProcedureComponentDataSourceIndicators
        portalUtils={portalUtils}
        context={context}
        visitInfo={visitInfo}
        isSplitView={splitModeData}
      />
      <ProcedureComponentSourceDocumentDisplay
        portalUtils={portalUtils}
        context={context}
        visitInfo={visitInfo}
        isFieldHistoryVisible={isFieldHistoryVisible}
        userData={userData}
      />
    </>
  );
};
