import { useState, useEffect } from "react";
import {ApiScopeAssociateContainer} from "./ApiScopeAssociateContainer";

export const ApiScopeAssociateComponents = (props) => {
  const { classic, portalUtils } = props;
  const [ apiAssociateScopeData, setApiAssociateScopeData ] = useState( null );

  useEffect(() => {
    classic.subscribe(
      'ApiScopeAssociateComponents',
      'adminApiScopeAssociateView',
      setApiAssociateScopeData
    );
  }, [classic]);

  const resetAssociateScopeData = () => {
    setApiAssociateScopeData( null );
  };

  return (
    <ApiScopeAssociateContainer {...{
      portalUtils,
      apiAssociateScopeData,
      resetAssociateScopeData,
    }}/>
  );
};
