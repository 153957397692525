export const ViewJsonData = (props) => {
  const { protocolNumber, protocolBranch, studyJson, updates, originalHash } = props;
  return (
    <>
      <h2 className="sub-header" id="admin-title">{protocolNumber} - JSON ({protocolBranch})</h2>
      <form>
        <fieldset>
          <div className="form-group" id="study_json_div">
            <label htmlFor="study_json">Study JSON</label>
            <div className="controls">
              <textarea id="study_json" cols="80" rows="25" disabled value={studyJson} />
            </div>
          </div>
          {updates && (
            <>
              <br/>
              <div className="form-group" id="updates_json_div">
                <label htmlFor="sql_json">Updates made by ODM:</label>
                <div className="controls">
                  <textarea disabled id="updates_json" cols="40" rows="10" value={updates} />
                </div>
                <p className="help-block">Last Approved Hash: {originalHash}</p>
              </div>
            </>
          )}
        </fieldset>
      </form>
    </>
  )
};
