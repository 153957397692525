import { create, all } from 'mathjs';
import {
  dataEntryFromProcedureDefinition,
  fieldDefinitionFromProcedureDefinition,
} from './protocolDefinitionUtils';

import {
  createIsEmpty,
  createIsComplete,
  createInValue,
  createNotInValue
} from './mathjsExtensions';

const statesFromProcedureDefinition = (procedureDefinition, logLevel = 0) =>
  procedureDefinition?.collection?.[logLevel]?.states
    ?? procedureDefinition?.states;

export const isProcedureHasState = (procedureDefinition, logLevel = 0) =>
  statesFromProcedureDefinition( procedureDefinition, logLevel ) ? true: false;


export const getProcedureState = (
    procedureDefinition,
    fieldsData,
    logLevel = 0
  ) => {

  const states = statesFromProcedureDefinition(procedureDefinition, logLevel);

  if( !states || states.length === 0 ) {
    return undefined;
  }

  const dataEntryItems = dataEntryFromProcedureDefinition(procedureDefinition)
                           ?.regular;

  const scope = {};

  for (const item of dataEntryItems) {
    if(!item?.state_var) {
      continue;
    }

    // probably, item is already fieldDefinition, perhaps except grids
    const fieldId = item.id;
    const fieldData = fieldsData.find(f => f.field_id === fieldId);

    scope[item.state_var] = {
      fieldDefinition: fieldDefinitionFromProcedureDefinition(
        procedureDefinition, fieldId
      ),
      fieldData,
    }; // fieldId;
       // used to be composed as `${procedure_repeat_idx}:${ fieldId}`
  }

  const mathjs = create(all);
  mathjs.import({
    createIsEmpty,
    createIsComplete,
    createInValue,
    createNotInValue,
  });

  const result = states.find( state => mathjs.evaluate(state.formula, scope) )
                 ?? { final: false,
                      state: 'Data Incomplete',
                      isDefaultProcedureState: true,
                    };

  return result;

};

