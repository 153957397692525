import {
  Button,
  Badge,
} from 'antd';
import { countProcedureFields } from '../lib/countProcedureFields';
import './FieldCountDisplay.scss';

export const FieldCountDisplay = ({
  context,
  protocolData,
  siteInfo,
  visitInfo,
}) => {

  const { missing: count } = countProcedureFields({
    context,
    protocolData,
    siteInfo,
    visitInfo,
  });

  return (
    <Button
      className={`FieldCountDisplay ${count > 0 ? 'incomplete' : ''}`}
      type='primary'
      disabled={true}
    >
      Missing Fields
      <Badge 
        className='FieldCountDisplayBadge'
        count={count}
        showZero={true}
      />

    </Button>
  );
};

