import { useEffect, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import {
  Modal,
  Button,
  Descriptions,
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import { useWebSocketRequest } from '../hook/useWebSocketRequest';
import { getCsrf, getApplicationEnvironment } from '../legacy/LegacyFacade';
const { rest: restapiUrl } =  getApplicationEnvironment();

async function aggregateFileChunks(reader) {
  const chunks = [];

  let done;
  let value;

  while (!done) {
    ({ value, done } = await reader.read());
    if (done) {
      return chunks;
    }
    chunks.push(value);
  }
}

const DownloadDialog = props => {

  const {
    protocolLabel,
    siteLabel,

    context,
    sortState,
    filterState,

    isDialogVisible,
    closeDialog,
  } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isRequestConfirmed, setIsRequestConfirmed ] = useState(false);
  const { sendRequest } = useWebSocketRequest();

  const sendDataToClient = useCallback(data => {

    const blob = new Blob(data, { type: 'application/vnd.ms-excel' });
    const objectUrl = window.URL.createObjectURL(blob);

    const protocolNumber = protocolLabel.replace(/\s/g, '');
    const fileName = protocolNumber + "-QueryListing-" + (new Date()).toISOString() + ".xlsx";

    const a = document.createElement('a');
    a.href = objectUrl;
    a.style = 'display: none';
    a.download = fileName;
    document.body.appendChild(a);

    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);

  },[protocolLabel]);

  const  downloadQueryReport = useCallback( async ({
                                          messageId,
                                          name,
                                          context,
                                          tzOffset,
                                          sort,
                                          filter,
                                      }) => {

    const input = {
      route: '/api/queryDownload',
      messageId,
      name,
      context,
      tzOffset,
      sort,
      filter
      };

     let readableStream = await fetch(restapiUrl + input.route,{
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'CSRF-Token': getCsrf()
      },
      body: JSON.stringify(input),
      duplex: 'half',
    }).then(response => response.body);
    const file = await aggregateFileChunks(readableStream.getReader());
    setIsLoading(false);
    sendDataToClient(file);
    closeDialog();
    setIsRequestConfirmed(false);

  },[closeDialog, sendDataToClient]);

  useEffect(() => {

    const getData = async () => {

      setIsLoading(true);
      await downloadQueryReport({
          messageId: uuid(),
            name: 'api/queryDownload',
          context,
          tzOffset: ((new Date()).getTimezoneOffset() * 60 * -1 * 1000),
          sort: sortState,
          filter: filterState,
        });
    };

    if( !isRequestConfirmed ) {
      return;
    }

    getData();

  }, [
    sendRequest,
    closeDialog,
    context,
    filterState,
    sortState,
    isRequestConfirmed,
    sendDataToClient,
    downloadQueryReport,
  ]);

  const handleCancel = () => {
    closeDialog();
  };

  const handleDownload = () => {
    setIsRequestConfirmed(true);
  };

  return (
    <Modal
      title='Download data queries spreadsheet?'
      visible={isDialogVisible}
      onOk={handleDownload}
      onCancel={handleCancel}
      confirmLoading={isLoading}
    >
      <Descriptions title='Detail' layout='vertical' bordered>
        { protocolLabel && (
          <Descriptions.Item label='Protocol'>
            {protocolLabel}
          </Descriptions.Item>
        )}
        { siteLabel && (
          <Descriptions.Item label='Site'>
            {siteLabel}
          </Descriptions.Item>
        )}
        { context?.presentation?.subjectLabel && (
          <Descriptions.Item label='Subject'>
            {context?.presentation?.subjectLabel}
          </Descriptions.Item>
        )}
        { filterState?.field && (
          <Descriptions.Item label='Filter'>
            {filterState.field} '{filterState.value ??  ''}'
          </Descriptions.Item>
        )}
          <Descriptions.Item label='Sort order'>
            { sortState?.field
            } { sortState.isAscending ? 'ascending' : 'descending'}
          </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export const QueryDownloadButton = props => {

  const {
    protocolLabel,
    siteLabel,
    context,
    filterState,
    sortState,
    children,
  } = props;

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const closeDialog = useCallback(() => setIsDialogVisible(false), []);

  return (
    <>
      <Button
        className='query-download-button'
        onClick={() => setIsDialogVisible(true)}
        icon={<DownloadOutlined />}
      >
        {children ?? 'Download'}
      </Button>
      <DownloadDialog
         protocolLabel={protocolLabel}
         siteLabel={siteLabel}
         isDialogVisible={isDialogVisible}
         filterState={filterState}
         sortState={sortState}
         context={context}
         closeDialog={closeDialog}
      />
    </>
  );
};
