import { useEffect, useState } from 'react';

import { useDataRequest } from '../hook/useDataRequest';
import { DataItemQueryStatus } from './FieldQueries';

const SubjectLogListQueryCounts = props => {

    const { portalUtils, queryCountItems } = props;

    if(! queryCountItems || queryCountItems.length === 0) {
      return null;
    }

    return(

      queryCountItems.map( item => {
        return portalUtils.attachSubjectLogListQueryCounts( item?.context?.procedureId,
        <DataItemQueryStatus queryCounts={item?.count} />)
      })

    );

};

const SubjectListQueryCounts = props => {
    const { portalUtils, queryCountItems } = props;

    if(! queryCountItems || queryCountItems.length === 0) {
      return null;
    }

    return(

      queryCountItems.map( item => portalUtils.attachVisitListQueryCounts( item?.context?.visitId,
        <DataItemQueryStatus queryCounts={item?.count} />)
      )

    );


};
export const SubjectComponents = props => {

    const { classic, portalUtils } = props;

    const [ subjectData, setSubjectData ] = useState(null);

    useEffect(() => {

      if (!classic) {
        return;
      }

      classic.subscribe('SubjectComponents', 'enterSubjectContext', setSubjectData);

    }, [ classic ]);

    const queryCountRequest = useDataRequest({

      endpoint: 'gui/queryCountList',
      context: {
        protocolId: subjectData?.protocol_id,
        siteId: subjectData?.siteId,
        subjectId: subjectData?.subjectId,
      },
      paging: {
        maxResults: null,
      },
      sort: {
        isAscending: true,
      },
      filter: {},
      config: { enabled: Boolean(subjectData?.subjectId), retryOnMount: true },
    });

    if (!subjectData ){
      return null;
    }

    return (
      <>
        <SubjectListQueryCounts
          portalUtils={portalUtils}
          queryCountItems={queryCountRequest?.data?.items.filter(
            item => item?.context?.visitId !== undefined
          )}
        />
        <SubjectLogListQueryCounts
          portalUtils={portalUtils}
          queryCountItems={queryCountRequest?.data?.items.filter(
            item => item?.context?.visitId === undefined
                    && item?.context?.procedureId !== undefined
          )}
        />
      </>
    );


  };

