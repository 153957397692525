import { ViewJsonData } from "./ViewJsonData";

export const ViewJsonDataContainer = (props) => {
  const {
    portalUtils,
    viewJsonData,
    resetViewJsonData,
  } = props;

  if (!viewJsonData || !viewJsonData.data) {
    return null;
  }

  const {
    protocol_number,
    protocol_branch,
    study_json,
    updates,
    original_hash
  } = viewJsonData.data;

  const viewJsonComponent = (
    <ViewJsonData
      protocolNumber={protocol_number}
      protocolBranch={protocol_branch}
      studyJson={study_json}
      updates={updates}
      originalHash={original_hash}
    />
  );

  return portalUtils.attachViewJsonContent(
    viewJsonComponent,
    viewJsonData.data,
    resetViewJsonData,
  );
};
