export const updateVisitData = ({
  visitInfo,
  fieldSaveResponse,
}) => {
  if(!fieldSaveResponse) {
    return visitInfo;
  }

  const unchangedFieldsData = visitInfo.data.fields.filter(
    field => fieldSaveResponse.field_instance_id !== field.field_instance_id
  );

  const changedFieldData = visitInfo.data.fields.find(
    field => fieldSaveResponse.field_instance_id === field.field_instance_id
  );

  const fieldsData = [
    ...unchangedFieldsData,
    {
      ...changedFieldData,
      data: fieldSaveResponse.data,
    },
  ];

  return {
    ...visitInfo,
    data: {
      ...visitInfo.data,
      fields: fieldsData,
    },
  };
};
