import { BiCommentAdd } from 'react-icons/bi';
import { FieldReviewBadge } from './fieldReviews/FieldReviewBadge';
import './FieldActionsButtons.scss';

const FieldActionsButtons = (props) => {
  const {
    context,
    userData,
    isQueryDisplayVisible,
    openQueriesCount,
    answeredQueriesCount,
    closedQueriesCount,
    fieldDefinitionId,
    isFieldReviewEnabled,
    fieldReview,
    toggleDrawer,
    principalQueryRole,
    protocolUserRole,
    isActive,
    isQueriesAreReadOnly,
    isSplitView,
  } = props;

  const isNewQueryButtonVisible =
    principalQueryRole === 'initiator' &&
    !isQueryDisplayVisible &&
    !openQueriesCount &&
    !answeredQueriesCount &&
    !closedQueriesCount &&
    !isQueriesAreReadOnly;

  const areFieldQueryButtonsVisible =
    !isQueryDisplayVisible &&
    (openQueriesCount > 0 ||
      answeredQueriesCount > 0 ||
      closedQueriesCount > 0);

  const fieldReviewBadge = 
    <FieldReviewBadge
      context={context}
      userData={userData}
      protocolUserRole={protocolUserRole}
      fieldDefinitionId={fieldDefinitionId}
      isFieldReviewEnabled={isFieldReviewEnabled}
      fieldReview={fieldReview}
      isActive={isActive}
    />


  return (
    <div
      className={`field-actions-button-container-anchor ${
        isSplitView && 'field-query-button-split'
      }`}
    >
      <div className='field-query-button-container'>
        {isNewQueryButtonVisible && (
          <button className='field-query-buttons'>
            <div
              className='new-field-query-button'
              id={`new-field-query-button-${fieldDefinitionId}`}
              onClick={() => toggleDrawer()}
              data-testid='new-field-query-button'
            >
              <BiCommentAdd
                className={`new-field-query-icon ${
                  isActive
                    ? 'new-field-query-icon-active'
                    : 'new-field-query-icon-inactive'
                }`}
                data-testid='field-data-source-icon'
              />
              {isActive && (
                <span className='new-field-query-text'>New query</span>
              )}
            </div>
            {fieldReviewBadge}
          </button>
        )}
        {areFieldQueryButtonsVisible && (
          <button className='field-query-buttons'>
            {openQueriesCount > 0 && (
              <div
                className='field-query-button open-field-query-button'
                id={`open-field-query-button-${fieldDefinitionId}`}
                onClick={() => toggleDrawer()}
                data-testid='open-field-query-button'
              >
                {isActive ? `${openQueriesCount} open` : openQueriesCount}
              </div>
            )}

            {answeredQueriesCount > 0 && (
              <div
                className='field-query-button answered-field-query-button'
                id={`answered-field-query-button-${fieldDefinitionId}`}
                onClick={() => toggleDrawer()}
                data-testid='answered-field-query-button'
              >
                {isActive
                  ? `${answeredQueriesCount} answered`
                  : answeredQueriesCount}
              </div>
            )}

            {closedQueriesCount > 0 && (
              <div
                className='field-query-button closed-field-query-button'
                id={`closed-field-query-button-${fieldDefinitionId}`}
                onClick={() => toggleDrawer()}
                data-testid='closed-field-query-button'
              >
                {isActive ? `${closedQueriesCount} closed` : closedQueriesCount}
              </div>
            )}
            {fieldReviewBadge}
          </button>
        )}
      </div>
    </div>
  );
};

export default FieldActionsButtons;
