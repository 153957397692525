import { useMutation, useQueryClient } from 'react-query';
import { useWebSocketRequest } from './useWebSocketRequest';
import { onError as displayError} from '../lib/notificationUtils';

const endpointNames = ['gui/queryAdd', 'gui/queryUpdate'];

export const useDataUpdate = ({ endpoint, mutateOptions, invalidates }) => {

  if (!endpointNames.includes(endpoint)) {
    throw new Error(`unrecognized endpoint name '${endpoint}'`);
  }

  const { sendRequest } = useWebSocketRequest();
  const queryClient = useQueryClient();

  const updateData = async ({ data }) => {

    const sresponse = await sendRequest(
      JSON.stringify({
        name: endpoint,
        item: data,
      })
    );

    const response = JSON.parse(sresponse);

    if( !response.success ) {
      throw response.error;
    }
    return response;

  };

  const onSuccess = async (data, variables, context) => {

    await Promise.all(invalidates.map(cacheName =>
                                   queryClient.resetQueries(cacheName)));

  };

  const onError = async (data, variables, context) => {

    displayError(data || 'unknown', { message: 'Error saving new query' });

    if(mutateOptions?.onError) {
      mutateOptions.onError(data, variables, context);
    }
  };

  return useMutation(updateData, {
    ...mutateOptions,
    onSuccess,
    onError,
  });
};
