import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DOCUMENT, JSON, VERSIONS } from "../components/constants";

const attachPortalToElementById = (elementId, component) => {

  const element = window.document.getElementById(elementId);
  if(!element) {

    console.log({
      what: 'unable to find element',
      elementId,
    });
    return null; // important to return null: client expects react component
  }

  return ReactDOM.createPortal(
    component,
    element
  );

};

export const attachProtocolNavigation = component => {
  const elementId = 'react-protocol-navigation';
  return attachPortalToElementById( elementId, component );
};

export const PortalUtils = {

  attachProcedureSubjectLogs: (context, component) => {

    const elementId = `react-procedure-log-listing-:${context.procedureId}`;

     const element = window.document.getElementById(elementId);

     if(!element) {

       console.log({
         what: 'attachProcedureSubjectLogs unable to find element',
         elementId,
       });

       return null; // important to return null: client expects react component
     }

     return ReactDOM.createPortal(
       component,
       element
     );

  },

  attachFieldReviewHistoryTable: (context, component) => {

    const elementId = `react-field-review-history-procedure-${context.procedureId
    }-${context?.procedureRepeatIndex?.toString() || ''
    }-field-${context.fieldId}`;

    const element = window.document.getElementById(elementId);

     if(!element) {
       return null; // important to return null: client expects react component
     }

     return ReactDOM.createPortal(
       component,
       element
     );

  },

  attachQueryButton: (procedureInfo, component) => {

    const elementId = `react-field-query-control:${
      procedureInfo.procedureId}:${procedureInfo.repeatIndex || '' }`;

    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachQueryButton unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );
  },

  attachFieldControls: (context, component) => {

   const elementId = `react-field-control-procedure-${context.procedureId
                      }-${context?.procedureRepeatIndex || ''
                      }-field-${context.fieldId}`;

    const element = window.document.getElementById(elementId);

    if(!element) {

      console.log({
        what: 'attachFieldControls unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachFieldSourceIndicator: (context, component) => {
    const elementId = `react-field-control-source-indicator-${context.procedureId}-field-${context.fieldId}`;

    const element = window.document.getElementById(elementId);

    if (!element) {

      console.log({
        what: 'attachFieldSourceIndicator unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );
  },

  attachFieldSourceDocumentLink: (context, component) => {
    const elementId = `react-field-control-source-document-link-${context.procedureId}-field-${context.fieldId}`;
    const element = window.document.getElementById(elementId);
    if (!element) {
      console.log({
        what: "attachFieldSourceDocumentLink unable to find element",
        elementId,
      });
      return null; // important to return null: client expects react component
    }
    return ReactDOM.createPortal(
      component,
      element
    );
   },

  attachSubjectLogRowQueryFlag: (context, component) => {
    const elementId = `react-component-data-query-flag-row-${
                                                 context.procedureRowNumber}`;
    const element = window.document.getElementById(elementId);

    if(!element) {

      console.log({
        what: 'attachSubjectLogRowQueryFlag unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachProcedureQueryFlag: (context, component) => {

    // react-component-data-query-flag-{{id}}

    const elementId = Boolean( context.procedureRepeatIndex )
                      || Number.isFinite( context.procedureRepeatIndex )
            ? `react-component-data-query-flag-${context.procedureId
                                         }-${ context.procedureRepeatIndex }`
            : `react-component-data-query-flag-${context.procedureId}`;

    const element = window.document.getElementById(elementId);

    if(!element) {

      console.log({
        what: 'attachProcedureQueryFlag unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachProcedureMissingFieldStatusIndicator: (context, component) => {

    // react-component-missing-fields-status-indicator-{{id}}

    const elementId = Boolean( context.procedureRepeatIndex )
                      || Number.isFinite( context.procedureRepeatIndex )
            ? `react-component-missing-fields-status-indicator-${
                context.procedureId }-${
                context.procedureRepeatIndex }`
            : `react-component-missing-fields-status-indicator-${
                context.procedureId}`;

    const element = window.document.getElementById(elementId);

    if(!element) {

      console.log({
        what: 'attachProcedureMissingFieldStatusIndicator unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachFieldQueryList: component => {

    const elementId = 'react-field-queries';
    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachFieldQueryList unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );
  },

  attachSiteListQueryCounts: (siteId, component) => {

    const elementId = `react-query-counts-site-${siteId}`;
    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachSiteListQueryCounts unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );
  },

  attachSubjectLogProcedureContent: component => {

    const elementId = 'react-subject-log-procedure-content';
    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachSubjectLogProcedureContent unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachSubjectEditButtons: (subjectId, component) => {
    const elementId = `react-subject-edit-button-${subjectId}`;

    const element = window.document.getElementById(elementId);

    if (!element) {

      console.log({
        what: 'attachSubjectEditButtons unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );
   },

  attachSubjectIdHistory: (subjectId, component) => {
    const elementId = `react-subject-id-history-${subjectId}`;

    const element = window.document.getElementById(elementId);

    if (!element) {

      console.log({
        what: 'attachSubjectIdHistory unable to find element',
        elementId,
      });

      return null;
    }

    return ReactDOM.createPortal(
      component,
      element
    );
  },

  attachSubjectListQueryCounts: (subjectId, component) => {

    const elementId = `react-query-counts-subject-${subjectId}`;
    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachSubjectListQueryCounts unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachVisitListQueryCounts: (visitId, component) => {

    const elementId = `react-query-counts-visit-${visitId}`;
    const element = window.document.getElementById(elementId);
    if(!element) {

      // todo: navigate to subject log, via visit list, wait
      console.log({
        what: 'attachVisitListQueryCounts unable to find element',
        elementId,
      });
      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachSubjectLogListQueryCounts: (procedureId, component) => {

    const elementId = `react-query-counts-subjectlogs-${procedureId}`;
    const element = window.document.getElementById(elementId);
    if(!element) {

      // todo: navigate to subject log, then another, wait

      console.log({
        what: 'attachSubjectLogListQueryCounts unable to find element',
        elementId,
      });
      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );

  },

  attachAdminApiScopeEditContent: ( component, breadcrumbContext, onDetach ) => {
    // following api_scope_edit_process
    window.bc_admin(
      breadcrumbContext.protocol_number,
      breadcrumbContext.protocol_id,
      {
        name: 'api_scope',
        func: breadcrumbContext.is_edit_allowed ? 'edit' : 'view',
        apiname: breadcrumbContext.api_name
      },
      breadcrumbContext.is_edit_allowed
    );
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachAdminApiScopeAssociateContent: ( component, breadcrumbContext, onDetach ) => {
    // following api_scope_associate_process
    window.bc_admin(
      breadcrumbContext.protocol_number,
      breadcrumbContext.protocol_id,
      {
        name: 'api_scope',
        func: 'associate'
      },
      breadcrumbContext.is_edit_allowed
    );
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachAdminProtocolContent: ( component, breadcrumbContext, onDetach ) => {
    window.bc_admin(null, null, { name: breadcrumbContext.action });
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachVersionContent: ( component, breadcrumbContext, onDetach ) => {
    window.bc_admin(breadcrumbContext.protocol_number, breadcrumbContext.protocol_id, {
      name: VERSIONS
    }, breadcrumbContext.is_edit_allowed);
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachViewJsonContent: ( component, breadcrumbContext, onDetach ) => {
    window.bc_admin(
      breadcrumbContext.protocol_number,
      breadcrumbContext.protocol_id,
      {
        name: VERSIONS,
        func: JSON,
        version: breadcrumbContext.protocol_branch
      },
      breadcrumbContext.is_edit_allowed
    );
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachDocumentsDataContent: ( component, breadcrumbContext, onDetach ) => {
    window.bc_admin(
      breadcrumbContext.protocol_number,
      breadcrumbContext.protocol_id,
      {
        name: VERSIONS,
        func: DOCUMENT,
        version: breadcrumbContext.branch_name
      },
      breadcrumbContext.is_edit_allowed
    );
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachIcfContent: ( component, breadcrumbContext, onDetach ) => {
    window.bc_admin(
      breadcrumbContext.protocol_number,
      breadcrumbContext.protocol_id,
      { name: "icf" },
      breadcrumbContext.is_edit_allowed
    );
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachSiteAdminContent: ( component, breadcrumbContext, onDetach ) => {
    window.bc_admin(
      breadcrumbContext.protocol_number,
      breadcrumbContext.protocol_id,
      {
        name: "sites",
        func: breadcrumbContext.is_edit_allowed ? "edit": "view",
        site_number: breadcrumbContext.given_site_id
      },
      breadcrumbContext.is_edit_allowed
    );
    return PortalUtils.attachMainContent( component, onDetach );
  },

  attachMainContent: ( component, onDetach ) => {

    // we assume that the react component is replacing content that used to be
    // attached under protocol_list. If protocol_list gets new content, call
    // onDetach() to let the component know to stop displaying
    const protocolListElement = document.getElementById('protocol_list');
    protocolListElement.replaceChildren(); // remove children

    const mutationObserver = new MutationObserver(
      (mutationsList, observer) => {

        const isAddedNodes = mutationsList.some(
          r => (r?.addedNodes?.length ?? 0) > 0,
        );

        if( !isAddedNodes ) {
          return;
        }

        observer.disconnect();
        if(!onDetach) {
          return;
        }

        onDetach();

    }); // mutationObserver

    mutationObserver.observe( protocolListElement, { childList: true });

    const elementId = 'react-main-content';
    //const elementId = 'protocol_list';
    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachMainContent unable to find element',
        elementId,
      });

      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );


  },
  attachClinicalListingSelector: (component) => {
    const elementId = `react-clinical-listing-button`;
    const element = window.document.getElementById(elementId);
    if(!element) {

      console.log({
        what: 'attachClinicalListingSelector unable to find element',
        elementId,
      });
      return null; // important to return null: client expects react component
    }

    return ReactDOM.createPortal(
      component,
      element
    );
  },

  attachFieldCountDisplay: (context, component) => {
    const {
      procedureId,
      procedureRepeatIndex,
    } = context;
    const elementId = `react-field-count-display-${
      procedureId}:${
      procedureRepeatIndex ?? ''}`;
    return attachPortalToElementById(elementId, component);  
  },
        
};

export const ProtocolListDetachListener = props => {

  const ancestorElementSelector = '#protocol_list';
  const {
    onDetach,
    children,
  } = props;

  const ref = useRef();

  useEffect(() => {

    if(!ref.current) {
      return;
    }

    const hostElement = ref.current.closest(ancestorElementSelector);

    const mutationObserver = new MutationObserver(
      (mutationsList, observer) => {

        const hostElement = ref.current.closest(ancestorElementSelector);

        if(hostElement) {
          return;
        }

        observer.disconnect();
        if(!onDetach) {
          return;
        }
        onDetach();

      }); // mutationObserver

    mutationObserver.observe( hostElement, { childList: true });

    return () => {
      mutationObserver.disconnect();
    };

  }, [ ref, onDetach ]);

  return (
    <div ref={ref}>
      { children }
    </div>
    );
};


