// Note, as a matter of discipline, this module has exactly one consumer:
// `isUserHasRight()`, defined in `../lib/roleUtils`
//
// The rights here, and the defined role names, are taken from the classic
// `rights.js` file. I have inverted the sense of the rights map, so instead
// of roles as keys and rights as values, we have rights as keys and roles as
// values.
// 
// Rights are commented out unless they have a consumer in the react
// system. Roles are commented out if they are not active in the rights map.
// Author initials after a line in the rights map indicate a right that is
// newly introduced for the react system.

const AUD = 'auditor';
const CEN = 'central';
const DM = 'dm';
const HN = 'home-nurse';
//const HNM = 'home-nurse-manager';
const MON ='monitor';
const PI = 'pi';
const RO = 'read-only';
const SAFE = 'safety';
const SITE = 'site';

export const rights = {

  'certified-copy-add': [ CEN, HN, PI, SITE ],
  'certified-copy-invalidated-view': [ AUD, CEN, HN, PI, SITE ], // ET
  'certified-copy-view': [ AUD, CEN, HN, MON, PI, SITE ],
  //'change-tz-view': [ AUD, DM, MON, RO, SAFE ],
  //'cs-assoc': [ PI, SITE ],
  //'cs-certify': [ PI, SITE ],
  //'cs-view': [ AUD, DM, MON, PI, RO, SAFE, SITE ],
  data_export: [ DM ],
  //'home-visit-assignment': [ HNM ],
  //'home-visit-assignment-update': [ HNM ],
  //'home-visit-show': [ AUD, CEN, DM, HN, MON, RO, SAFE ],
  //'home-visit-start': [ HNM ],
  //icf: [ AUD, DM, MON, PI, RO, SITE ],
  listing_artifact: [ AUD, DM, MON, RO ],
  //listing_procedure: [ AUD, DM, MON, RO, SAFE ],
  listing_query: [ AUD, DM, MON, PI, RO, SAFE, SITE ],
  //'log-site-modify': [ PI, SITE ],
  //'log-site-view': [ AUD, MON, PI, SITE ],
  //'log-subject-modify': [ CEN, HN, PI, SITE ],
  //'log-subject-tab-view': [ AUD, DM, HN, MON, PI, RO, SAFE, SITE ],
  //'log-subject-view': [ AUD, CEN, DM, HN, MON, PI, RO, SAFE, SITE ],
  //log_lock: [ DM ],
  //'not-procedure-access-individual': [ PI, SITE ],
  //'performance-done': [ CEN, HN, PI, SITE ],
  'performance-guidance-cra-modify': [ MON ],
  'performance-guidance-cra-view': [ AUD, DM, MON, RO, SAFE ],
  'performance-guidance-regular-modify': [ CEN, HN, PI, SITE ],
  'performance-guidance-regular-view': [ AUD, CEN, DM, HN, MON,
                                        PI, RO, SAFE, SITE ],
  'performance-guidance-safety-modify': [ SAFE ],
  'performance-guidance-safety-view': [ SAFE ],
  'performance-guidance-source-modify': [ CEN, HN, PI, SITE ],
  'performance-guidance-source-view': [ AUD, CEN, DM, HN, MON,
                                       PI, RO, SAFE, SITE ],
  //'performance-signature': [ CEN, HN, PI, SITE ],
  //'pisig-sign': [ PI ],
  //'pisig-tab': [ AUD, DM, MON, PI, RO, SITE ],
  //'procedure-access-individual': [ CEN ],
  'procedure-dataentry-regular-modify': [ CEN, HN, PI, SITE ],
  'procedure-dataentry-regular-view': [ AUD, CEN, DM, HN, MON,
                                        PI, RO, SAFE, SITE ],
  //'procedure-dataentry-safety-modify': [ SAFE ],
  //'procedure-dataentry-safety-view': [ DM, SAFE ],
  //'procedure-submit': [ CEN, HN, PI, SITE ],
  //'protocol-real-time-reports': [ AUD, DM, MON, PI, RO, SAFE, SITE ],
  //query_answer: [ CEN, HN, PI, SITE ],
  //query_create: [ DM, SAFE ],
  //'read-signature-sign': [ CEN, PI, SITE ],
  review_close: [ MON ],
  review_query: [ MON ],
  review_reopen: [ CEN, HN, PI, SITE ],
  review_submit: [ CEN, HN, PI, SITE ],
  //'subject-add': [ HNM, PI, SITE ],
  //'subject-edit': [ HNM, PI, SITE ],
  //subject_lock: [ DM ],
  //'visit-anchor-date': [ PI, SITE ],
  //'visit-date-update': [ HNM, PI, SITE ],
  //'visit-start': [ PI, SITE ],
  //visit_lock: [ DM ]
};


/*
// keep this here for comparison with classic/js/src/rights.js
// example, isEditRole() in SubjectLogProcedureItemView.js
//
// based on restapi/functions/permission.js line 3
export const isWriteRole = roles => {
  const writeRoles = ['pi',
                      'site',
                      'central',
                      'home-nurse-manager',
                      'home-nurse'];
                      
  return roles.some(
    role => writeRoles.includes(role));                    

};
*/

/*

const subject_lock_functions = [
  'subject-edit',
  'visit-start',
  'visit-anchor-date',
  'visit-date-update',
  'log-subject-modify',
  'performance-done',
  'performance-signature',
  'performance-guidance-regular-modify',
  'performance-guidance-source-modify',
  'performance-guidance-cra-modify',
  'certified-copy-add',
  'procedure-dataentry-regular-modify',
  'procedure-submit',
  'cs-assoc',
  'cs-certify',
  'review_submit',
  'review_reopen',
  'review_close',
  'review_query'
];

const visit_lock_functions = [
  'visit-date-update',
  'performance-done',
  'performance-signature',
  'performance-guidance-regular-modify',
  'performance-guidance-source-modify',
  'performance-guidance-cra-modify',
  'certified-copy-add',
  'procedure-dataentry-regular-modify',
  'procedure-submit',
  'review_submit',
  'review_reopen',
  'review_close',
  'review_query'
];

const log_lock_functions = [
  'performance-guidance-regular-modify',
  'performance-guidance-source-modify',
  'performance-guidance-cra-modify',
  'log-subject-modify',
  'certified-copy-add',
  'review_submit',
  'review_reopen',
  'review_close',
  'review_query'
];

function access_given(locks, user_type, access_type) {

  if ( locks?.subject_lock && access_type in subject_lock_functions) {
    return false;
  }

  if ( locks?.visit_lock && access_type in visit_lock_functions) {
    return false;
  }

  if ( locks?.log_lock && access_type in log_lock_functions) {
    return false;
  }

  return rights?.[user_type]?.[access_type] === true;
}

*/
