import ReactMarkdown from 'react-markdown';

export const Notice = props => {

  const { notice } = props;

  if(!notice?.entry) {
    return null;
  }

  return (
      <div className='Notice'>
        <ReactMarkdown>
          {notice.entry}
        </ReactMarkdown>
      </div>
  );

};

