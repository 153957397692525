import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import useEditCheckWebSocket from '../hook/useEditCheckWebSocket';
import EditCheckNotification from './EditCheckNotification';

import './EditCheckNotifications.scss';

const EditCheckNotifications = () => {
	const queryClient = useQueryClient();
	const [editCheckNotifications, setEditCheckNotifications] = useState([]);
	const [timeouts, setTimeouts] = useState({});

	const addEditCheckNotification = ({ visit, procedure, field, is_field_level_query, id, subject, description}) => {
		const newQuery = { id, visit, procedure, field, is_field_level_query, subject, description };

		if (timeouts[id]) {
			clearTimeout(timeouts[id]);
		}

		if (!timeouts[id]) {
			setEditCheckNotifications(prevQueries => [...prevQueries, newQuery]);
		}

		const timeout = setTimeout(() => removeEditCheckNotification(id), 300000);
		setTimeouts(prevTimeouts => ({ ...prevTimeouts, [id]: timeout }));
	};

	const removeEditCheckNotification  = (idToRemove) => {
		setEditCheckNotifications(prevNotifications => prevNotifications.filter(query => query.id !== idToRemove));
		clearTimeout(timeouts[idToRemove]); // Cancel the decay timeout for the removed query
		setTimeouts(prevTimeouts => {
			const updatedTimeouts = { ...prevTimeouts };
			delete updatedTimeouts[idToRemove];
			return updatedTimeouts;
		});
	};

	useEditCheckWebSocket({
		queryClient,
		addEditCheckNotification,
		removeEditCheckNotification,
	});

	return editCheckNotifications.length > 0 && (
		<div className="edit-check-notifications">
			<h3>New automated queries</h3>
			{editCheckNotifications.map((notification) => (
				<EditCheckNotification
					notification={notification}
					removeEditCheckNotification={removeEditCheckNotification}
				/>
			))}
		</div>
	);
};

export default EditCheckNotifications;
