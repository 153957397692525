import { useEffect, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { SubjectLogProcedureItemView } from './SubjectLogProcedureItemView';
import { requestProcedureLoad } from '../legacy/LegacyFacade';

const SubjectLogProcedureRowContent = props => {

  const { portalUtils, ...rest } = props;

  return portalUtils.attachSubjectLogProcedureContent(
    <SubjectLogProcedureItemView { ...rest } />
  ); 
};

export const SubjectLogRowComponents = props => {

  const { classic, portalUtils } = props;

  const [ userData, setUserData ] = useState( null );

  const [ protocolData, setProtocolData ] = useState( null );
  const [ siteInfo, setSiteInfo ] = useState( null );
  const [ subjectData, setSubjectData ] = useState( null );
  const [ subjectLogProcedureData,
          setSubjectLogProcedureData ] = useState( null );
  const [ visitInfo, setVisitInfo ] = useState( null );        

  const [ newSubjectLogProcedureData,
          setNewSubjectLogProcedureData ] = useState( null );
  const [ subjectLogProcedureSelection,
          setSubjectLogProcedureSelection ] = useState( null );

  const [ isRefreshProcedureData,
          setIsRefreshProcedureData ] = useState( false );
  const [ subjectLogRowCrossoutPutStatus,
          setSubjectLogRowCrossoutPutStatus ] = useState({ isLoading: false });
  const [ isSubjectLogRowCrossoutPutLoading,
          setIsSubjectLogRowCrossoutPutLoading ] = useState( false );

  const resetSubjectLogProcedureSelection = useCallback(
    () => setSubjectLogProcedureSelection(() => null), []);

  const handleSubjectLogProcedureChange = useCallback(newData => {

    if( isRefreshProcedureData && Object.keys(newData).length === 0) {
      setIsRefreshProcedureData(() => false);
      return;
    }

    setSubjectLogProcedureData(oldData => {
      if(oldData?.procedure_id !== newData?.procedure_id) {
        setSubjectLogProcedureSelection(() => null);
      }
      return newData;
    });
    setIsRefreshProcedureData(() => false);

  }, [ isRefreshProcedureData ]);

  const handleLeaveSubjectContext = useCallback(() => {

    setSubjectLogProcedureData(() => null);
    setSubjectLogProcedureSelection(() => null);

  }, []);

  const refreshSubjectLogProcedureData = useCallback(() => {

    // a field has been edited, reload procedure data to refresh summary table
    setIsRefreshProcedureData(() => true);

    requestProcedureLoad({
      protocolId: siteInfo?.protocol_id,
      siteId: siteInfo?.siteId,
      subjectId: subjectLogProcedureData?.subject_id,
      procedureId: subjectLogProcedureData?.procedure_id,
    });

  }, [
    siteInfo?.protocol_id,
    siteInfo?.siteId,
    subjectLogProcedureData?.procedure_id,
    subjectLogProcedureData?.subject_id,
  ]);

  useEffect(() => {

    if (!classic) {
      return;
    }
    classic.subscribe('SubjectLogRowComponents', 'protocolListData',
                                                 setUserData);
    classic.subscribe('SubjectLogRowComponents', 'protocolData',
                                                 setProtocolData);
    classic.subscribe('SubjectLogRowComponents', 'enterSiteContext',
                                                 setSiteInfo);
    classic.subscribe('SubjectLogRowComponents', 'enterSubjectContext',
                                                              setSubjectData);

    classic.subscribe('SubjectLogRowComponents', 'subjectLogProcedureData',
                                               handleSubjectLogProcedureChange);
    classic.subscribe('SubjectLogRowComponents', 'subjectLogProcedureSelection',
                                             setSubjectLogProcedureSelection);

    classic.subscribe('SubjectLogRowComponents', 'newSubjectLogProcedureData',
                                               setNewSubjectLogProcedureData);
    classic.subscribe('SubjectLogRowComponents', 'leaveSubjectContext',
                                             handleLeaveSubjectContext);

    classic.subscribe('SubjectLogRowComponents',
                      'subjectLogProcedureEvidenceUpdate',
                      refreshSubjectLogProcedureData);

    classic.subscribe('SubjectLogRowComponents',
                      'subjectLogRowCrossoutPutStatus',
                      setSubjectLogRowCrossoutPutStatus);
    classic.subscribe('SubjectLogRowComponents', 'enterVisitContext', setVisitInfo);

  }, [
    classic,
    handleSubjectLogProcedureChange,
    handleLeaveSubjectContext,
    refreshSubjectLogProcedureData,
  ]);

  useEffect(() => {

    // new entry, request new procedure data that has the new entry

    if( !newSubjectLogProcedureData ) {
      return () => {};
    }

    if( !subjectLogProcedureData?.procedure_id ) {
      return  () => {};
    }

    requestProcedureLoad({
      protocolId: siteInfo?.protocol_id,
      siteId: siteInfo?.siteId,
      subjectId: subjectLogProcedureData?.subject_id,
      procedureId: subjectLogProcedureData?.procedure_id,
    });

  }, [
    newSubjectLogProcedureData,
    siteInfo?.protocol_id,
    siteInfo?.siteId,
    subjectLogProcedureData?.subject_id,
    subjectLogProcedureData?.procedure_id,  
  ]);

  useEffect(() => {

    // new entry, new procedure data is here, select the new entry

    if( !newSubjectLogProcedureData || !subjectLogProcedureData ) {
      return () => {};
    }

    if( subjectLogProcedureData.evidence.length 
        <= newSubjectLogProcedureData.entity_row_count) {
      return () => {}; // hasn't changed
    }
       
    setSubjectLogProcedureSelection(() => ({
      index: newSubjectLogProcedureData.procedure_row,
      level: 0,
      crossout: false,
      lock: null,
    }));

    setNewSubjectLogProcedureData(() => null);

  }, [ 
    subjectLogProcedureData,
    newSubjectLogProcedureData,
  ]);

  const queryClient = useQueryClient();

  useEffect(() => {

    const invalidateDataCaches = async () => {
      return  Promise.all([
        queryClient.resetQueries('gui/queryCountList'),
        queryClient.resetQueries('gui/queryList'),
      ]);
    };

    if(!subjectLogRowCrossoutPutStatus?.isLoading
      && !isSubjectLogRowCrossoutPutLoading
      ){
      return () => {};
    }

    if(isSubjectLogRowCrossoutPutLoading
        && !subjectLogRowCrossoutPutStatus?.isLoading ){ 

      // we have just finished submitting a change in crossout state, so we
      // should make the counts feeds stale
      invalidateDataCaches();

      console.log({
        what: 'SubjectLogRowComponents',
        message: 'make counts stale [NYI]',
      });

    }

    setIsSubjectLogRowCrossoutPutLoading(
      () => subjectLogRowCrossoutPutStatus?.isLoading ?? false 
    );

  }, [
    isSubjectLogRowCrossoutPutLoading,
    subjectLogRowCrossoutPutStatus,
    queryClient,
  ]);

  useEffect( () => {
    if ( visitInfo?.data && subjectLogProcedureSelection ) {
      resetSubjectLogProcedureSelection();
    }
  }, [ resetSubjectLogProcedureSelection,
       subjectLogProcedureSelection,
       visitInfo?.data,
  ]);

  // these components are exclusively for subject log context
  // visitInfo becomes {} when the user navigates away from the visit view
  if( visitInfo?.data ) {
    return null
  }
  
  if( !subjectLogProcedureData?.procedure_id ) {
    return null;
  }

  if( !subjectLogProcedureSelection ) {
    return null;
  }

  if( !!newSubjectLogProcedureData ) {
    return null;
  }

  // fires in subject log row context

  return (
    <SubjectLogProcedureRowContent
      portalUtils={portalUtils}
      userData={userData}

      protocolData={protocolData}
      siteInfo={siteInfo}
      subjectData={subjectData}
      subjectLogProcedureData={subjectLogProcedureData}
      subjectLogProcedureSelection={subjectLogProcedureSelection}

      refreshSubjectLogProcedureData={refreshSubjectLogProcedureData}
      resetSubjectLogProcedureSelection={ resetSubjectLogProcedureSelection }
    />
  );


};
