import {
  useState,
  useEffect,
} from 'react';  
import {
  isOkRoleForDataEntryModify,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils'; 
import {
  protocolDefinitionFromData,
  procedureDefinitionFromProtocolDefinition,
  fieldDefinitionFromProcedureDefinition
} from '../lib/protocolDefinitionUtils';
import { 
  Table,
  Button, 
} from 'antd';

import { getReadOnlyContent } from '../components/procedure/Field';

import {
  addNewLog,
  editLog,
} from '../legacy/LegacyFacade';

import './ProcedureSubjectLogView.scss';

const ProcedureSubjectLogTable = props => {
  const { 
      userData,
      procedureDefinition,
      subjectLogProcedureData,
      visitInfo,
      siteInfo
  } = props;

  if( !procedureDefinition || procedureDefinition.id !== subjectLogProcedureData.procedure_id) {
      return null;
    }

  const columns = []
  const dataSource = []
  const column_lookup = {}
  
  // create the columns from procedureDefinition and add edit and query columns 
  for (const de_object of procedureDefinition.collection[0].dataentry.regular) {

    if (de_object.log_display) {
      columns.push({ title: de_object.text, dataIndex: de_object.id })

      const fieldDefinition = fieldDefinitionFromProcedureDefinition(procedureDefinition, de_object.id)
      column_lookup[de_object.id] = fieldDefinition
    }
  }
  columns.push({ title: '',dataIndex: 'edit' })
  columns.push({ title: '', dataIndex: 'query' })

  // add row in table for each existing log 
  for (const evidence of subjectLogProcedureData.evidence) { 
      dataSource[evidence.row] = { key: evidence.row }

      // determine and insert crossout status per row 
      const crossoutStatus = evidence.crossout
            && evidence.crossout.length > 0
            ? evidence.crossout[0].status
            : false;
      dataSource[evidence.row]['crossout'] = crossoutStatus

      // create edit and insert button per row
      const editContext = {
          index: evidence.row,
          level: 0,
          crossout: crossoutStatus,
          lock: false
      }
      const editButton = 
        <Button 
            className='edit-log-button'
            onClick={() => editLog(editContext)}>
                Open
        </Button>

      dataSource[evidence.row]['edit'] = editButton;

      // create and insert query count element per row 
      const spanId = `react-component-data-query-flag-row-${evidence.row}`
      const queryCountElement = <span id={spanId}></span>
      dataSource[evidence.row]['query'] = queryCountElement;
  }

  const getFormattedValue = ( fieldDefinition, fieldData ) => {
    if(!fieldData?.value) {
      return (
        <div>
          Not entered
        </div>
      );
    }
    return getReadOnlyContent(
      fieldDefinition.type,
      fieldData.value,
      fieldDefinition,
      fieldData
    );
  };

  // insert log fields into each correct row/column 
  for (const field of subjectLogProcedureData.fields) {
      const row = field.procedure_row

      if (column_lookup[field.field_id]) {
        dataSource[row][field.field_id] = getFormattedValue(
          column_lookup[field.field_id],
          field?.data?.[0]
        );
      }
  }

  const addNewLogArgs  = {
      subjectId: subjectLogProcedureData.subject_id, 
      procedureId: procedureDefinition.id, 
      type: 'subject', 
      visitInfo: visitInfo, 
      level: 0, 
      parentRow: 0
  };

  const locale = {
    emptyText: 
    <div >
      No Entries
    </div>
    
  };

  const userRole = protocolUserRoleFromUserData(
    userData, siteInfo.protocol_id);

  const isViewWritable = isOkRoleForDataEntryModify(userRole);

  const newEntryButton = isViewWritable ? 
    <Button 
      className='guidance-icon-button'
      onClick={() => addNewLog( addNewLogArgs )}
    >
      New Entry
    </Button> : 
    null;

  return (
  <div className='ProcedureSubjectLogTable'>
    <div className='ProcedureSubjectLogTableTitle'>
      <p>
        Here, you can manage all log entries and queries for
        the <span className='procedure-name' >{
          procedureDefinition.name
        } </span> subject logs.</p>
      
      {newEntryButton}

      <Table 
        className='Table'
        dataSource={dataSource} 
        columns={columns}
        locale={locale}
        rowClassName={record => (record['crossout'] === true ? 'crossout' : 'normal')} />

    </div>
  </div>  
  );
};


export const ProcedureSubjectLogView = props => {

  const {
      userData,
      siteInfo,
      visitInfo,
      protocolData,
      subjectLogProcedureData,
  } = props;

  const [ currentProcedureData, setCurrentProcedureData ] = useState( null );
  const [ procedureDefinition, setProcedureDefinition ] = useState(null);

  useEffect(() => {

    setCurrentProcedureData( subjectLogProcedureData );

  }, [ subjectLogProcedureData ]);

  useEffect(() => {

    if(!protocolData
       || !siteInfo
       || !currentProcedureData) {
      return;
    }

    const protocolVersionName =  siteInfo.protocolVersionName;
 
    const protocolDefinition = protocolDefinitionFromData(
      protocolData,
      siteInfo.protocol_id,
      protocolVersionName,
    );

    const newProcedureDefinition = procedureDefinitionFromProtocolDefinition(
      protocolDefinition, currentProcedureData.procedure_id
    );

    setProcedureDefinition( () => newProcedureDefinition );

  }, [
     protocolData,
     siteInfo,
     currentProcedureData,
  ]);

  if (!procedureDefinition) { 
      return; 
  }


  return (
    <div className='ProcedureSubjectLogView' >
      <div className='subject-log'>
        <h4>Subject Logs</h4>

        <ProcedureSubjectLogTable {...{
          userData,
          procedureDefinition,
          subjectLogProcedureData,
          visitInfo,
          siteInfo
          }}/>
      </div>

    </div>
  );
};


