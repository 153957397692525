export const SiteAdmin = (props) => {
  const {
    protocolId,
    protocolNumber,
    siteName,
    siteId,
    givenSiteId,
    PIName,
    ctmsId,
    countryCode,
    versionHistory,
    version,
    siteIcfVersions,
    icfVersion,
    countryList,
    isEditAllowed
  } = props;
  const currentCountry = countryList.find(country => country.code === countryCode);
  return (
    <>
      <h2 className="sub-header" id="admin-title">{protocolNumber} - Site Edit {siteName}</h2>
      <form>
        <fieldset>
          <div className="form-group" id="site_name_div">
            <label htmlFor="site_name">Site Name</label>
            <div className="controls">
              <input type="text" id="site_name" value={siteName} autoComplete="off" disabled={!isEditAllowed} />
              {isEditAllowed && <span className="help-block">Please enter the site name. This is required.</span>}
            </div>
          </div>
          <div className="form-group" id="pi_name_div">
            <label htmlFor="pi_name">Principal Investigator (PI) Name</label>
            <div className="controls">
              <input type="text" id="pi_name" value={PIName} autoComplete="off" disabled={!isEditAllowed} />
              {isEditAllowed && <span className="help-block">Please enter the PI name. This is required.</span>}
            </div>
          </div>
          <div className="form-group" id="site_id_div">
            <label htmlFor="site_id">Site Identifier or Number</label>
            <div className="controls">
              <input type="text" id="site_id" value={givenSiteId} autoComplete="off" disabled={!isEditAllowed} />
              {isEditAllowed && <span className="help-block">Please enter the site identifier or number. This is required. (Needs to be unique across protocol) </span>}
            </div>
          </div>
          <div className="form-group" id="ctms_id_div">
            <label htmlFor="ctms_id">Global Site Identifier or Number</label>
            <div className="controls">
              <input type="text" id="ctms_id" value={ctmsId} autoComplete="off" disabled={!isEditAllowed} />
              {isEditAllowed && <span className="help-block">Please enter the Global Site identifier or number. This is optional.</span>}
            </div>
          </div>
          <div className="form-group" id="protocol_branch_history_div">
            <label>Protocol Version History</label>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                <tr>
                  <th>Version</th>
                  <th>Effective Date</th>
                  <th>Assigned Date</th>
                </tr>
                </thead>
                <tbody>
                  {versionHistory.map((version, idx) => (
                    <tr id="version-row" key={idx}>
                      <td id={`version-branch-${idx}`}>{version.branch}</td>
                      <td id={`version-active-${idx}`} data-date={version.active_date_raw}>{version.active_date}</td>
                      <td id={`version-assign-${idx}`} data-date={version.assigned_date_raw}>{version.assigned_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isEditAllowed && (
              <div className="form-group" id="protocol_branch_div">
                <label htmlFor="protocol_branch">Add Protocol Version</label>
                <div className="controls">
                  <select id="protocol_branch">
                    <option value="">Select Version</option>
                    {version.map((ver, idx) => <option value={ver} key={idx}>{ver}</option>)}
                  </select>
                  <input type="text" id="active_date" />
                  <span className="help-block">
                    Please select the version of the protocol this site should be on. This will be effective on the date/time specified in local timezone.
                  </span>
                </div>
              </div>
            )}
            <div className="form-group" id="protocol_branch_history_div">
              <label>ICF Version History</label>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th>Version</th>
                    <th>Effective Date</th>
                    <th>Assigned Date</th>
                  </tr>
                  </thead>
                  <tbody>
                    {siteIcfVersions.map((siteIcfVersion, idx) => (
                      <tr id="icf-version-row" key={idx}>
                        <td id={`icf-version-version-${idx}`}>{siteIcfVersion.version}</td>
                        <td id={`icf-version-date-${idx}`} data-date={siteIcfVersion.effective_date_raw}>{siteIcfVersion.effective_date}</td>
                        <td id={`icf-assign-date-${idx}`} data-date={siteIcfVersion.assigned_date_raw}>{siteIcfVersion.assigned_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {isEditAllowed && (
              <div className="form-group" id="icf_version_div">
                <label htmlFor="icf_version">Add ICF Version</label>
                <div className="controls">
                  <select id="icf_version">
                    <option value="">Select Version</option>
                    {icfVersion.map((ver, idx) => <option value={ver.version} key={idx}>{ver.version}</option>)}
                  </select>
                  <input type="text" id="icf_version_effective_date" />
                  <span className="help-block">
                    Please select the version of the ICF this site should be on. This will be effective on the date/time specified in local timezone.
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="form-group" id="site_country_div">
            <label htmlFor="site_country">{`${isEditAllowed ? "Select" : ""} Site Country`}</label>
            <div className="controls">
              <select id="site_country_select" className="site-country-select" disabled={!isEditAllowed} style={{appearance: isEditAllowed ? "" : "none"}}>
                {countryList.map((countryElem, idx) => (
                  <option
                    value={countryElem.code}
                    disabled={!isEditAllowed}
                    key={idx}
                    selected={currentCountry.code === countryElem.code}
                  >
                    {countryElem.country}
                  </option>
                ))}
              </select>
              {isEditAllowed && <span className="help-block">Please select a country where site is located. This is required.</span>}
            </div>
          </div>
          {isEditAllowed && (
            <div className="form-actions">
              <button
                id="btn-save"
                type="button"
                onClick={() => {
                  window.site_edit_send(siteId, protocolId, protocolNumber, isEditAllowed);
                  return false;
                }}
                className="btn btn-primary"
              >
                Update
              </button>
              <button
                id="btn-cancel"
                type="button"
                onClick={() => {
                  window.site_list_get(protocolId, protocolNumber, true, false, isEditAllowed);
                  return false;
                }}
                className="btn btn-default"
              >
                Cancel
              </button>
            </div>
          )}
        </fieldset>
      </form>
    </>
  );
};
