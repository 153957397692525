import FieldReviewHistoryTable from "../fieldReviews/FieldReviewHistoryTable";

export const VisitProcedureFieldReviewHistoryTables = props => {

  const {
    portalUtils,
    siteInfo,
    fieldReviewItems,
    userData,
  } = props;

  return (
    <>
      { fieldReviewItems?.map((fieldReview) => (
          <VisitProcedureFieldReviewHistoryTable
            key={fieldReview.context.fieldId}
            portalUtils={portalUtils}
            siteInfo={siteInfo}
            userData={userData}
            actions={fieldReview.payload.actions}
            context={{
              procedureId: fieldReview.context.procedureId,
              fieldId: fieldReview.context.fieldId,
              procedureRepeatIndex: fieldReview.context.procedureRepeatIndex,
            }}
          />
        ))
      }
    </>
  );
};

const VisitProcedureFieldReviewHistoryTable = props => {

  const {
    portalUtils,
    siteInfo,
    context,
    actions,
    userData,
  } = props;

  return portalUtils.attachFieldReviewHistoryTable(
    {
      procedureId: context.procedureId,
      procedureRepeatIndex: context.procedureRepeatIndex,
      fieldId: context.fieldId,
    },
    <FieldReviewHistoryTable
      siteInfo={siteInfo}
      isHistoryVisible={true}
      actions={actions}
      userData={userData}
    />
  );
};
