import { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useInfiniteDataRequest } from '../hook/useInfiniteDataRequest';

const { Option } = Select;

export const SubjectContextFilterControl = props => {

  const { 
    context,

    setContext,
    clearContext,
  } = props;

  const [ previousContext, setPreviousContext ] = useState( null );

  useEffect(() => {
    if(!context) {
      return;
    }
    if(context?.subjectId) {
      return;
    }
    setPreviousContext( context );
    
  }, [ context ]);

  const {

    data,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,

  } = useInfiniteDataRequest({

    endpoint: 'gui/subjectList',
    context,
    pageSize: 5,
    sort: {},
    config: {},

  });

  const onSelect = subjectId => {
    const items = data?.pages?.flatMap(page => page.items);
    const item = items.find(i => i.subject_id === subjectId);

    setContext({
      protocolId: context.protocolId,
      protocolVersionName: item.protocol_branch,
      siteId: item.site_id,
      subjectId: item.subject_id,
      presentation: {
        subjectLabel: item?.display_id?.[0]?.value,
      },
    });
  };

  const onClear = () => {
    if( previousContext?.siteId ) {
      setContext( previousContext );
      return;
    }
    clearContext();
  };

  const handleScroll = (event) => {
    event.stopPropagation();

    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Select
      className='SubjectContextFilterControl'
      placeholder='select subject'
      allowClear={true}
      loading={isLoading}
      onSelect={onSelect}
      onClear={onClear}
      dropdownRender={menu => <div onWheel={handleScroll}>{menu}</div>}
    >
      {data?.pages
           ?.flatMap(page => page.items)
           ?.map(item =>
              <Option key={item.subject_id}>{item?.display_id?.[0]?.value}</Option>
              )
      }
      {isError && <div>There was an error fetching data</div>}
    </Select>
  );
};
