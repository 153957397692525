import {
  useEffect,
  useState,
  useCallback,
} from 'react';

import {
  ProcedureSubjectLogView,
} from './ProcedureSubjectLogView';

import {
  ProcedureSubjectLogItemModal,
} from './ProcedureSubjectLogItemModal';

import {
  procedureDefinitionFromData
} from '../lib/protocolDefinitionUtils';

import {
  isOkRoleForDataEntryModify,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';
import { ReviewStatus, } from '../lib/dataUtils';

import { requestProcedureLoad } from '../legacy/LegacyFacade';

const ProcedureSubjectLogContent = props => {

  const {
    userData,
    portalUtils,
    context,
    procedureInfo,
    subjectLogProcedureData,
    siteInfo,
    visitInfo,
    protocolData

  } = props;

  if(!context.procedureId || !siteInfo || !procedureInfo || !subjectLogProcedureData ||!protocolData) {
    return null;
  }

  const userRole = protocolUserRoleFromUserData(
    userData, siteInfo.protocol_id);

  const isEditRole = isOkRoleForDataEntryModify(userRole);
  if( isEditRole ) {

/*
  the advent of procedure statuses "Not Done Previously Done"
  and "Not Required PreviouslyDone" force a re-examination of
  logs-in-forms display logic.
  
  Consider

  - The logs-in-forms widget that appears at the end of a form is a complete
    copy of the subject log listing for that kind of log, whether its rows
    "belong" to the form or not.
  - It is placed there as a convenience for a site user who is expected to
    fill one of those logs out each time they fill that form out
  - if the site user closes the form after opening it accidentally, they don't
    need short-cut access to that listing table from the form
  - nor does a DM or anyone else

  For now, we adjust only the display logic for a "Site user", identified here
  using `isOkRoleForDataEntryModify`, which resolves as CEN, HN, PI, SITE
  (see src/data/rights.js).

*/

    const reviewData = visitInfo?.data?.review?.find(
      p => p?.procedure_id === procedureInfo?.procedureId
        && p?.repeat_label_idx?.toString()
             === procedureInfo?.repeatIndex?.toString()
    );
    const reviewState = reviewData?.actions?.[0]?.action;
    if( ![ ReviewStatus.Open,
           ReviewStatus.ReviewComment ].includes( reviewState )  ) {
      return null;
    }

    const procedureData = visitInfo?.data?.visit?.procedures.find(
      p => p?.procedure_id === procedureInfo?.procedureId
        && p?.repeat_label_idx?.toString()
             === procedureInfo?.repeatIndex?.toString()
    );

    if(procedureData?.performance_status?.[0]?.status !== '1') {
      return null;
    }

  } // isEditRole

  const procedureDefinition = procedureDefinitionFromData(protocolData, siteInfo.protocol_id, siteInfo.protocolVersionName, procedureInfo.procedureId)

  if(!procedureDefinition?.linked_subject_log) {
    return null;
  }

  return portalUtils.attachProcedureSubjectLogs(context, (
    <ProcedureSubjectLogView {...{
      userData,
      siteInfo,
      visitInfo,
      protocolData,
      subjectLogProcedureData,
    }} />
  ));
};

const ProcedureSubjectLogRowContent = props => {
  const {
    classic,

    userData,

    protocolData,
    subjectData,
    siteInfo,
    visitInfo,
    subjectLogProcedureData,
  } = props;

  const [ subjectLogProcedureSelection,
          setSubjectLogProcedureSelection ] = useState( null );
  const [ newSubjectLogProcedureData,
          setNewSubjectLogProcedureData ] = useState( null );

  const resetSubjectLogProcedureSelection = useCallback(
    () => setSubjectLogProcedureSelection(() => null), []);

  useEffect(() => {

    if (!classic) {
      return;
    }

    classic.subscribe('ProcedureSubjectLogRowContent',
      'subjectLogProcedureSelection', setSubjectLogProcedureSelection);

    classic.subscribe('ProcedureSubjectLogRowContent',
      'newSubjectLogProcedureData', setNewSubjectLogProcedureData);

  }, [ classic ]);

  const refreshSubjectLogProcedureData = useCallback(() => {

    // a field has been edited, reload procedure data to refresh summary table

    requestProcedureLoad({
      protocolId: siteInfo?.protocol_id,
      siteId: siteInfo?.siteId,
      subjectId: subjectLogProcedureData?.subject_id,
      procedureId: subjectLogProcedureData?.procedure_id,
    });

  }, [
    siteInfo?.protocol_id,
    siteInfo?.siteId,
    subjectLogProcedureData?.procedure_id,
    subjectLogProcedureData?.subject_id,
  ]);

  // todo: code reuse -- this is copy-pasted here from
  // components/SubjectLogRowComponents.js

  useEffect(() => {

    // new entry, request new procedure data that has the new entry

    if( !newSubjectLogProcedureData ) {
      return () => {};
    }

    if( !subjectLogProcedureData?.procedure_id ) {
      return  () => {};
    }

    requestProcedureLoad({
      protocolId: siteInfo?.protocol_id,
      siteId: siteInfo?.siteId,
      subjectId: subjectLogProcedureData?.subject_id,
      procedureId: subjectLogProcedureData?.procedure_id,
    });

  }, [
    newSubjectLogProcedureData,
    siteInfo?.protocol_id,
    siteInfo?.siteId,
    subjectLogProcedureData?.subject_id,
    subjectLogProcedureData?.procedure_id,
  ]);


  // todo: code reuse -- this is copy-pasted here from
  // components/SubjectLogRowComponents.js
  useEffect(() => {

    // new entry, new procedure data is here, select the new entry

    if( !newSubjectLogProcedureData || !subjectLogProcedureData ) {
      return () => {};
    }

    if( subjectLogProcedureData.evidence.length
        <= newSubjectLogProcedureData.entity_row_count) {
      return () => {}; // hasn't changed
    }

    setSubjectLogProcedureSelection(() => ({
      index: newSubjectLogProcedureData.procedure_row,
      level: 0,
      crossout: false,
      lock: null,
    }));

    setNewSubjectLogProcedureData(() => null);

  }, [
    subjectLogProcedureData,
    newSubjectLogProcedureData,
  ]);

  useEffect( () => {
    if ( !visitInfo?.data && subjectLogProcedureSelection ) {
      resetSubjectLogProcedureSelection();
    }
  }, [ resetSubjectLogProcedureSelection,
       subjectLogProcedureSelection,
       visitInfo?.data,
  ]);

  // these components are exclusively for visit procedure context
  // visitInfo becomes {} when the user navigates away from the visit view
  if( !visitInfo?.data ) {
    return null
  }

  if( !subjectLogProcedureData?.procedure_id ) {
    return null;
  }

  if( !subjectLogProcedureSelection ) {
    return null;
  }

  if( Boolean( newSubjectLogProcedureData )) {
    return null;
  }

  return (
    <ProcedureSubjectLogItemModal {...{

      userData,

      protocolData,
      siteInfo,
      subjectData,
      subjectLogProcedureData,
      subjectLogProcedureSelection,
      
      refreshSubjectLogProcedureData,
      resetSubjectLogProcedureSelection, // needed?
    }} />
  );

};


export const ProcedureSubjectLogComponents = props => {

  const { classic, portalUtils } = props;

  const [ userData, setUserData ] = useState( null );

  const [ protocolData, setProtocolData ] = useState( null );
  const [ siteInfo, setSiteInfo ] = useState( null );
  const [ subjectData, setSubjectData ] = useState( null );
  const [ visitInfo, setVisitInfo ] = useState( null );
  const [ procedureInfo, setProcedureInfo ] = useState( null );
  const [ subjectLogProcedureData,
    setSubjectLogProcedureData ] = useState( null );
  // eslint-disable-next-line no-unused-vars
  const [ procedureReviewData,
    setProcedureReviewData ] = useState( null );

  useEffect(() => {
    if (!classic) {
      
      return;
    }

    classic.subscribe('ProcedureSubjectLogRowContent',
      'protocolListData', setUserData);

    classic.subscribe('ProcedureSubjectLogComponents',
      'protocolData', setProtocolData);
    classic.subscribe('ProcedureSubjectLogComponents',
      'enterSiteContext', setSiteInfo);
    classic.subscribe('ProcedureSubjectLogComponents',
      'enterSubjectContext', setSubjectData);
    classic.subscribe('ProcedureSubjectLogComponents',
      'enterVisitContext', setVisitInfo);
    classic.subscribe('ProcedureSubjectLogComponents',
      'enterProcedureContext', setProcedureInfo);   
    classic.subscribe('ProcedureSubjectLogComponents',
      'subjectLogProcedureData', setSubjectLogProcedureData);
    
    // next subscription is to force update on review status change
    classic.subscribe('ProcedureSubjectLogComponents', 
      'procedureReviewData', setProcedureReviewData);

  }, [classic]);

  const context = {
    procedureId: procedureInfo?.procedureId
  };

  if(!visitInfo?.data?.visit?.procedures) {
    if(procedureInfo !== null) {
      setProcedureInfo(null);
    }
    return null;
  }

  if( !procedureInfo || !subjectLogProcedureData ) {
    return null;
  }

  return (
  <>
    <ProcedureSubjectLogContent
      userData={userData}
      portalUtils={portalUtils}
      context={context}

      procedureInfo={procedureInfo}
      subjectLogProcedureData={subjectLogProcedureData}
      protocolData={protocolData}
      siteInfo={siteInfo}
      visitInfo={visitInfo}
    />

    <ProcedureSubjectLogRowContent {...{
      classic,

      userData,

      protocolData,
      siteInfo,
      subjectData,
      visitInfo,

      subjectLogProcedureData,
    }}
    />
  </>  
  );
};
