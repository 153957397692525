import moment from "moment";
import { Table } from "antd";
import { capitalizeFirstLetter } from '../../lib/stringUtils'
import { findFieldReviewRole, protocolUserRoleFromUserData } from "../../lib/roleUtils";

const FieldReviewHistoryTable = (props) => {
    const {
        isHistoryVisible,
        userData,
        siteInfo,
        actions,
    } = props;

    if (!actions) {
      return null;
    };

    const protocolUserRole = protocolUserRoleFromUserData( userData, siteInfo.protocol_id );
    const fieldReviewRole = findFieldReviewRole( protocolUserRole );

    const formattedActions = actions.map( (action) => {
      const name = action.author.systemGenerated
        ? 'Automated System Action due to Data Change'
        : `${action.author.firstName} ${action.author.lastName},  ${capitalizeFirstLetter(action.author.role)}`;

      return {
        time: action.date,
        name: name,
        action: action.action,
      }
    });

    const fieldReviewColumns = [
      {
        title: 'When',
        dataIndex: 'time',
        key: 'time',
        render: epochTime => moment(epochTime).format('L, hh:mm:ss'),
      },
      {
        title: 'Who',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Value',
        dataIndex: 'action',
        key: 'action',
        render: action => {
          switch (action) {
            case 'review':
              return 'Reviewed';
            case 'unreview':
              return 'Unreviewed';
            default:
              return action;
          }
        }
      }
    ]

    const isFieldReviewHistoryVisible = fieldReviewRole && isHistoryVisible;

    return (
      <div className="field-review-history-container">
        {
          isFieldReviewHistoryVisible ? (
            <Table
              title={() => <span className="history-table-title">Review History</span>}
              className="field-review-history"
              columns={fieldReviewColumns}
              dataSource={formattedActions}
              rowKey={record => record.time}
              pagination={false}
            />
          ) : null
        }
      </div>
    )
}

export default FieldReviewHistoryTable;