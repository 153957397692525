import { useEffect, useState, useCallback } from 'react';

import { Popover } from 'antd';

import { useDataRequest } from '../hook/useDataRequest';
import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';

import {
  updateVisitData,
} from '../lib/visitDataUtils';

import { DataItemQueryStatus } from './FieldQueries';
import {
  isProtocolDefinedAsEdcStudy,
  protocolDefinitionFromData,
  procedureDefinitionFromProtocolDefinition,
  isProcedureDefinitionHasFieldAlias,
} from '../lib/protocolDefinitionUtils';

import {
  isProcedureHasState,
} from '../lib/procedureFormUtils';

import { countProcedureFields } from '../lib/countProcedureFields';
import {
  FlagIcon,
  IncompleteIcon,
  CompleteIcon, 
} from './Icons';


import './VisitComponents.scss';


const VisitComponentDataQueryFlag = props => {

  const { portalUtils, userData, context, count, } = props;

  const principalQueryRole = findQueryRole(
          protocolUserRoleFromUserData( userData, context.protocolId ));

  if( !principalQueryRole ) {
    return null;
  }

  const countType = {
    'initiator': 'answered',
    'recipient': 'open',
  };

  if(count[countType[principalQueryRole]] <= 0) {
    return null;
  }

  const queryOrQueries = count[countType[principalQueryRole]] > 1
                         ? 'queries' : 'query';

  const title = principalQueryRole === 'initiator' 
    ? `Answered data item ${queryOrQueries} available`
    : `Open data item ${queryOrQueries} available`;

  return portalUtils.attachProcedureQueryFlag( context,
    <Popover
      className='VisitComponentDataQueryFlag'
      overlayClassName='VisitComponentDataQueryFlagOverlay'
      title={title}
      content={ <DataItemQueryStatus queryCounts={count} />}
    >
      <FlagIcon />
    </Popover>
  );
};

const VisitComponentDataQueryFlags = props => {

  const { portalUtils, userData, visitContext, } = props;

  const queryCountRequest = useDataRequest({
    endpoint: 'gui/queryCountList',
    context: visitContext,
    paging: {
      maxResults: null,
    },
    sort: {
      isAscending: true,
    },
    filter: {},
    config: { enabled: !!visitContext?.visitId, retryOnMount: true },
  });

  if(! queryCountRequest?.data?.items ) {
    return null;
  }

  return (
    <>
      { queryCountRequest.data.items.map( item => (
          <VisitComponentDataQueryFlag
            key={item.etag}
            portalUtils={portalUtils}
            userData={userData}
            context={item.context}
            count={item.count}
          />
        ))
      }
    </>
  );
};

const MissingFieldsStatusIndicator = ({count}) => {
  if(!Number.isFinite(count) || count < 0) {
    return null;
  }

  return (
    <Popover
      className={`MissingFieldsStatusIndicator ${
         count === 0 ? 'complete' : 'incomplete'}`}
      overlayClassName='MissingFieldsStatusIndicatorOverlay'
      title='Missing Field Count'
      content={ <span>{count} missing fields</span> }
    >
      { count === 0 && <CompleteIcon /> }
      { count > 0 && <IncompleteIcon /> }
    </Popover>
  );

};

const VisitComponentMissingFieldStatusIndicator = ({
  portalUtils,
  context,
  siteInfo,
  visitInfo,
  protocolData,
}) => {

  const { missing: count } = countProcedureFields({
    context,
    protocolData,
    siteInfo,
    visitInfo,
  });

  return portalUtils.attachProcedureMissingFieldStatusIndicator(
    context,
    <MissingFieldsStatusIndicator
      count={count}
    />
    );

};


const VisitComponentMissingFieldStatusIndicators = ({
  portalUtils,
  visitContext,
  visitInfo,
  siteInfo,
  protocolData,
  fieldSaveResponse,
  clearFieldSaveResponse,
}) => {

  const [effectiveVisitInfo, setEffectiveVisitInfo] = useState(visitInfo);

  const protocolVersionName = visitInfo?.data?.visit?.protocol_branch;
  const protocolDefinition = protocolDefinitionFromData(
    protocolData,
    visitContext.protocolId,
    protocolVersionName,
  );

  useEffect(() => {
    if(fieldSaveResponse) {
      setEffectiveVisitInfo(updateVisitData({
        visitInfo: effectiveVisitInfo,
        fieldSaveResponse,
      }));
      clearFieldSaveResponse();
    }
  }, [fieldSaveResponse, clearFieldSaveResponse, effectiveVisitInfo]);

  useEffect(() => {
    setEffectiveVisitInfo(visitInfo);
  }, [visitInfo]);

  if(!isProtocolDefinedAsEdcStudy({ protocolData, siteInfo })) {
    return null;
  }

  return (visitInfo?.data?.visit?.procedures || [])
    .filter((procedureItem) => {
      if(procedureItem?.performance_status?.[0]?.status !== '1') {
        return false;
      }
      const procedureDefinition = procedureDefinitionFromProtocolDefinition(
        protocolDefinition,
        procedureItem.procedure_id,
      );
      const isProcedureDefinitionHasConditionalFieldCountRules = (
        isProcedureDefinitionHasFieldAlias(procedureDefinition)
      );
      const isProcedureDefinitionHasStateFormula = (
        isProcedureHasState(procedureDefinition)
      );

      return (
        isProcedureDefinitionHasConditionalFieldCountRules
        || !isProcedureDefinitionHasStateFormula
      );

    })
    .map((procedureItem) => (
      <VisitComponentMissingFieldStatusIndicator
        key={`${procedureItem.procedure_id}-${
                procedureItem.repeat_label_idx}`}
        {...{
          portalUtils,
          context: {
            ...visitContext,
            procedureId: procedureItem.procedure_id,
            procedureRepeatIndex: procedureItem.repeat_label_idx,
          },
          siteInfo,
          visitInfo: effectiveVisitInfo,
          protocolData,
        }}
      />
    ));
};

export const VisitComponents = props => {

  const { classic, portalUtils } = props;

  const [siteInfo, setSiteInfo] = useState( null );
  const [visitInfo, setVisitInfo] = useState( null );
  const [userData, setUserData] = useState( null );
  const [protocolData, setProtocolData] = useState( null );
  const [fieldSaveResponse, setFieldSaveResponse] = useState(null);

  useEffect(() => {

    if (!classic) {
      return;
    }

    classic.subscribe('VisitComponents', 'enterSiteContext', setSiteInfo);
    classic.subscribe('VisitComponents', 'enterVisitContext', setVisitInfo);
    classic.subscribe('VisitComponents', 'protocolListData', setUserData);
    classic.subscribe('VisitComponents', 'protocolData', setProtocolData);
    classic.subscribe('VisitComponents', 'fieldSaveResponse',
                                              setFieldSaveResponse);

  }, [ classic ]);

  const clearFieldSaveResponse = useCallback(() => {
    setFieldSaveResponse(null);
  }, [setFieldSaveResponse]);

  // visitInfo becomes {} when the user navigates away from the visit view
  if(    ( ! siteInfo )
      || ( ! visitInfo?.data )) {

    return null;
  }

  const visitContext = {
    protocolId: siteInfo.protocol_id,
    siteId: siteInfo.siteId,
    subjectId: visitInfo?.data?.visit?.subject_id,
    visitId: visitInfo?.data?.visit?.visit_instance_id,
  };

  return (
    <>
      <VisitComponentDataQueryFlags
        { ...{ portalUtils, userData, visitContext, } }
      />
      <VisitComponentMissingFieldStatusIndicators
        {...{
          portalUtils,
          //userData,
          visitContext,
          visitInfo,
          siteInfo,
          protocolData,
          fieldSaveResponse,
          clearFieldSaveResponse,
        }}
      />
    </>
  );
};
