import { useState, useEffect, useRef, useCallback } from 'react';

import {
  Modal,
  Button,
  Input,
  Checkbox,
  Space,
  Alert,
  Image as AntdImage,
  Card,
} from 'antd';

import ReactTimeAgo from 'react-time-ago';

import {
  showEvidenceAddDialog,
  getApplicationEnvironment,
  showSignatureView,
  invalidateSubjectLogEvidencePages,
  trackEvidencePage,
} from '../../legacy/LegacyFacade';

import {
  protocolUserRoleFromUserData,
  isOkRoleForEvidenceAdd,
  isOkRoleForEvidenceView,
  isOkRoleForInvalidatedEvidenceView,
} from '../../lib/roleUtils';

import { ReviewStatus } from '../../lib/dataUtils';
import { QueryStatus } from '../DataItemQueries';

const EmptyEvidenceNotice = props => {
  const { isVisible } = props;

  if(!isVisible) {
    return null;
  }
  return (
    <div className='empty-evidence-notice'>
      Site has not uploaded any certified copies for this procedure.
    </div>
  );
};

const drawRedxOnCanvas = (context2d, imageDimensions) => {

  context2d.lineWidth = 3;
  context2d.strokeStyle = 'red';
  context2d.beginPath();
  context2d.moveTo(0,0);
  context2d.lineTo(imageDimensions.width, imageDimensions.height);
  context2d.moveTo(0, imageDimensions.height);
  context2d.lineTo(imageDimensions.width, 0);
  context2d.stroke();

};

const dataUrlFromArrayBuffer = async (buf, mime) => {

  const CHUNK_SIZE = 0x8000;
	const u8a = new Uint8Array( buf );

  const chunks = [];
  for (let i=0; i < u8a.length; i+=CHUNK_SIZE) {
    chunks.push(String.fromCharCode.apply(
      null, u8a.subarray(i, i+CHUNK_SIZE)));
  }

	const base64String = btoa( chunks.join('') );
  return `data:${mime};base64,${base64String}`;
};

const getImageDataUrl = async url => {

  const response = await fetch(url, {
    credentials: 'include',
  });
  const buf = await response.arrayBuffer();

  const mime = response.headers.get('Content-Type');

  return dataUrlFromArrayBuffer(buf, mime);

};

const getImage = async url => {

  const dataurl = await getImageDataUrl( url );
  const img = new Image(); // this is why we alias antd Image as AntdImage
  img.src = dataurl;
  return img;

};

const getRedxImage = async url => {


  const image = await getImage( url );

  const canvas = document.createElement('canvas');
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;


  const context = canvas.getContext('2d');

  context.drawImage(image, 0, 0);
  drawRedxOnCanvas(context, {width: image.naturalWidth,
                             height: image.naturalHeight});

  return canvas.toDataURL();

};


const InvalidatedEvidenceMedia = props => {

  const [ smallImageDataUrl, setSmallImageDataUrl ] = useState(null);
  const [ imageDataUrl, setImageDataUrl ] = useState(null);

  const {
    feedItem,
  } = props;

  const {
    feed_id: mediaId,
    // observed: date, fn, ln, org, signer_user_id,
    // inferred from code: invalid, name
    //invalid: isInvalidated,
  } = feedItem;

  const applicationEnvironment = getApplicationEnvironment();
  const url = `${applicationEnvironment.rest}/feed/get/${mediaId}`;

  useEffect(() => {

    const fetchDataUrl = async () => {
      setSmallImageDataUrl( await getRedxImage( `${url}/thumbnail` ));
    };

    if( smallImageDataUrl ) {
      return;
    }
    fetchDataUrl();

  }, [ smallImageDataUrl, url ]);

  useEffect(() => {

    const fetchDataUrl = async () => {
      setImageDataUrl( await getRedxImage( `${url}/fullsize` ));

    };

    if(imageDataUrl) {
      return;
    }

    fetchDataUrl();
  }, [ imageDataUrl, url ]);

  return (
    <div className='evidence-media invalidated-source'>
      <AntdImage
        rootClassName='invalid-source-image'
        className='evidence-media-image'
        preview={{
          src: imageDataUrl,
        }}
        src={ smallImageDataUrl }
      />
    </div>
  );
};

const EvidenceMedia = props => {

  const {
    feedItem,
    isCheckboxRequired,
  } = props;

  const {
    feed_id: mediaId,
    // observed: date, fn, ln, org, signer_user_id,
    // inferred from code: invalid, name
    invalid: isInvalidated,
  } = feedItem;

  // media_type in { 'fullsize', 'thumbnail', 'signature', 'original' }

  const applicationEnvironment = getApplicationEnvironment();
  const url = `${applicationEnvironment.rest}/feed/get/${mediaId}`;

  if(isInvalidated) {
    return (
      <InvalidatedEvidenceMedia
        feedItem={ feedItem }
      />
    );
  }

  return (
    <div className='evidence-media'>
      <AntdImage
        src={`${url}/thumbnail`}
        preview={{
          src: `${url}/fullsize`,
        }}
      />
      { isCheckboxRequired && (
        <Checkbox
          className='evidence-media-checkbox'
          value={mediaId}
        />
      )}
    </div>
  );
};

const EvidenceImageGallery = props => {

  // tracking behavior follows set_evidence_tracking() in evidence.js

  const {
    pages,
    setSelectedPageIds,
    isSelectorRequired,
  } = props;

  const [ isPreviewVisible, setIsPreviewVisible ] = useState( false );
  const renderedPageTracker  = useRef( {} );

  const trackPage = (pageId, date) => {

    const itemIndex = renderedPageTracker.current.findIndex(
                                                   item => item.id === pageId);
    renderedPageTracker.current[itemIndex] = {
      id: pageId,
      isViewed: true,
      viewStart: null,
    };

    trackEvidencePage( pageId, date );

  };

  const startTrackingPage = (pageId, date) => {

    const itemIndex = renderedPageTracker.current.findIndex(
                                                   item => item.id === pageId);
    if(renderedPageTracker.current[itemIndex].isViewed) {
      return;
    }

    renderedPageTracker.current[itemIndex] = {
      id: pageId,
      isViewed: false,
      viewStart: Date.now(),
    };
  };

  const trackRemainingViewedPages = useCallback(() => {

    const now = Date.now();

    const untrackedPages = renderedPageTracker.current.filter( item =>
      !item.isViewed
      && item.viewStart !== null
      && now - item.viewStart > 1500
    );

    for (const item of untrackedPages) {

      trackPage(item.id, now);

    }

  }, []);

  useEffect(() => {

    renderedPageTracker.current = pages.map(page => ({
      id: page.feed_id,
      isViewed: false,
      viewStart: null,
    }));

    return () => {
      trackRemainingViewedPages();
    };

  }, [ pages, trackRemainingViewedPages ]);

  const onCheckboxChange = value => {
    setSelectedPageIds(value);
  };

  return (
      <Checkbox.Group
        className='evidence-image-gallery'
        onChange={onCheckboxChange}
        >
        <AntdImage.PreviewGroup

          preview={{

            // antd uses package 'rc-image', so documentation for
            // preview.onVisibleChange and preview.countRender
            // is at https://github.com/react-component/image.git

            onVisibleChange: ( isVisible ) => {

              if(!isVisible) {
                trackRemainingViewedPages();
              }

              setIsPreviewVisible( isVisible );

            },

            countRender: (current, total) => {
              // ruse: hijack to capture index

              if(!isPreviewVisible) {
                return `${ current } / ${ total }`;
              }

              const pageId = pages[ current - 1 ].feed_id;

              trackRemainingViewedPages();
              startTrackingPage(pageId, Date.now());

              return `${ current } / ${ total }`;


            },

          }}
        >
          <Space className='thumbnails' wrap={true}>

            { pages.map( feedItem => (

              <EvidenceMedia
                key={feedItem.feed_id}
                feedItem={ feedItem }
                isCheckboxRequired={isSelectorRequired && pages.length > 1}
              />

            ))}

          </Space>
        </AntdImage.PreviewGroup>
      </Checkbox.Group>
  );
};

const EvidenceInvalidationDialog = props => {

  const {
    isInvalidationDialogVisible,
    setIsInvalidationDialogVisible,
    context,
    pageIds: invalidPageIds,
    refreshData,
  } = props;

  const [ evidenceInvalidationReason,
          setEvidenceInvalidationReason ] = useState('');
  const [ isEmptyReasonWarningVisible,
          setIsEmptyReasonWarningVisible ] = useState(false);

  if(invalidPageIds.length === 0) {
    return null;
  }

  return (
    <Modal
     {...{
       visible: isInvalidationDialogVisible,
       onCancel: () => setIsInvalidationDialogVisible( false ),
       onOk: async () => {
         if(evidenceInvalidationReason.length === 0) {
           setIsEmptyReasonWarningVisible( true );
           return;
         }
         await invalidateSubjectLogEvidencePages({
           context,
           evidenceInvalidationReason,
           pageIds: invalidPageIds,
           });
         setIsInvalidationDialogVisible( false );
         refreshData();
       },
       title: 'Confirm Invalidation',
       okText: 'Confirm',
     }}
    >
      <Space
        direction='vertical'
      >
        { isEmptyReasonWarningVisible && (
          <Alert
            type='error'
            message='Reason Required'
            description='Please provide reason for evidence invalidation'
          />
        )}
        <Alert
          type='warning'
          message='NOTE'
          description={(
            <ul>
              <li>Invalidated pages & documents cannot be re-validated</li>
              <li>Invalidated pages will no longer be visible to monitors</li>
            </ul>
          )}
        />
        <Input.TextArea
          className='evidence-invalidation-reason-input'
          placeholder='Evidence invalidation reason'
          onChange={ e => {
            const value = e.target.value;
            if(value.length > 0) {
              setIsEmptyReasonWarningVisible(false);
            }
            setEvidenceInvalidationReason( value );
          }}
        />
      </Space>
    </Modal>
  );

};

const EvidenceDocument = props => {

  const {

    userData,

    context,

    pages,
    isEvidenceEditable,
    isEvidenceDownloadAllowed,

    refreshData,
  } = props;

  const [ selectedPageIds, setSelectedPageIds ] = useState([]);
  const [ isInvalidationDialogVisible,
          setIsInvalidationDialogVisible ] = useState( false );

  const nValidPages = pages.filter(p => !p?.invalid).length;
  const userRole = protocolUserRoleFromUserData(userData, context.protocolId);

  const invalidatePages = pageIds => {
    // pageIds are "feed" IDs

    // evidence.view.handlebars: oncontextmenu="evidence_invalidate('{{feed_id}}', '{{proc_id}}');
    // onclick="evidence_invalidate_continue('{{feed_id}}', '{{proc_id}}');
    // evidence_invalidate pops up a confirm dialog
    // evidence_invalidate_continue composes feed.invalidate message

    setIsInvalidationDialogVisible( true );

  };

  const viewSignature = () => {
    // evidence.view.handlebars: onclick="evidence_signature_view('{{signature_url}}', '{{signature_date}}', '{{sqesc signature_name}}', '{{feed_id}}', '{{proc_name}}', {{download}});
    const args = {
      feedId: pages[0].feed_id,
      date: pages[0].date, // could be formatted
      signerFirstName: pages[0].fn,
      signerLastName: pages[0].ln,
      signerAffiliation: pages[0].org,
      isDownloadable: isEvidenceDownloadAllowed,
    };

    showSignatureView( args );
  };

  return (<>
    <Card
      className='evidence-document'
      cover={(
        <EvidenceImageGallery
          pages={ pages }
          setSelectedPageIds={ setSelectedPageIds }
          isSelectorRequired={ isOkRoleForEvidenceAdd( userRole ) 
                               && isEvidenceEditable }
        />
      )}
    >
      <Card.Meta
        title={`${pages[0].fn} ${pages[0].ln} of ${pages[0].org}`}
        description={<ReactTimeAgo date={new Date(pages[0].date)} />}
      />

      <Space className='toolbar'>
        <Button
          onClick={ viewSignature }
        >
          View Signature
        </Button>
        { isOkRoleForEvidenceAdd( userRole )
          && isEvidenceEditable
          && pages.length > 1
          &&  nValidPages > 0
          && (
          <Button
            onClick={ () => invalidatePages( selectedPageIds )}
            disabled={selectedPageIds.length === 0}
          >
            Invalidate Selected Pages
          </Button>
        )}
        { isOkRoleForEvidenceAdd( userRole )
          && isEvidenceEditable
          && pages.length === 1
          && nValidPages > 0
          && (
          <Button
            onClick={ () => {
              setSelectedPageIds( [ pages[0].feed_id ] );
              invalidatePages( pages.map(p => p.feed_id ) );
            }}
          >
            Invalidate Document
          </Button>
        )}
      </Space>
    </Card>
    <EvidenceInvalidationDialog
      {...{
        isInvalidationDialogVisible,
        setIsInvalidationDialogVisible,
        pageIds: selectedPageIds,
        context,
        refreshData,
      }}
    />
  </>);
};

const EvidenceDocumentList = props => {

  const {
    userData,

    context,

    documents,
    isEvidenceEditable,
    isEvidenceDownloadAllowed,

    refreshData,
  } = props;

  const dates = Object.keys(documents).map(k => parseInt(k));
  dates.sort( (a, b) => b - a); // descending date

  return (
   <div className='evidence-document-list'>
     { dates.map(d => (
       <EvidenceDocument
         key={d}
         userData={ userData }
         context={ context }
         pages={documents[d]}
         isEvidenceEditable={ isEvidenceEditable }
         isEvidenceDownloadAllowed={ isEvidenceDownloadAllowed }

         refreshData={ refreshData }
       />
     ))}
   </div>
 );


};

export const isProcedureCertifyCopyEnabled = (
                                    studyDefinition, procedureDefinition) => {

  const isStudyAccessDefined = Boolean(studyDefinition.certified_copy);
  const studyAccessValue = isStudyAccessDefined
                             ? studyDefinition.certified_copy === 'enable'
                             : true;

  const isProcedureAccessDefined = Boolean(
                                     procedureDefinition.certified_copy);
  const procedureAccessValue = isProcedureAccessDefined
                             ? procedureDefinition.certified_copy === 'enable'
                             : studyAccessValue;
  return procedureAccessValue;
};

export const ProcedureEvidence = props => {

  // if Procedure Certify Copy is not Enabled, it is the consumer's
  // responsibility not to render this component

  const {
    context,
    evidence,
    refreshSubjectLogProcedureData,
    userData,
    isSubjectLogRowLocked,
    reviewStatus,
    procedureQueryItems,
    isEvidenceDownloadAllowed,

  } = props;

  // See log.js at about lines 852 - 865 today for hints about support for a
  // 'certified_copy' attribute with possible value 'disable' at either the
  // study or procedure level
  //
  // one approach might be to replace isEvidenceDownloadAllowed prop with the
  // procedureDefinition and create isEvidenceDownloadAllowed here
  //
  // probably still need study def here too

  const { feed: pages, } = evidence;
  const userRole = protocolUserRoleFromUserData(userData, context.protocolId);

  if(!isOkRoleForEvidenceView(userRole)) {
    return null;
  }

  const isShowInvalidatedPages =
    isOkRoleForInvalidatedEvidenceView( userRole );

  const documents = {};
  for (const p of pages) {
    if(!isShowInvalidatedPages && p.invalid) {
      continue;
    }
    if(!( p.date in documents)) {
      documents[ p.date ] = [];
    }
    documents[ p.date ].push(p);
  }

  const isOpenishReviewMode = [ ReviewStatus.Undefined,
                                ReviewStatus.Open,
                                ReviewStatus.ReviewComment
                              ].includes( reviewStatus );
  const isEvidenceEditable = !isSubjectLogRowLocked
                             && (
                                  isOpenishReviewMode 
                                  ||
                                  procedureQueryItems.some( queryItem =>
                                    queryItem.status === QueryStatus.Open )
                                );
  // follows classic/templates/procedure.layout.handlebars
  // The action of the button is evidence_show_modal('{{repeat_idx}}:{{id}}')

  return (
    <div className='procedure-evidence'>
      <h4>Certified Copies</h4>
      { isOkRoleForEvidenceAdd(userRole) 
        && isEvidenceEditable
        && (
        <Button
          id='cc-add-button'
          onClick={ () => showEvidenceAddDialog( context ) }
        >
          + Add
        </Button>
      )}
      <EmptyEvidenceNotice isVisible={ !evidence?.feed?.length } />
      <EvidenceDocumentList
        documents={ documents }
        context={ context }
        userData={ userData }
        refreshData={ refreshSubjectLogProcedureData }
        isEvidenceEditable={ isEvidenceEditable }
        isEvidenceDownloadAllowed={ isEvidenceDownloadAllowed }
      />
    </div>
  );
};


