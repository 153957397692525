import { useEffect, useState, useRef } from "react";

export const HoverWrapperForClassicEnvironment = (props) => {
  const { renderHoverableComponent, className, ...childProps } = props;
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // it's voodoo, but also, perhaps, the least worst option
    const containerElement = ref.current.closest(
      ".react-field-control-container"
    );

    const onMouseEnter = () => setIsActive(true);
    const onMouseLeave = () => setIsActive(false);

    containerElement.addEventListener("mouseenter", onMouseEnter);
    containerElement.addEventListener("mouseleave", onMouseLeave);

    return () => {
      containerElement.removeEventListener("mouseenter", onMouseEnter);
      containerElement.removeEventListener("mouseleave", onMouseLeave);
    };
  }, []);

  return (
    <div className={className} ref={ref}>
      {renderHoverableComponent(childProps, isActive)}
    </div>
  );
};
