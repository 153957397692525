import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import {
  Button,
  InputNumber,
  Radio,
  Checkbox,
  Table,
  Space,
  List,
  Select,
  Divider,
  DatePicker,
  TimePicker,
  Alert,
  Form,
} from 'antd';

import {
  HistoryIcon,
  VersionMigrationIcon,
  DataEntryModeSourceIcon,
  CPEnabledIcon,
  SourceUpoadRequiredIcon,
} from '../Icons';

import moment from 'moment';

import {
  fieldDefinitionFromProcedureDefinition,
} from '../../lib/protocolDefinitionUtils';

import { ReviewStatus, } from '../../lib/dataUtils';
import { QueryStatus } from '../DataItemQueries';

import {
  protocolUserRoleFromUserData,
  isOkRoleForDataEntryModify,
} from '../../lib/roleUtils';

import {
  getCountryCode,
  getFieldHistory,
  getTimezone,
  saveField,
} from '../../legacy/LegacyFacade';

import { DataSourceIndicator } from './DataSourceIndicator';
import { FieldActionsDisplayDrawerControl } from './FieldActionsDisplayDrawerControl';
import FieldReasonForChangeDialog from './FieldReasonForChangeDialog';

import FieldReviewHistoryTable from '../fieldReviews/FieldReviewHistoryTable';
import { SourceDocumentDisplay } from './SourceDocumentDisplay';

import { INTENTIONALLY_LEFT_BLANK, NOT_ANSWERED } from './constants';

import './Field.scss';
import TextAreaField from './TextAreaField';

const stringOrNumberZeroPaddingFormatter = v => {

  if(v === 'UNK') {
    return 'UNK';
  }

  const s = Number.isFinite( v ) ? v.toString() : v ;

  const isNonEmptyString = typeof s === 'string' && s.length > 0;

  return isNonEmptyString ? s.padStart( 2, '0' ) : '';
};

const FieldHistory = props => {

  const {
    isHistoryVisible,
    siteInfo,
    protocolVersionId,
    fieldData,
    fieldType,
    fieldPickValues,
    timeFormat,
    userRole
  } = props

  const [ historyData, setHistoryData ] = useState(null);

  useEffect(() => {
    const fetchHistoryData = async () => {

      const newHistoryData = await getFieldHistory({
        protocolId: siteInfo.protocol_id,
        siteId: siteInfo.siteId,
        protocolVersionId,
        protocolVersionName: fieldData.protocol_branch,
        fieldId: fieldData.field_instance_id,
      });

      setHistoryData( newHistoryData );

    };

    if( !isHistoryVisible ){
      return;
    }

    if(historyData
       && historyData?.field?.data
       && historyData.field.data.length === 0
       ) {
      // history response is here, field has never been filled in
      return;
    }

    if(historyData && fieldData
       && historyData.field.date_updated === fieldData.data?.[0].date ){
      return;
    }

    fetchHistoryData();

  }, [
    fieldData,
    protocolVersionId,
    siteInfo.protocol_id,
    siteInfo.siteId,
    isHistoryVisible,
    historyData,
  ]);

  if(!isHistoryVisible) {
    return null;
  }

  if(!historyData) {
    return null;
  }

  const displayDataEntryMode = raw => {
    if(!raw || raw === ''){
      return 'Transcription';
    }
    switch (raw) {
      case 'source': return 'Source';
      case 'transcription': return 'Transcription';
      default: return raw;
    }
    // eslint-disable-next-line no-unreachable
    throw new Error('Unreachable code!');
  };

  const prepareDatePartialForDisplay = fieldValues => {

    const monthNames = ['JAN', 'FEB', 'MAR', 'APR',
                        'MAY', 'JUN', 'JUL', 'AUG',
                        'SEP', 'OCT', 'NOV', 'DEC'
                       ];

    const pcs = fieldValues.split('-');

    const year = pcs[0];
    const month = monthNames[ pcs[1] - 1 ] ?? 'UNK';
    const day = pcs.length > 2 && pcs[2] !== ''
                ? stringOrNumberZeroPaddingFormatter(pcs[2])
                : 'UNK';

    return [ year, month, day ].slice(0, pcs.length).join('-');

  };

  const prepareDateTimePartialForDisplay = fieldValues => {

    const datePartial = prepareDatePartialForDisplay(fieldValues);
    const pcs = fieldValues.split('-');
    if(pcs.length < 4) {
      return datePartial;
    }
    const hour = stringOrNumberZeroPaddingFormatter(pcs[3]);
    const minute = stringOrNumberZeroPaddingFormatter(pcs[4]);
    const second = pcs.length > 5 && pcs[5] !== ''
                     ? stringOrNumberZeroPaddingFormatter(pcs[5])
                     : 'UNK';

    const timeDisplay = [ hour, minute, second ].slice(0, pcs.length - 3)
                                                .join(':');

    return `${datePartial}, ${timeDisplay}`;

  };

  const preparePickOneForDisplay = fieldValues => {
    const jsonValue = JSON.parse(fieldValues);
    if(!jsonValue.other) return fieldPickValues[jsonValue.value];
    return fieldPickValues[jsonValue.value] + ": " + jsonValue.other;
  };

  const preparePickManyForDisplay = fieldValues => {
    const splitValues = fieldValues.split(';');
    let combinedValues = [];
    for( const value of splitValues){
      const jsonValue = JSON.parse(value);
      if(jsonValue.other) {
        combinedValues.push(fieldPickValues[jsonValue.value] + ": " + jsonValue.other);
      } else {
        combinedValues.push(fieldPickValues[jsonValue.value]);
      }
      // combinedValues += fieldPickValues[jsonValue.value] + ' ';
    }
    return combinedValues.join(', ');
  };

  const prepareForDisplay = (fieldValues, fieldType) => {

    const fieldTypeFunction = {
      'date-partial': prepareDatePartialForDisplay,
      'datetime-partial': prepareDateTimePartialForDisplay,
      'pick-one': preparePickOneForDisplay,
      'pick-many': preparePickManyForDisplay
    };

    if(![
          'pick-many',
          'pick-one',
          'datetime-partial',
          'date-partial'
        ].includes(fieldType)){

      return fieldValues;

    }

    return fieldTypeFunction[fieldType](fieldValues);
  };

  const prepareDateTimeForDisplay = (text, inputOffset, value) => {
    const countryCode = getCountryCode(siteInfo.siteId);
    const timezone = getTimezone({ countryCode, inputOffset, value });
    const offsetDTString = adjustDateTimeWithOffset(text, inputOffset);
    switch (fieldType) {
      case 'time':
        return `${offsetDTString.format(timeFormat)} (${timezone})`;
      case 'datetime':
        return `${offsetDTString.format('L, HH:mm:ss')} (${timezone})`;
      case 'date':
        return `${offsetDTString.format('L')} (${timezone})`;
      default:
        throw new Error(`Unrecognized date type '${fieldType}'`);
    }
  };

  const columns = [
    {
      title: 'When',
      dataIndex: 'date',
      key: 'date',
      render: (text, data) => {
        if (data?.transaction_date){
          /*
           * The transaction_date pushed from clinical pipe should take
           * precedence over the date from the Vessel application
           */
          return moment(data.transaction_date).format('L, HH:mm:ss');
        }
        return moment(text).format('L, HH:mm:ss');
      },
    },
    {
      title: 'Who',
      dataIndex: 'user',
      key: 'user'
    },
    {
      title: 'Why',
      dataIndex: 'rfc',
      key: 'rfc',
      render: text => {
        return text || 'No Previous Value';
      },
    },
    {
      title: 'Data Entry Mode',
      dataIndex: 'demode',
      key: 'demode',
      render: text => {
        return displayDataEntryMode(text);
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, data) => {

        if(text === undefined){
          return INTENTIONALLY_LEFT_BLANK;
        }

        if(['date','datetime','time'].includes(fieldType)){
          return prepareDateTimeForDisplay(text, data.offset, data.value);
        }

        return prepareForDisplay(text, fieldType);
      },
    }
  ];

  return (
    <>
      <SourceDocumentDisplay
        protocolId={siteInfo.protocol_id}
        fieldId={fieldData.field_instance_id}
        fieldSourceType={fieldData.data?.[0].demode}
        sourceDocuments={fieldData.data?.[0].source_documents}
        formType='Subject Log'
        userRole={userRole}
      />
      <Table
        className='History'
        title={() => <span className='history-table-title'>Data Entry History</span>}
        dataSource={historyData?.field?.data ?? []}
        columns={columns}
        pagination={false}
        rowKey={record => record?.date}
      />
    </>
  );
};

export const HistoryField = props => {
  const {
    fieldDefinition,
    fieldData,
    timeFormat,

    protocolVersionId,
    siteInfo,
    children,
    menuItems,
    onBlur,
    onPointerEnter,
    onPointerLeave,
    isQueryDisplayVisible,
    isActive,
    className,
    isFieldValueChanged,
    fieldReviewItems,

    userData,
  } = props;

  const [ isHistoryVisible, setIsHistoryVisible ] = useState(false);
  const [fieldDataSource, setFieldDataSource ] = useState(null);

  useEffect(() => {
    const dataSource = fieldData.data?.length > 0 ? fieldData.data[0].demode : null
    setFieldDataSource(dataSource);
  }, [fieldData]);

  const fieldReview = fieldReviewItems?.find(
    item => item?.context?.fieldId === fieldData.field_instance_id
  );

  return (
    <div
      className={`${className ? className + " " : ""}HistoryField${
        isQueryDisplayVisible ? " selected-field-for-query-detail" : ""
      }${isFieldValueChanged ? " field-value-is-changed" : ""}`}
      onBlur={onBlur}
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
    >
      <div className='field-header'>
        <div className='title-bar' data-field-id={fieldData.field_instance_id}>
          {fieldDefinition.text}
          <Button
            className='icon-button'
            onClick={() => {
              setIsHistoryVisible(!isHistoryVisible);
            }}
          >
            <HistoryIcon title='Field Audit History'/>
          </Button>

          {fieldData.data?.[0]?.source && (
            <DataEntryModeSourceIcon title='Source Data Entry'/>
          )}
          {fieldData.version_migration && (
            <VersionMigrationIcon title='Version Migrated Field'/>
          )}
          {fieldDefinition.cp_field && (
            <CPEnabledIcon title='CP Enabled Field'/>
          )}
          {fieldDefinition.source_req && (
            <SourceUpoadRequiredIcon title='Source Upload Required Field'/>
          )}
        </div>
        <div className='menu-bar'>
          <DataSourceIndicator dataSource={fieldDataSource} isActive={isActive}/>
          {menuItems}
        </div>
      </div>
      <div className='field-content'>
        {children}
      </div>
      <FieldHistory
        isHistoryVisible={isHistoryVisible}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        fieldData={fieldData}
        fieldType={fieldDefinition.type}
        fieldPickValues={fieldDefinition.values}
        timeFormat={timeFormat}
        userRole={protocolUserRoleFromUserData(userData, siteInfo.protocol_id)}
      />
      <FieldReviewHistoryTable
        siteInfo={siteInfo}
        isHistoryVisible={isHistoryVisible}
        actions={fieldReview?.payload?.actions}
        userData={userData}
      />
    </div>
  );
};

const StringField = props => {

  const {

    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( null );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState( false );

  useEffect(() => {
    setValue( () => fieldData?.data?.[0]?.value);
  }, [ fieldData?.data ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue( () => fieldData?.data?.[0]?.value);

  }, [ fieldData?.data, isResetDisplayValue ]);

  const isValueChanged = () => {

    if(!fieldData?.data?.[0]?.value && value === '') {
      return false;
    }

    return value !== fieldData?.data?.[0]?.value;

  };

  return (
    <HistoryField
      className='StringField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={e => handleFieldValueChange(value) }
      onPointerEnter={ () => setIsActive(!isQueryDisplayVisible && true)}
      onPointerLeave={ () => setIsActive(false)}
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          userData,

          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          protocolData,
          subjectLogProcedureData,

          procedureQueryItems,
          fieldReviewItems,

          isQueryDisplayVisible,
          isActive,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,

        }}/>)}
    >
      <TextAreaField
        autoSize={{ minRows: 1, maxRows: 3 }}
        charLimit={ fieldDefinition.character_limit }
        value={ value }
        onChange={ e => setValue(() => e.target.value) }

        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'
      />
    </HistoryField>
    );

};

const IntegerField = props => {

  const DEFAULT_VALUE = null;

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( DEFAULT_VALUE );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState( false );
  const [ isWarningActive, setIsWarningActive ] = useState( false );

  useEffect(() => {
    setValue(fieldData?.data?.[0]?.value);
  }, [ fieldData?.data, setValue ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue(fieldData?.data?.[0]?.value);

  }, [ fieldData?.data, isResetDisplayValue, setValue ]);

  if(!context) {
    return null;
  }

  const onChange = newValue => setValue(() => newValue ?? DEFAULT_VALUE);

  const isValueChanged = () => {

    const v0 = Number.isFinite(fieldData?.data?.[0]?.value)
             ? fieldData?.data?.[0]?.value.toString()
             : null;
    const v = Number.isFinite(value)
             ? value.toString()
             : null;

    return v !== v0;
  };

  const onBlur = e => {
    const newInputValue = e.target.value;
    const integerRegExp = /^\d+$/;;

    if(newInputValue && !(integerRegExp.test(newInputValue))) {
      setIsWarningActive(true);
      return;
    }

    setIsWarningActive(false);
    handleFieldValueChange(value?.toString());

  }

  return (
    <HistoryField
      className='IntegerField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={onBlur}
      onPointerEnter={ () => setIsActive(!isQueryDisplayVisible && true)}
      onPointerLeave={ () => setIsActive(false)}
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,

        }}/>)}
    >
      {isWarningActive && (
        <Alert
          className='Alert'
          message='Input is not a valid integer value'
          type='error'
        />
      )}

        <InputNumber

          className='IntegerFieldForm'
          defaultValue={DEFAULT_VALUE}
          value={value ?? DEFAULT_VALUE}
          onChange={onChange}
          controls={false}
          precision={0}

          name='data-element'
          data-label={fieldDefinition.text}
          data-value={value ?? ''}
          data-access='modify'
        />
    </HistoryField>
    );

};
const adjustDateTimeWithOffset = ( value, inputOffset) => {
  const offset = inputOffset/(60 * 1000 * 1);
  return moment(value).utcOffset(offset);
};

const FloatField = props => {

  const DEFAULT_VALUE = null;
  const DEFAULT_NUM_DECIMAL_PLACES = 0;

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( DEFAULT_VALUE );
  const [ numDecimalPlaces, setNumDecimalPlaces ]
                                      = useState( DEFAULT_NUM_DECIMAL_PLACES );


  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState( false );
  const [ isWarningActive, setIsWarningActive ] = useState( false );
  const [ isLoading, setIsLoading ] = useState( true );

  const findNumDecimalPlaces = s => {
    if(s === null) {
        return DEFAULT_NUM_DECIMAL_PLACES;
    }

    // assume value is string, well formatted as decimal with dot separator

    const decimalSeparator = '.';
    const decimalSeparatorIndex = s.indexOf(decimalSeparator);

    if(decimalSeparatorIndex < 0) {
      return DEFAULT_NUM_DECIMAL_PLACES;
    }

    const fractionalPart = s.slice(decimalSeparatorIndex);
    return fractionalPart.length - 1;

  };

  const getValue = useCallback(() => {

    if(value === null || value === '') {
      return '';
    }
    return Number.parseFloat( value )?.toFixed( numDecimalPlaces ) ?? null;
  },
    [ numDecimalPlaces, value ]);

  const initialValue = useMemo(() => {

    const v = fieldData?.data?.[0]?.value ?? DEFAULT_VALUE;

    return {
      value: v,
      numDecimalPlaces: findNumDecimalPlaces( v ),
    };

  }, [ fieldData?.data ]);

  useEffect(() => {

    const { value: v, numDecimalPlaces: p } = initialValue;
    setValue(() => v);
    setNumDecimalPlaces(() => p);
    setIsLoading(() => false);

  }, [ initialValue ]);


  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    const { value: v, numDecimalPlaces: p } = initialValue;
    setValue(() => v);
    setNumDecimalPlaces(() => p);

  }, [ initialValue, isResetDisplayValue ]);

  if(isLoading || isResetDisplayValue) {
    return null;
  }

  const isValueChanged = () => {

    const { value: v0 } = initialValue;
    const v = getValue();

    if(v0 === null && v === '') {
      return false;
    }
    return v !== v0;
  };

  const onBlur = e => {

    // e.target is an icon button, somehow. Don't depend on its target value

    const floatRegExp = /^[+-]?\d+(?:\.\d+)?$/;

    if(value && !floatRegExp.test(value)) {
      setIsWarningActive(true);
      return;
    }
    setIsWarningActive(false);

    if(!isValueChanged()) {
      return;
    }
    handleFieldValueChange(getValue());

  }

  return (
    <HistoryField
      className='FloatField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={onBlur}
      onPointerEnter={ () => setIsActive(!isQueryDisplayVisible && true)}
      onPointerLeave={ () => setIsActive(false)}
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,

        }}/>)}
    >
      {isWarningActive && (
        <Alert
          className='Alert'
          message='Input is not a valid float value'
          type='error'
        />
      )}

      <InputNumber
        className='FloatFieldForm'

        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'

        controls={false}
        value={getValue()}

        formatter={ (v, info) => {

          if(v === '' || v === null ) {
            return '';
          }
          return Number.parseFloat(v).toFixed( numDecimalPlaces );

        }}

        parser={ s => {
          const p = findNumDecimalPlaces( s );
          setValue(() => s.trim() === '' ? null : s.trim());
          setNumDecimalPlaces(() => p );
          return s.trim() === '' ? null : s.trim();
        }}
      />

    </HistoryField>
  );
};

const DateField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( null );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState( false );

  useEffect(() => {

    setValue(() => fieldData?.data?.[0]?.value
                     ? adjustDateTimeWithOffset(
                         fieldData?.data?.[0]?.value,
                         fieldData?.data?.[0]?.offset)
                     : null);

  }, [ fieldData?.data ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue(() =>
      fieldData?.data?.[0]?.value ? moment( fieldData?.data?.[0]?.value )
                                  : null);

  }, [ fieldData?.data, isResetDisplayValue ]);

  const isValueChanged = () => {
    const v = value ? value : null;
    const v0 = fieldData?.data?.[0]?.value
               ? adjustDateTimeWithOffset(
                   fieldData?.data?.[0]?.value,
                   fieldData?.data?.[0]?.offset)
               : null;
    if(v === null) {
      return v0 !== null;
    }
    if(v0 === null) {
      return true;
    }
    return !v.isSame(v0);
  };

  const isDateDisabled = (currentDate, today) => {
    const isAllowFutureDate = fieldDefinition.futuredate ?? false;
    if(isAllowFutureDate) {
      return false;
    }
    return currentDate.isAfter(today);
  };

  return (
    <HistoryField
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={e => handleFieldValueChange(value?.valueOf() ?? null )}
      onPointerEnter={ () => setIsActive(!isQueryDisplayVisible && true)}
      onPointerLeave={ () => setIsActive(false)}
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,
        }}/>)}
    >
      <DatePicker
        value={ value }
        onChange={(date, dateString) => {
          setValue(() => date ? moment(dateString) : null);
          if(date === null ){
            // clear button side-steps onBlur event
            handleFieldValueChange(null);
          }
        }}
        disabledDate={isDateDisabled}
        format='L'

        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'
      />
    </HistoryField>
    );

};

const DatetimeField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( null );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState( false );

  useEffect(() => {
    setValue(() => fieldData?.data?.[0]?.value
                     ? adjustDateTimeWithOffset(
                         fieldData?.data?.[0]?.value,
                         fieldData?.data?.[0]?.offset)
                     : null);

  }, [ fieldData?.data ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue(() =>
      fieldData?.data?.[0]?.value ? moment( fieldData?.data?.[0]?.value )
                                  : null);

  }, [ fieldData?.data, isResetDisplayValue ]);

  const isValueChanged = () => {
    const v = value ? value : null;
    const v0 = fieldData?.data?.[0]?.value
               ? adjustDateTimeWithOffset(
                   fieldData?.data?.[0]?.value,
                   fieldData?.data?.[0]?.offset)
               : null;
    if(v === null) {
      return v0 !== null;
    }
    if(v0 === null) {
      return true;
    }
    return !v.isSame(v0);
  };

  const isDateDisabled = (currentDate, today) => {
    const isAllowFutureDate = fieldDefinition.futuredate ?? false;
    if(isAllowFutureDate) {
      return false;
    }
    return currentDate.isAfter(today);
  };

  return (
    <HistoryField
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={e => handleFieldValueChange(value?.valueOf() ?? null )}
      onPointerEnter={ () => setIsActive(!isQueryDisplayVisible && true)}
      onPointerLeave={ () => setIsActive(false)}
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,
        }}/>)}
    >
      <DatePicker
        showTime={true}
        allowClear={true}
        value={ value }
        onChange={( date ) => {
          // catches the clear signal
          setValue(() => date ? date : null);
          if(date === null ){
            setValue(() => null);
            // clear button side-steps onBlur event
            handleFieldValueChange(null);
          }
        }}
        onSelect={( date ) => {
          setValue(() => date ? date : null);
          if(date === null ){
            // clear button side-steps onBlur event
            handleFieldValueChange(null);
          }
        }}
        disabledDate={isDateDisabled}

        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'
      />
    </HistoryField>
    );

};

const DatePartialField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const isPointerInFieldBox = useRef( false );
  const [ isSomeInputHasFocus, setIsSomeInputHasFocus ] = useState( false );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =
                                                              useState(false);
  const [ isActive, setIsActive ] = useState(false);

  const [ today, setToday ] = useState( new Date() );
  const [ isUnknown, setIsUnknown ] = useState(false);
  const [ maxYear, setMaxYear ] = useState( 2100 );
  const [ maxMonth, setMaxMonth ] = useState( 12 );
  const [ maxDay, setMaxDay ] = useState( 31 );

  const [ form ] = Form.useForm();

  const setIsPointerInFieldBox = v => {
    isPointerInFieldBox.current = v;
  };

  const parsedDataValue = useMemo(() => {

    const dataValue = fieldData?.data?.[0]?.value;

    const [ year, month, day ] = dataValue?.split('-')
                                   ?.map(e => e === 'UNK' ? null
                                                          : parseInt(e))
                                   ?? [ null, null, null ];
    return {
      isUnknown: dataValue === 'UNK-UNK-UNK',
      year,
      month,
      day,
    };

  }, [ fieldData?.data ]);

  const resetControlConstraints = useCallback(() => {

    setMaxYear(() => 2100);
    setMaxMonth(() => 12);
    setMaxDay(() => 31);

    if(parsedDataValue.isUnknown) {

      setIsUnknown(() => true);

      return;
    }

    setIsUnknown(() => false);

    const now = new Date();
    setToday( () => now );

    const isAllowFutureDate = fieldDefinition.futuredate ?? false;

    if(Number.isFinite(parsedDataValue.year)
       && Number.isFinite(parsedDataValue.month)) {
        setMaxDay(() => moment({
                year: parsedDataValue.year,
                month: parsedDataValue.month - 1,
              }).daysInMonth());
    }

    if(!isAllowFutureDate) {

      setMaxYear(() => now.getFullYear());
      if(parsedDataValue.year === now.getFullYear()){

        setMaxMonth(() => now.getMonth() + 1);
        if(parsedDataValue.month === now.getMonth() + 1) {
          setMaxDay(() => now.getDate());
        }

      } // parsedDataValue.year === now.getFullYear()

    } // !isAllowFutureDate

  }, [ parsedDataValue, fieldDefinition.futuredate ]);

  useEffect(() => {

    resetControlConstraints();

  }, [ resetControlConstraints ]);

  useEffect(() => {

    const unknownValue = 'UNK-UNK-UNK';
    setIsUnknown(() => fieldData?.data?.[0]?.value === unknownValue);

  }, [ fieldData?.data ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    resetControlConstraints();
    form.resetFields();

  }, [ form, isResetDisplayValue, resetControlConstraints ]);

  const isValueChanged = useCallback(value => {

    const keys = Object.keys(parsedDataValue);
    for (const k of keys) {
      if(parsedDataValue[k] !== value[k]){
        return true;
      }
    }

    return false;

  }, [ parsedDataValue ]);

  const saveData = useCallback(value => {

    if(!isValueChanged( value )) {
      return;
    }

    if((fieldDefinition?.dtp_unknown ?? true ) && value.isUnknown) {
      const unknownValue = 'UNK-UNK-UNK';
      handleFieldValueChange(unknownValue);
      return;
    }

    if(!value.year) {
      handleFieldValueChange(null);
      return;
    }

    const dataValues = [
      value.year,
      value.month,
      value.day,
    ].map(e => e ?? 'UNK');

    handleFieldValueChange(dataValues.join('-'));

  }, [
    fieldDefinition?.dtp_unknown,
    isValueChanged,
    handleFieldValueChange,
  ]);

  const onPointerEnter = e => {
    setIsActive( () => !isQueryDisplayVisible );
    setIsPointerInFieldBox( true );
  };

  const onPointerLeave = e => {

    setIsActive(false);
    setIsPointerInFieldBox( false );

    if(isSomeInputHasFocus) {
      return;
    }

    if(! form?.isFieldsTouched([ 'isUnknown', 'year', 'month', 'day' ] )) {
      return;
    }

    form.submit();

  };

  const onBlur = () => {

    if(isPointerInFieldBox.current) {
      return;
    }

    if(! form?.isFieldsTouched([ 'isUnknown', 'year', 'month', 'day' ] )) {
      return;
    }

    form.submit();

  };

  const onInputFocus = e => {
    setIsSomeInputHasFocus(() => true);
  };

  const onInputBlur = e => {
    setIsSomeInputHasFocus(() => false);
  };

  const setNullBelowNullField = (changedValues, allValues) => {

    const units = [ 'year', 'month', 'day', ];

    const firstNullUnitIndex = allValues['isUnknown']
      ? 0
      : units.findIndex(e => !Number.isFinite(allValues[e]));

    const correctedValues = JSON.parse(JSON.stringify( allValues ));
    if(firstNullUnitIndex > -1) {
      for (const unit of units.slice(firstNullUnitIndex)) {
        correctedValues[unit] = null;
      }
    }

    form.setFieldsValue( correctedValues );

  };

  const isFormValueNull = () => {

    const value = form?.getFieldsValue([ 'isUnknown', 'year', 'month', 'day' ]);

    return !value.isUnknown
        && value.year === null
        && value.month === null
        && value.day === null;

  };

  const isFormValueChanged = () => {

    // isValueChanged compares value against parsedDataValue.
    // parsedDataValue field values distinguish between undefined and null
    const len =  fieldDefinition?.dtp_available?.length ?? 3;

    const value = form?.getFieldsValue(['isUnknown', 'year', 'month', 'day' ]
                                           .slice(0, len + 1));
    return isValueChanged(value);
  };

  const getAggregatedErrors = fieldName => {
    const fieldMap = {
      'year': [ 'year', ],
      'month': [ 'year', 'month', ],
      // and, for completeness:
      'day': [ 'year', 'month', 'day', ],
    };

    if(!fieldName) {

      return getAggregatedErrors('day');

    }
    return form.getFieldsError(fieldMap[fieldName]).flatMap(f => f.errors);

  };

  const isAggregatedErrors = fieldName =>
    getAggregatedErrors(fieldName).length > 0;

  const isFieldRequired = fieldName => {

    if(!('dtp_required' in fieldDefinition)) {
      return false;
    }
    if(isUnknown) {
      return false;
    }
    return !isFormValueNull()
           && fieldDefinition.dtp_required.includes( fieldName );

  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required',
    types: {
      number: '${label} is not a valid number',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  return (
    <HistoryField
      className='DatePartialField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={ onBlur }
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isFormValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          userData,

          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          protocolData,
          subjectLogProcedureData,

          procedureQueryItems,
          fieldReviewItems,
          isQueryDisplayVisible,
          isActive,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
        }}/>)}
    >

      <Form {...{
        form,
        initialValues: parsedDataValue,
        onFinish:  values  => saveData( values ),
        onFinishFailed: () => form.validateFields(),
        validateMessages,
        onValuesChange: setNullBelowNullField,
      }}>

        {isAggregatedErrors() && !isFormValueNull() && (
          <Alert
            className='Alert'
            message={
              <ul>
                {
                  getAggregatedErrors().map( (e, i) => (
                    <li key={i}>{e}</li>))
                }
              </ul>
            }
            type='error'
          />
        )}

        <Space className='DatePartialFieldForm'
          name='data-element'
          data-label={fieldDefinition.text}
          data-value={form?.getFieldsValue( true )}
          data-access='modify'
        >
          { (fieldDefinition?.dtp_unknown ?? true ) && (

            <Form.Item
              name='isUnknown'
              className='is-unknown-control'
              valuePropName='checked'
            >
              <Checkbox
                onChange = { e => {
                  const newIsUnknown = e.target.checked;
                  setIsUnknown(() => newIsUnknown);
                }}
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }
              >
                Unknown
              </Checkbox>
            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('year') ?? true ) && (

            <Form.Item
              name='year'
              label='Year'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('year'),
                type: 'number',
                min: 1900,
                max: maxYear,
              }]}
            >
              <InputNumber
                className='year-control'
                type='number'
                placeholder='Year'
                disabled={isUnknown}
                onChange = { newValue => {
                  const isAllowFutureDate = fieldDefinition.futuredate ?? false;
                  if(!isAllowFutureDate
                     && newValue === today.getFullYear()){
                    setMaxMonth(() => today.getMonth() + 1);
                  }

                  if(!isAllowFutureDate
                    && newValue < today.getFullYear()
                    && maxMonth < 12) {
                    setMaxMonth( () => 12 );
                  }

                }}
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                controls={false}
                precision={0}
              />
            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('month') ?? true ) && (

            <Form.Item
              name='month'
              label='Month'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('month'),
                type: 'number',
                min: 1,
                max: maxMonth,
              }]}
            >
              <Select
                className='month-control'
                placeholder='Month'
                disabled={ isUnknown
                     || isAggregatedErrors('year')
                     || !Number.isFinite(form.getFieldValue('year')) }
                allowClear={ true }
                onChange = { newValue => {

                  const isAllowFutureDate = fieldDefinition.futuredate ?? false;
                  const year = form.getFieldValue('year');
                  const newDaysInMonth = moment({
                    year,
                    month: newValue - 1,
                  }).daysInMonth();

                  const newMaxDay = isAllowFutureDate
                    ? newDaysInMonth
                    : year === today.getFullYear()
                      && newValue - 1 === today.getMonth()
                        ? today.getDate()
                        : newDaysInMonth;
                  if(newMaxDay !== maxDay) {
                    setMaxDay( newMaxDay );
                  }

                }}
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }
                options={[
                  { value:  1, label: 'January' },
                  { value:  2, label: 'February' },
                  { value:  3, label: 'March' },
                  { value:  4, label: 'April' },
                  { value:  5, label: 'May' },
                  { value:  6, label: 'June' },
                  { value:  7, label: 'July' },
                  { value:  8, label: 'August' },
                  { value:  9, label: 'September' },
                  { value: 10, label: 'October' },
                  { value: 11, label: 'November' },
                  { value: 12, label: 'December' },
                ]}
              />
            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('day') ?? true ) && (

            <Form.Item
              name='day'
              label='Day'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('day'),
                type: 'number',
                min: 1,
                max: maxDay,

              }]}
            >
              <InputNumber
                className='day-control'
                type='number'
                placeholder='Day'
                disabled={ isUnknown
                     || isAggregatedErrors('month')
                     || !Number.isFinite(form.getFieldValue('month')) }
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                controls={false}
                precision={0}

                formatter={ stringOrNumberZeroPaddingFormatter }
                parser={ s => s === '' ? null : parseInt(s) }

              />
            </Form.Item>

          )}
        </Space>
      </Form>
    </HistoryField>
  );
};

const DatetimePartialField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const isPointerInFieldBox = useRef( false );
  const [ isSomeInputHasFocus, setIsSomeInputHasFocus ] = useState( false );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =
                                                              useState(false);
  const [ isActive, setIsActive ] = useState(false);

  const [ today, setToday ] = useState( new Date() );
  const [ isUnknown, setIsUnknown ] = useState(false);
  const [ maxYear, setMaxYear ] = useState( 2100 );
  const [ maxMonth, setMaxMonth ] = useState( 12 );
  const [ maxDay, setMaxDay ] = useState( 31 );

  const [ form ] = Form.useForm();

  const setIsPointerInFieldBox = v => {
    isPointerInFieldBox.current = v;
  };

  const parsedDataValue = useMemo(() => {

    const len =  fieldDefinition?.dtp_available?.length ?? 6;
    const [ year, month, day,
            hour, minute, second ] = (fieldData?.data?.[0]
                                   ?.value?.split('-')
                                   ?.map(e => e === 'UNK' ? null
                                                          : parseInt(e))
                                   ?? Array(len).fill( null ))
                                   .slice(0, len);
    const unknownValue = Array(len).fill('UNK').join('-');

    return {
      isUnknown: fieldData?.data?.[0]?.value === unknownValue,
      year,
      month,
      day,
      hour,
      minute,
      second,
    };

  }, [ fieldData?.data, fieldDefinition?.dtp_available?.length ]);

  const resetControlConstraints = useCallback(() => {

    setMaxYear(() => 2100);
    setMaxMonth(() => 12);
    setMaxDay(() => 31);

    if(parsedDataValue.isUnknown) {

      setIsUnknown(() => true);

      return;
    }

    setIsUnknown(() => false);

    const now = new Date();
    setToday( () => now );

    const isAllowFutureDate = fieldDefinition.futuredate ?? false;

    if(Number.isFinite(parsedDataValue.year)
       && Number.isFinite(parsedDataValue.month)) {
        setMaxDay(() => moment({
                year: parsedDataValue.year,
                month: parsedDataValue.month - 1,
              }).daysInMonth());
    }

    if(!isAllowFutureDate) {

      setMaxYear(() => now.getFullYear());
      if(parsedDataValue.year === now.getFullYear()){

        setMaxMonth(() => now.getMonth() + 1);
        if(parsedDataValue.month === now.getMonth() + 1) {
          setMaxDay(() => now.getDate());
        }

      } // parsedDataValue.year === now.getFullYear()

    } // !isAllowFutureDate

  }, [ parsedDataValue, fieldDefinition.futuredate ]);

  useEffect(() => {

    resetControlConstraints();

  }, [ resetControlConstraints ]);

  useEffect(() => {

    const len =  fieldDefinition?.dtp_available?.length ?? 6;
    const unknownValue = Array(len).fill('UNK').join('-');
    setIsUnknown(() => fieldData?.data?.[0]?.value === unknownValue);

  }, [ fieldData?.data, fieldDefinition?.dtp_available?.length ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    resetControlConstraints();
    form.resetFields();

  }, [ form, isResetDisplayValue, resetControlConstraints ]);

  const isValueChanged = useCallback(value => {

    const keys = Object.keys(parsedDataValue);
    for (const k of keys) {
      if(parsedDataValue[k] !== value[k]){
        return true;
      }
    }

    return false;

  }, [ parsedDataValue ]);

  const saveData = useCallback(value => {

    if(!isValueChanged( value )) {
      return;
    }
    const len =  fieldDefinition?.dtp_available?.length ?? 6;

    if((fieldDefinition?.dtp_unknown ?? true ) && value.isUnknown) {
      const unknownValue = Array(len).fill('UNK').join('-');
      handleFieldValueChange(unknownValue);
      return;
    }

    if(!value.year) {
      handleFieldValueChange(null);
      return;
    }

    const dataValues = [
      value.year,
      value.month,
      value.day,
      value.hour,
      value.minute,
      value.second,
    ].map(e => e ?? 'UNK')
     .slice(0, len);

    handleFieldValueChange(dataValues.join('-'));

  }, [
    fieldDefinition?.dtp_available?.length,
    fieldDefinition?.dtp_unknown,
    isValueChanged,
    handleFieldValueChange,
  ]);

  const onPointerEnter = e => {
    setIsActive( () => !isQueryDisplayVisible );
    setIsPointerInFieldBox( true );
  };

  const onPointerLeave = e => {

    setIsActive(false);
    setIsPointerInFieldBox( false );

    if(isSomeInputHasFocus) {
      return;
    }

    if(! form?.isFieldsTouched([ 'isUnknown', 'year', 'month', 'day',
                                         'hour', 'minute', 'second'] )) {
      return;
    }

    form.submit();

  };

  const onBlur = () => {

    if(isPointerInFieldBox.current) {
      return;
    }

    if(! form?.isFieldsTouched([ 'isUnknown', 'year', 'month', 'day',
                                         'hour', 'minute', 'second'] )) {
      return;
    }

    form.submit();

  };

  const onInputFocus = e => {
    setIsSomeInputHasFocus(() => true);
  };

  const onInputBlur = e => {
    setIsSomeInputHasFocus(() => false);
  };

  const isFormValueChanged = () => {

    // isValueChanged compares value against parsedDataValue.
    // parsedDataValue field values distinguish between undefined and null
    const len =  fieldDefinition?.dtp_available?.length ?? 6;
    const value = form?.getFieldsValue([ 'isUnknown', 'year', 'month', 'day',
                                         'hour', 'minute', 'second']
                                           .slice(0, len + 1));
    return isValueChanged(value);
  };

  const setNullBelowNullField = (changedValues, allValues) => {

    const units = [
      'year', 'month', 'day',
      'hour', 'minute', 'second',
    ];

    const firstNullUnitIndex = allValues['isUnknown']
      ? 0
      : units.findIndex(e => !Number.isFinite(allValues[e]));

    const correctedValues = JSON.parse(JSON.stringify( allValues ));
    if(firstNullUnitIndex > -1) {
      for (const unit of units.slice(firstNullUnitIndex)) {
        correctedValues[unit] = null;
      }
    }

    form.setFieldsValue( correctedValues );

  };

  const getAggregatedErrors = fieldName => {
    const fieldMap = {
      'year': [ 'year', ],
      'month': [ 'year', 'month', ],
      'day': [ 'year', 'month', 'day', ],
      'hour': [ 'year', 'month', 'day', 'hour', ],
      'minute': [ 'year', 'month', 'day', 'hour', 'minute', ],
      // and, for completeness:
      'second': [ 'year', 'month', 'day', 'hour', 'minute', 'second' ],
    };

    if(!fieldName) {

      return getAggregatedErrors('second');

    }
    return form.getFieldsError(fieldMap[fieldName]).flatMap(f => f.errors);

  };

  const isFormValueNull = () => {

    const value = form?.getFieldsValue([ 'isUnknown', 'year', 'month', 'day',
                                         'hour', 'minute', 'second'] );

    return !value.isUnknown
        && value.year === null
        && value.month === null
        && value.day === null
        && value.hour === null
        && value.minute === null
        && value.second === null;

  };

  const isAggregatedErrors = fieldName =>
    getAggregatedErrors(fieldName).length > 0;

  const isFieldRequired = fieldName => {

    if(!('dtp_required' in fieldDefinition)) {
      return false;
    }
    if(isUnknown) {
      return false;
    }

    return !isFormValueNull()
      && fieldDefinition.dtp_required.includes( fieldName );

  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required',
    types: {
      number: '${label} is not a valid number',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  return (
    <HistoryField
      className='DatetimePartialField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={ onBlur }
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isFormValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          userData,

          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          protocolData,
          subjectLogProcedureData,

          procedureQueryItems,
          fieldReviewItems,
          isQueryDisplayVisible,
          isActive,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
        }}/>)}
    >

      <Form {...{
        form,
        initialValues: parsedDataValue,
        onFinish:  values  => saveData( values ),
        onFinishFailed: () => form.validateFields(),
        validateMessages,
        onValuesChange: setNullBelowNullField,
      }}>

        {isAggregatedErrors()
         && !isFormValueNull()
         && (
          <Alert
            className='Alert'
            message={
              <ul>
                {
                  getAggregatedErrors().map( (e, i) => (
                    <li key={i}>{e}</li>))
                }
              </ul>
            }
            type='error'
          />
        )}

        <Space className='DatetimePartialFieldForm'
          name='data-element'
          data-label={fieldDefinition.text}
          data-value={form?.getFieldsValue( true )}
          data-access='modify'
        >
          { (fieldDefinition?.dtp_unknown ?? true ) && (

            <Form.Item
              name='isUnknown'
              className='is-unknown-control'
              valuePropName='checked'
            >
              <Checkbox
                onChange = { e => {
                  const newIsUnknown = e.target.checked;
                  setIsUnknown(() => newIsUnknown);
                }}
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }
              >
                Unknown
              </Checkbox>
            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('year') ?? true ) && (

            <Form.Item
              name='year'
              label='Year'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('year'),
                type: 'number',
                min: 1900,
                max: maxYear,
              }]}
            >
              <InputNumber
                className='year-control'
                type='number'
                placeholder='Year'
                disabled={isUnknown}
                onChange = { newValue => {
                  const isAllowFutureDate = fieldDefinition.futuredate ?? false;
                  if(!isAllowFutureDate
                     && newValue === today.getFullYear()){
                    setMaxMonth(() => today.getMonth() + 1);
                  }

                  if(!isAllowFutureDate
                    && newValue < today.getFullYear()
                    && maxMonth < 12) {
                    setMaxMonth( () => 12 );
                  }

                }}

                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                controls={false}
                precision={0}
              />

            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('month') ?? true ) && (

            <Form.Item
              name='month'
              label='Month'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('month'),
                type: 'number',
                min: 1,
                max: maxMonth,
              }]}
            >
              <Select
                className='month-control'
                placeholder='Month'
                disabled={ isUnknown
                     || isAggregatedErrors('year')
                     || !Number.isFinite(form.getFieldValue('year')) }
                allowClear={ true }
                onChange = { newValue => {

                  const isAllowFutureDate = fieldDefinition.futuredate ?? false;
                  const year = form.getFieldValue('year');
                  const newDaysInMonth = moment({
                    year,
                    month: newValue - 1,
                  }).daysInMonth();

                  const newMaxDay = isAllowFutureDate
                    ? newDaysInMonth
                    : year === today.getFullYear()
                      && newValue - 1 === today.getMonth()
                        ? today.getDate()
                        : newDaysInMonth;
                  if(newMaxDay !== maxDay) {
                    setMaxDay( newMaxDay );
                  }

                }}
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }
                options={[
                  { value:  1, label: 'January' },
                  { value:  2, label: 'February' },
                  { value:  3, label: 'March' },
                  { value:  4, label: 'April' },
                  { value:  5, label: 'May' },
                  { value:  6, label: 'June' },
                  { value:  7, label: 'July' },
                  { value:  8, label: 'August' },
                  { value:  9, label: 'September' },
                  { value: 10, label: 'October' },
                  { value: 11, label: 'November' },
                  { value: 12, label: 'December' },
                ]}
              />
            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('day') ?? true ) && (

            <Form.Item
              name='day'
              label='Day'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('day'),
                type: 'number',
                min: 1,
                max: maxDay,
              }]}
            >
              <InputNumber
                className='day-control'
                placeholder='Day'
                type='number'
                disabled={ isUnknown
                     || isAggregatedErrors('month')
                     || !Number.isFinite(form.getFieldValue('month')) }
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                controls={false}
                precision={0}

                formatter={ stringOrNumberZeroPaddingFormatter }
                parser={ s => s === '' ? null : parseInt(s) }

              />
            </Form.Item>

          )}

          { ( fieldDefinition?.dtp_available?.includes('hour') ?? true ) && <>

            <Divider type='vertical' />

            <Form.Item
              name='hour'
              label='Hour'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('hour'),
                type: 'number',
                min: 0,
                max: 23,
              }]}
            >
              <InputNumber

                className='hour-control'
                placeholder='Hour (0-23)'
                type='number'
                disabled={ isUnknown
                     || isAggregatedErrors('day')
                     || !Number.isFinite(form.getFieldValue('day')) }
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                controls={false}
                precision={0}

                formatter={ stringOrNumberZeroPaddingFormatter }
                parser={ s => s === '' ? null : parseInt(s) }

              />
            </Form.Item>

          </>}

          { ( fieldDefinition?.dtp_available?.includes('minutes') ?? true ) && <>

            :

            <Form.Item
              name='minute'
              label='Minute'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('minutes'), // plural by convention
                type: 'number',
                min: 0,
                max: 59,
              }]}
            >
              <InputNumber
                className='minute-control'
                placeholder='Minute'
                type='number'
                disabled={ isUnknown
                     || isAggregatedErrors('hour')
                     || !Number.isFinite(form.getFieldValue('hour')) }
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                controls={false}
                precision={0}

                formatter={ stringOrNumberZeroPaddingFormatter }
                parser={ s => s === '' ? null : parseInt(s) }

              />
            </Form.Item>

          </>}

          { ( fieldDefinition?.dtp_available?.includes('seconds') ?? true ) && <>

            :

            <Form.Item
              name='second'
              label='Second'
              labelCol={ { span: 0 } }
              rules={[{
                required: isFieldRequired('seconds'), // plural by convention
                type: 'number',
                min: 0,
                max: 59,
              }]}
            >
              <InputNumber
                className='second-control'
                placeholder='Second'
                type='number'
                disabled={ isUnknown
                     || isAggregatedErrors('minute')
                     || !Number.isFinite(form.getFieldValue('minute')) }
                onBlur={ onInputBlur }
                onFocus={ onInputFocus }

                min={0}
                max={59}
                controls={false}
                precision={0}

                formatter={ stringOrNumberZeroPaddingFormatter }
                parser={ s => s === '' ? null : parseInt(s) }

              />
            </Form.Item>

          </>}

        </Space>
      </Form>
    </HistoryField>
  );
};

const TimeField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( null );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState( false );

  useEffect(() => {

    setValue(() =>
      fieldData?.data?.[0]?.value
        ? adjustDateTimeWithOffset(
            fieldData?.data?.[0]?.value,
            fieldData?.data?.[0]?.offset )
        : null);

  }, [ fieldData?.data ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue(() =>
      fieldData?.data?.[0]?.value ? moment( fieldData?.data?.[0]?.value )
                                  : null);

  }, [ fieldData?.data, isResetDisplayValue ]);

  const isValueChanged = () => {
    const v = value ? value : null;
    const v0 = fieldData?.data?.[0]?.value
               ? adjustDateTimeWithOffset(
                   fieldData?.data?.[0]?.value,
                   fieldData?.data?.[0]?.offset )
               : null;

    if(v === null) {
      return v0 !== null;
    }

    if(v0 === null) {
      return true;
    }

    return !v.isSame(v0);
  };

  const timeFormat = fieldDefinition.seconds ? 'HH:mm:ss': 'HH:mm';
  return (
    <HistoryField
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      timeFormat={timeFormat}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onBlur={e => {

        if( value?.format( timeFormat ) === e.target.value ) {
          handleFieldValueChange(value?.valueOf() ?? null);
          return;
        }

        // manual edit in input box
        const targetValueAsMoment = moment( e?.target?.value, timeFormat, true);
        const fieldValue = targetValueAsMoment?.isValid()
                       ? targetValueAsMoment?.valueOf()
                       : value?.valueOf() ?? null;
        handleFieldValueChange(fieldValue);


        }
      }
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      onPointerEnter={ () => setIsActive(!isQueryDisplayVisible && true)}
      onPointerLeave={ () => setIsActive(false)}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,
        }}/>)}
    >
      <TimePicker
        value={ value }
        onChange={(time, timeString) => {
          setValue(() => time ?? null);
          if(time === null ){
            handleFieldValueChange(null);
          }
        }}
        onSelect={time => {
          setValue(() => time ?? null);
          if(time === null ){
            // clear button side-steps onBlur event
            handleFieldValueChange(null);
          }
        }}

        onBlur={ e => {
          if( value?.format( timeFormat ) === e.target.value ) {
            return;
          }
          const targetValueAsMoment = moment(
                                        e?.target?.value, timeFormat, true);
          if(!targetValueAsMoment?.isValid()) {
            return;
          }
          // set the yellow change indication
          setValue( () => targetValueAsMoment );
        }}

        format={timeFormat}
        minuteStep={5}
        allowClear={true}

        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'

      />
    </HistoryField>
    );

};

const PickOneShortListField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    value,
    setValue,

    onPointerEnter,
    onPointerLeave,

    onBlur,
    onInputFocus,
    onInputBlur,

    procedureQueryItems,
    fieldReviewItems,

    isQueryDisplayVisible,
    setIsQueryDisplayVisible,
    userData,
    protocolData,
    subjectLogProcedureData,
    context,

    isFieldValueChanged,
    isActive,
  } = props;

  const onSelectionChange = e => {

    setValue(() => {

      const isOptionHasOther = !!fieldDefinition.other
                            && fieldDefinition.other.includes(e.target.value);
      if(isOptionHasOther) {
        return { value: e.target.value, other: '' };
      }

      return { value: e.target.value };

    });

  };

  const onTextChange = e => {
    setValue(oldValue => ({ value: oldValue.value, other: e.target.value}));
  };

  const other = fieldDefinition?.other ?? [];

  const options = Object.entries(fieldDefinition.values).map(([id, label]) => {

    const radio = (
      <Radio
        key={id}
        className={ other.includes(id) ? '' : 'pick-one-item' }
        value={id}
        onBlur={ onInputBlur }
        onFocus={ onInputFocus }
      >
        {label}
      </Radio>
    );

    if(other.includes(id)) {

      return (
        <Space
          key={`radio-and-text-${id}`}
          className='pick-one-item'
        >
          <Radio
            key={id}
            className={ other.includes(id) ? '' : 'pick-one-item' }
            value={id}
            onBlur={ onInputBlur }
            onFocus={ onInputFocus }
          >
            {label}
            <TextAreaField
              disabled={ value?.value !== id }
              charLimit={ fieldDefinition.character_limit }
              placeholder={ value?.value === id ? 'Specify' : '' }
              value={ value?.value === id ? value?.other : null }
              onBlur={ onInputBlur }
              onChange={ onTextChange }
              onFocus={ onInputFocus }
            />
          </Radio>
        </Space>
      );
    }

    return radio;

  });

  return (
    <HistoryField
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
      onBlur={ onBlur }
      isFieldValueChanged={isFieldValueChanged}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,
        }}/>)}

    >
      <Radio.Group
        value={value?.value}
        onChange={ onSelectionChange }

        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'
      >
        { options }
      </Radio.Group>
      <div className='clear-button-box' >
        <Button
          className='clear-button'
          onClick={() => setValue(() => null)}
        >
          Clear
        </Button>
      </div>
    </HistoryField>
  );
};

const PickOneLongListField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    value,
    setValue,

    onPointerEnter,
    onPointerLeave,

    onBlur,
    onInputFocus,
    onInputBlur,

    procedureQueryItems,
    fieldReviewItems,

    isQueryDisplayVisible,
    setIsQueryDisplayVisible,
    userData,
    protocolData,
    subjectLogProcedureData,
    context,

    isFieldValueChanged,
    isActive,
  } = props;

  const other = fieldDefinition?.other ?? [];

  const onSelectionChange = newId => {

    setValue(() => {

      if( newId === undefined) {
        return null;
      }

      const isOptionHasOther = other.includes(newId);
      if(isOptionHasOther) {
        return { value: newId, other: '' };
      }

      return { value: newId };

    });

  };

  const onTextChange = e => {
    setValue(oldValue => ({ value: oldValue.value, other: e.target.value}));
  };

  const options = Object.entries(fieldDefinition.values).map(([k, v]) => ({
    value: k,
    label: v
  }));

  return (
    <HistoryField
      className='PickOneLongListField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
      onBlur={ onBlur }
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isFieldValueChanged}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,
        }}/>)}
    >
      <Space
        name='data-element'
        className='pick-one-long-list-field-container'

        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'
      >
        <Select
          options={options}
          onChange={ onSelectionChange }
          value={ value?.value }
          onBlur={ onInputBlur }
          onFocus={ onInputFocus }
          allowClear={true}
          placeholder='Please select one'
        />
        { other.length > 0 && (
          <TextAreaField
            disabled={ ! other.includes( value?.value ) }
            charLimit={ fieldDefinition.character_limit }
            placeholder={ other.includes( value?.value ) ? 'Specify' : '' }
            value={ value?.other }
            onBlur={ onInputBlur }
            onChange={ onTextChange }
            onFocus={ onInputFocus }
          />
        )}
      </Space>
    </HistoryField>
    );

};

const PickOneField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    procedureQueryItems,
    fieldReviewItems,

    handleFieldValueChange,
    isResetDisplayValue,
    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( null );
  const isPointerInFieldBox = useRef( false );
  const [ isSomeInputHasFocus, setIsSomeInputHasFocus ] = useState( false );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =  useState(false);
  const [ isActive, setIsActive ] = useState(false);

  const setIsPointerInFieldBox = v => {
    isPointerInFieldBox.current = v;
  };

  const parsedDataValue = useMemo(() => {

    return fieldData?.data?.[0]?.value
      ? JSON.parse(fieldData?.data?.[0]?.value)
      : null;

  }, [ fieldData?.data ]);

  useEffect(() => {

    // initialize value

    setValue( () => parsedDataValue );

  }, [ parsedDataValue ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue( () => parsedDataValue );

  }, [ parsedDataValue, isResetDisplayValue ]);

  const isValueChanged = () => {

    return parsedDataValue?.value !== value?.value
        || parsedDataValue?.other !== value?.other
  };

  const saveData = () => {

    if( !isValueChanged() ) {
      // no change
      return;
    }

    const newDataValue = value
                           ? JSON.stringify( value )
                           : undefined;
    handleFieldValueChange(newDataValue);

  };

  const onPointerEnter = e => {
    setIsActive(!isQueryDisplayVisible && true);
    setIsPointerInFieldBox( true );
  };

  const onPointerLeave = e => {

    setIsActive(false);
    setIsPointerInFieldBox( false );

    if(isSomeInputHasFocus) {
      return;
    }

    saveData();

  };

  const onBlur = () => {

    if(isPointerInFieldBox.current) {
      return;
    }

    saveData();

  };

  const onInputFocus = e => {
    setIsSomeInputHasFocus(() => true);
  };

  const onInputBlur = e => {
    setIsSomeInputHasFocus(() => false);
  };

  const numItems = Object.keys(fieldDefinition.values).length;
  const MAX_SHORT = 5;

  if(numItems <= MAX_SHORT) {
    return (
      <PickOneShortListField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}

        value={value}
        setValue={setValue}

        onPointerEnter={ onPointerEnter }
        onPointerLeave={ onPointerLeave }

        onBlur={ onBlur }
        onInputFocus={ onInputFocus }
        onInputBlur={ onInputBlur }

        isQueryDisplayVisible={isQueryDisplayVisible}
        setIsQueryDisplayVisible={setIsQueryDisplayVisible}
        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={subjectLogProcedureData}
        context={context}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        isFieldValueChanged={isValueChanged()}
        isActive={isActive}
      />
    );

  }

  return (
    <PickOneLongListField
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}

      value={value}
      setValue={setValue}

      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }

      onBlur={ onBlur }
      onInputFocus={ onInputFocus }
      onInputBlur={ onInputBlur }

      isQueryDisplayVisible={isQueryDisplayVisible}
      setIsQueryDisplayVisible={setIsQueryDisplayVisible}
      userData={userData}
      protocolData={protocolData}
      subjectLogProcedureData={subjectLogProcedureData}
      context={context}
      procedureQueryItems={procedureQueryItems}
      fieldReviewItems={fieldReviewItems}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
    />
  );

};

const PickManyField = props => {

  const {
    fieldDefinition,
    fieldData,

    protocolVersionId,
    siteInfo,

    handleFieldValueChange,
    isResetDisplayValue,

    procedureQueryItems,
    fieldReviewItems,

    userData,
    protocolData,
    subjectLogProcedureData,
    context,

  } = props;

  const [ value, setValue ] = useState( [] );
  const isPointerInFieldBox = useRef( false );
  const [ isSomeInputHasFocus, setIsSomeInputHasFocus ] = useState( false );
  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] = useState(false);
  const [ isActive, setIsActive ] = useState(false);

  const setIsPointerInFieldBox = v => {
    isPointerInFieldBox.current = v;
  };

  const parsedDataValue = useMemo(() => {

    const dataValueItems = fieldData?.data?.[0]?.value
                    ?.split(';')
                    ?.map(f => JSON.parse(f) )

    if( !dataValueItems ) {
      return [];
    }

    return dataValueItems.filter(item => Boolean(item.value));

  }, [ fieldData?.data ]);

  useEffect(() => {
    setValue( oldValue => parsedDataValue ?? oldValue );
  }, [ parsedDataValue ]);

  useEffect(() => {

    // reset value

    if( !isResetDisplayValue ) {
      return;
    }

    setValue( () => parsedDataValue );

  }, [ parsedDataValue, isResetDisplayValue ]);

  const isRecordsAreEqual = (a0, b0) => {

    const a = a0 ?? [];
    const b = b0 ?? [];

    // same length
    if(a.length !== b.length) {
      return false;
    }

    // each original item is present and equal
    for( const ka in a ) {

      if(!(ka in b)){
        return false;
      }

      const va = a[ka];
      const vb = b[ka];

      if(
             va?.value !== vb?.value
          || va?.other !== vb?.other

        ) {
        // change
        return false;
      }

    }

    return true;

  } // isRecordsAreEqual

  const saveData = () => {

    if( isRecordsAreEqual( value, parsedDataValue )) {
      return;
    }

    const newDataValue = value.length === 0
      ? undefined
      : value.map(i => JSON.stringify( i )).join(';');

    handleFieldValueChange(newDataValue);

  };

  const onPointerEnter = e => {
    setIsActive(!isQueryDisplayVisible && true);
    setIsPointerInFieldBox( true );
  };

  const onPointerLeave = e => {

    setIsActive(false);
    setIsPointerInFieldBox( false );

    if(isSomeInputHasFocus) {
      return;
    }

    saveData();

  };

  const onBlur = () => {

    if(isPointerInFieldBox.current) {
      return;
    }

    saveData();

  };

  const onInputFocus = e => {
    setIsSomeInputHasFocus(() => true);
  };

  const onInputBlur = e => {
    setIsSomeInputHasFocus(() => false);
  };

  const onCheckboxGroupChange = checkedValues => {

    const newValue = checkedValues.map(v => {
      const result = { value: v };
      if( Boolean(fieldDefinition.other)
          && fieldDefinition.other.includes(v)
          ) {

        result.other = value?.find(item => item.value === v)?.other ?? '';
      }
      return result;

    });

    setValue( () => newValue );

  };

  const onTextChange = (id, e) => {

    setValue(oldValue => oldValue.filter(item => item.value !== id)
                                 .concat({ value: id, other: e.target.value}));
  };

  const isValueChanged = () => !isRecordsAreEqual( value, parsedDataValue );

  const options = Object.entries(fieldDefinition.values).map(([id, label]) => {

    const otherDefinition = fieldDefinition?.other || [];

    return (
      <Space
        key={`check-and-text-${id}`}
        className='pick-many-item'
      >
        <Checkbox
          key={id}
          className={ otherDefinition.includes(id)
                      ? undefined : 'pick-many-item' }
          value={id}
          onBlur={ onInputBlur }
          onFocus={ onInputFocus }
        >
          { label }
          { otherDefinition.includes(id) && (
            <TextAreaField
              disabled={ !value.some(item => item.value === id) }
              charLimit={ fieldDefinition.character_limit }
              placeholder={ value.some(item => item.value === id) ? 'Specify' : '' }
              value={ value.find(item => item.value === id)?.other }
              onBlur={ onInputBlur }
              onChange={ e => onTextChange(id, e) }
              onFocus={ onInputFocus }
            />
          )}
        </Checkbox>
      </Space>
    );

  });

  return (
    <HistoryField
      className='PickManyField'
      fieldDefinition={fieldDefinition}
      fieldData={fieldData}
      protocolVersionId={protocolVersionId}
      siteInfo={siteInfo}
      onPointerEnter={ onPointerEnter }
      onPointerLeave={ onPointerLeave }
      onBlur={ onBlur }
      isQueryDisplayVisible={isQueryDisplayVisible}
      isFieldValueChanged={isValueChanged()}
      isActive={isActive}
      fieldReviewItems={fieldReviewItems}
      userData={userData}
      menuItems={(<FieldActionsDisplayDrawerControl
        {...{
          context: {
            ...context,
            fieldId: fieldData?.field_instance_id,
          },
          userData,

          protocolData,
          subjectLogProcedureData,

          isQueryDisplayVisible,
          procedureQueryItems,
          fieldReviewItems,
          isSubjectLogRowLocked: false,

          setIsQueryDisplayVisible,
          isActive,
        }}/>)}
    >

      <Checkbox.Group
        className='checkbox-group'
        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='modify'

        value={ value.map( item => item.value ) }
        onChange={onCheckboxGroupChange}
      >
        { options }
      </Checkbox.Group>

    </HistoryField>
    );

};

const getStringReadOnlyContent = ( value, definition ) => {
  return value ?? NOT_ANSWERED;
}

const getIntegerReadOnlyContent = ( value, definition ) =>
  value ?? NOT_ANSWERED;

const getFloatReadOnlyContent = ( value, definition ) =>
  value ?? NOT_ANSWERED;

const toPresentationMonth = monthNumberStringOneBased => {

  // follows
  //   - classic/js/src//visit.page.js field_specfic_data()
  //   - classic/js/src//utility.js getMonthNames()
  const language = navigator.language || navigator.browserLanguage;
  return monthNumberStringOneBased === 'UNK'
          ? 'UNK'
          : new Intl.DateTimeFormat(language, { month: 'short'})
                    .format(new Date( 1970,
                                      parseInt(monthNumberStringOneBased) - 1,
                                      15)) // avoid TZ issues at month boundary
                    .toUpperCase();
};

const getPartialDateReadOnlyContent = ( value, definition ) => {

  if(!value) {
    return NOT_ANSWERED;
  }

  const [ year, month, day ] = value?.split('-');

  return `${year}-${toPresentationMonth(month)}-${ day.padStart( 2, '0' ) }`;

};

const getPartialDatetimeReadOnlyContent = ( value, definition ) => {

  if(!value) {
    return NOT_ANSWERED;
  }

  const len =  definition?.dtp_available?.length ?? 6;
  const [ year, month, day,
          hour, minute, second ] = ( value?.split('-')
                                 ?.map(e => e === 'UNK' ? 'UNK'
                                                        : e)
                                 ?? Array(len).fill( 'UNK' ))
                                 .slice(0, len);

  switch (len) {
    case 6: return `${year}-${ toPresentationMonth(month)
                          }-${ day.padStart( 2, '0' )
                         }, ${ hour.padStart( 2, '0' )
                          }:${ minute.padStart( 2, '0' )
                          }:${ second.padStart( 2, '0' ) }`;
    case 5: return `${year}-${ toPresentationMonth(month)
                          }-${ day.padStart( 2, '0' )
                         }, ${ hour.padStart( 2, '0' )
                          }:${ minute.padStart( 2, '0' ) }`;
    case 4: return `${year}-${ toPresentationMonth(month)
                          }-${ day.padStart( 2, '0' )
                         }, ${ hour.padStart( 2, '0' ) }`;
    case 3: return `${year}-${ toPresentationMonth(month)
                          }-${ day.padStart( 2, '0' ) }`;
    case 2: return `${year}-${ toPresentationMonth(month) }`;
    case 1: return `${year}`;
    default:
      throw new Error( `Found unexpected partial datetime length '${len}'` );
  }

};

const getPickOneReadOnlyContent = ( rawValue, definition ) => {

  const value = rawValue
    ? JSON.parse(rawValue)
    : null;

  if( value === null) {
    return NOT_ANSWERED;
  }

  if(!value.other) {
    return definition?.values?.[value.value];
  }

  return (
    <List
      dataSource={[ value ]}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={definition?.values?.[item.value]}
            description={item?.other}
          />
        </List.Item>
      )}
    >
    </List>
  );



};

const getPickManyReadOnlyContent = ( value, definition ) => {

  const selectedItems = value?.split(';')
                             ?.map(f => JSON.parse(f) );

  return (
    <List
      dataSource={selectedItems}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={definition?.values?.[item.value]}
            description={item?.other}
          />
        </List.Item>
      )}
    >
    </List>
  );

};

const getDateReadOnlyContent = ( value, definition, data ) => {

  if(!value) {
    return NOT_ANSWERED;
  }
  const offset = data.offset / 60000;
  return moment(value).utcOffset(offset).format('L');

};

const getDateTimeReadOnlyContent = ( value, definition, data ) => {

  if(!value) {
    return NOT_ANSWERED;
  }
  const offset = data.offset / 60000;
  return moment(value).utcOffset(offset).format('L, HH:mm:ss');

};

const getTimeReadOnlyContent = ( value, definition, data ) => {

  if(!value) {
    return NOT_ANSWERED;
  }
  const timeFormat = definition.seconds ? 'HH:mm:ss': 'HH:mm';
  const offset = data.offset / 60000;
  return moment(value).utcOffset(offset).format(timeFormat);

};

export const getReadOnlyContent = ( type, value, definition, data ) => {

  const fn = {
    string            : getStringReadOnlyContent,
    integer           : getIntegerReadOnlyContent,
    float             : getFloatReadOnlyContent,
    'date-partial'    : getPartialDateReadOnlyContent,
    'datetime-partial': getPartialDatetimeReadOnlyContent,
    'pick-one'        : getPickOneReadOnlyContent,
    'pick-many'       : getPickManyReadOnlyContent,
    date              : getDateReadOnlyContent,
    datetime          : getDateTimeReadOnlyContent,
    time              : getTimeReadOnlyContent,
  };

  if( !(type in fn )) {
    throw new Error(`Unrecognized field definition type '${type}'`);
  }
  return fn[type]( value, definition, data );
};

const FieldReadOnly = props => {

  const {
    userData,

    context,

    protocolData,
    siteInfo,
    protocolVersionId,
    procedureData,
    fieldData,

    fieldDefinition,

    procedureQueryItems,
    fieldReviewItems,
    isSubjectLogRowLocked,

  } = props;

  const [ value, setValue ] = useState( NOT_ANSWERED );

  const [ isQueryDisplayVisible, setIsQueryDisplayVisible ] =
                                                              useState(false);
  const [ isActive, setIsActive ] = useState( false );
  const [ timeFormat, setTimeFormat ] = useState(null);
  useEffect(() => {

    if('data' in fieldData && ('value' in fieldData.data[0]) === false){
      setValue( () =>  INTENTIONALLY_LEFT_BLANK );
    }

    if('data' in fieldData && 'value' in fieldData.data[0]){
      setValue( () =>  getReadOnlyContent(
                         fieldDefinition.type,
                         fieldData.data[0]?.value,
                         fieldDefinition,
                         fieldData.data[0]
                       )
              );
    }

    if (['time', 'date', 'datetime'].includes(fieldDefinition.type)) {
      switch (fieldDefinition.type) {
        case 'time':
          setTimeFormat(fieldDefinition.seconds ? 'HH:mm:ss': 'HH:mm');
          break;
        case 'date':
          setTimeFormat('L');
          break;
        case 'datetime':
          setTimeFormat('L, HH:mm:ss');
          break;
        default:
          break;
      }
    }

  }, [ fieldData, fieldData?.data, fieldDefinition ]);

  // highlight row if
  // - userRole is monitor (so only applies to read-only fields)
  // - review status is ReviewStatus.ReviewComment or ReviewStatus.Reviewed
  // - field was edited more recently than review status change

  const isEditedAfterReview = args => {

    const {
      userData,
      protocolId,
      reviewData,
    } = args;

    const userRole = protocolUserRoleFromUserData(userData, protocolId);

    if(userRole !== 'monitor') {
      return false;
    }

    // assume reviewData.actions is ordered by time desc
    if( ![ ReviewStatus.ReviewComment, ReviewStatus.Reviewed ]
         .includes( reviewData?.[0]?.actions?.[0]?.action )) {
      return false;
    }

    if( !fieldData?.data?.[0]?.date || !reviewData?.[0]?.actions?.[0]?.time) {
      return false;
    }

    return fieldData.data[0].date - reviewData[0].actions[0].time > 0;

  };


  const isEditedAfterReviewHighlightRequired
          = isEditedAfterReview({
              userData,
              protocolId:  context.protocolId,
              reviewData: procedureData?.review,
            });

  const className = isEditedAfterReviewHighlightRequired
                    ? 'is-edited-after-review'
                    : '';
  return (
    <HistoryField {...{
      className,
      fieldDefinition,
      fieldData,
      timeFormat,
      protocolVersionId,
      siteInfo,
      isQueryDisplayVisible,
      onPointerEnter: () => setIsActive(!isQueryDisplayVisible && true),
      onPointerLeave: () => setIsActive(false),
      isActive,
      fieldReviewItems,
      userData,
      menuItems: (
        <FieldActionsDisplayDrawerControl
          {...{

            context: {
              ...context,
              fieldId: fieldData?.field_instance_id,
            },
            userData,

            protocolData,
            subjectLogProcedureData: procedureData,

            procedureQueryItems,
            fieldReviewItems,
            isQueryDisplayVisible,
            isActive,
            isSubjectLogRowLocked,

            setIsQueryDisplayVisible,
          }}
        />),

    }}>
      <div className='read-only-testing-data-container'
        name='data-element'
        data-label={fieldDefinition.text}
        data-value={value}
        data-access='view'
      >
      { value }
      </div>
    </HistoryField>
  );

};

export const Field = props => {

  const {

    userData,

    context,
    protocolData,
    protocolVersionId,
    siteInfo,
    procedureData,
    fieldData,
    subjectLogProcedureSelection,

    procedureDefinition,

    procedureQueryItems,
    fieldReviewItems,

    isDataSaveInProgress,
    isSubjectLogRowLocked,
    reviewStatus,

    setIsDataSaveInProgress,
    refreshSubjectLogProcedureData,
    calculateNewProcedureState,

  } = props;

  const [ fieldValue, setFieldValue ] = useState( fieldData?.data?.[0]?.value );
  const [ isShowReasonForChangeDialog,
          setIsShowReasonForChangeDialog ] = useState( false );

  const [ isResetDisplayValue,
          setIsResetDisplayValue ] = useState( false );

  let logEntryId = procedureData.evidence.find(
    ev => ev.row === subjectLogProcedureSelection.index).logentry_id;

  const saveData = useCallback( (newValue, reasonForChange) => {

    // saveField() is async
    // to prevent leaking an exception, use .then() rather than await

    const procedureState =
      calculateNewProcedureState( fieldData.field_id, newValue );

    setIsDataSaveInProgress( true );

    const fieldDefinition = fieldDefinitionFromProcedureDefinition(
                              procedureDefinition, fieldData.field_id);

    saveField({
      fieldDefinitionId: fieldData?.field_id,
      reasonForChange,
      supplementaryData: {
        fieldId: fieldData?.field_instance_id,
        value: newValue,
        procedureState,
        dataType: fieldDefinition.type,
        logEntryId: logEntryId,
        validation: fieldData.validation_digest,
      }
    })
    .then( () => refreshSubjectLogProcedureData() )
    .catch( error => {

      // todo: notify user!

      console.error({
        what: 'Field.saveData',
        name: fieldDefinition.text,
        error,
      });

      setIsDataSaveInProgress( false );

    } );
    setIsDataSaveInProgress( false );
  }, [
    calculateNewProcedureState,
    fieldData,
    setIsDataSaveInProgress,
    refreshSubjectLogProcedureData,
    procedureDefinition,
    logEntryId
  ]);

  useEffect(() => {
    setIsShowReasonForChangeDialog(() => false);
  }, [ fieldData ]);

  const handleFieldValueChange = useCallback((newFieldValue) => {
    setFieldValue(newFieldValue);

    if( fieldData?.data?.[0]?.value ===  newFieldValue ) {
      return;
    }

    // prevent repeated field submission
    if( fieldData?.data?.[0]?.value === undefined
        && ( newFieldValue === '' || newFieldValue === null )) {
      return;
    }

    if(isDataSaveInProgress) {
      return;
    }

    if( fieldData?.data?.[0]?.value !== undefined ) {
      setIsShowReasonForChangeDialog(() => true);
      return;
    }

    saveData(newFieldValue, undefined);
  }, [
    fieldData?.data,
    isDataSaveInProgress,
    saveData
  ]);

  useEffect(() => {
    if( !isResetDisplayValue ) {
      return;
    }
    if(fieldData?.data?.[0]?.value !== fieldValue) {
      return;
    }

    setIsResetDisplayValue( () => false );
  }, [
    fieldData?.data,
    fieldValue,
    isResetDisplayValue,
  ]);

  const fieldDefinition = fieldDefinitionFromProcedureDefinition(
    procedureDefinition, fieldData.field_id);

  const userRole = protocolUserRoleFromUserData(userData, context.protocolId);

  const isViewReadOnly = !isOkRoleForDataEntryModify(userRole)
                         || isSubjectLogRowLocked
                         || (![ ReviewStatus.Undefined,
                                ReviewStatus.Open,
                                ReviewStatus.ReviewComment
                              ].includes( reviewStatus )
                             &&
                             !procedureQueryItems.some( queryItem =>
                                 queryItem.status === QueryStatus.Open)
                            );

  if( isViewReadOnly ) {
    return (
      <FieldReadOnly {...{

        userData,

        context,

        protocolData,
        siteInfo,
        protocolVersionId,
        procedureData,
        fieldData,

        fieldDefinition,

        procedureQueryItems,
        fieldReviewItems,
        isSubjectLogRowLocked,

      }}/>
    );
  }

  // assert: can't get to editable fields (below here) if
  // isSubjectLogRowLocked is true

  return (
    <>
    {fieldDefinition.type === 'string' && (
      <StringField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'integer' && (
      <IntegerField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'float' && (
      <FloatField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'date' && (
      <DateField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'date-partial' && (
      <DatePartialField
        fieldDefinition={fieldDefinition}
        fieldData={ fieldData }
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'datetime-partial' && (
      <DatetimePartialField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'datetime' && (
      <DatetimeField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'time' && (
      <TimeField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}
        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'pick-one' && (
      <PickOneField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    {fieldDefinition.type === 'pick-many' && (
      <PickManyField
        fieldDefinition={fieldDefinition}
        fieldData={fieldData}
        protocolVersionId={protocolVersionId}
        siteInfo={siteInfo}
        handleFieldValueChange={handleFieldValueChange}
        isResetDisplayValue={isResetDisplayValue}
        procedureQueryItems={procedureQueryItems}
        fieldReviewItems={fieldReviewItems}

        userData={userData}
        protocolData={protocolData}
        subjectLogProcedureData={procedureData}
        context={context}
      />
      )
    }

    { ![
         'string',
         'integer',
         'float',
         'date',
         'date-partial',
         'datetime-partial',
         'datetime',
         'time',
         'pick-one',
         'pick-many',
       ].includes(fieldDefinition.type) && (

    <div>
      <h1>Unrecognized Field Type!!</h1>
      {fieldDefinition.text}
      : {fieldDefinition.type}
    </div>
    )
    }
    {
      <FieldReasonForChangeDialog
        isShowReasonForChangeDialog={ isShowReasonForChangeDialog }
        fieldDefinition={fieldDefinition}
        fieldValue={ fieldValue }
        fieldData={ fieldData }
        setIsShowReasonForChangeDialog={ setIsShowReasonForChangeDialog }
        setFieldValue={ setFieldValue }
        setIsResetDisplayValue={ setIsResetDisplayValue }
        saveData={ saveData }
      />
    }
    </>
  );

};

