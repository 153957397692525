import { Form } from 'antd';
import { sendAdminApiScopeUpdate, classicCancelAction } from '../utils';
import { ApiScopeSelectionForm } from '../ApiScopeSelectionForm';
import '../ApiScope.scss';

export const ApiScopeEditor = props => {
  const { apiScopeData } = props;
  const {
    api_name: apiName,
    procedures: proceduresUnsorted,
    protocol_id: protocolId,
    protocol_number: protocolNumber,
    api_id: apiId,
    is_edit_allowed: isEditAllowed,
  } = apiScopeData;


  const [ form ] = Form.useForm();
  /**
   follows api_scope_update(
   '37ccd1c4-5609-4e29-863a-30d49c9271bd', // api_id
   'fe1bfd7a-9f87-4722-b793-91ce92447548', // protocol_id
   'PRT543-01' // protocol_number
   );
   */
  const onFinish = values => {
    const {
      'odmexport-procedures': odmExportProcedureSelections,
      odmupdate: odmImportProcedureSelections,
      ...scopeSelections
    } = values;

    sendAdminApiScopeUpdate(
      isEditAllowed,
      protocolId,
      protocolNumber,
      apiId,
      proceduresUnsorted.map( i => i.id),
      scopeSelections,
      odmExportProcedureSelections,
      odmImportProcedureSelections,
    );

  };

  return (
    <div id='ApiScopeEditor'>
      <h3 id='admin-title' >{ apiName } - { isEditAllowed ? 'Edit' : 'View'} API Scope</h3>
      <div className='scope-guidance'>
        Only select API scope for which client needs access.
      </div>
      <ApiScopeSelectionForm {...{
        apiScopeData,
        form,
        onFinish,
        onCancel: () => classicCancelAction(apiScopeData),
      }}/>
    </div>
  );
};
