import { FIELD_DATA_SOURCES, FIELD_DATA_SOURCE_DESCRIPTIONS } from './procedureConstants';
import './DataSourceIndicator.scss';

export const DataSourceIndicator = ({ dataSource, isActive, isSplitView }) => {
  if (!dataSource || isSplitView) {
    return;
  }

  let indicatorComponent;
  switch (dataSource) {
    case FIELD_DATA_SOURCES.IDENTIFIED_BY_FLATIRON:
      indicatorComponent = (
        <DataSourceIcon
          isActive={isActive}
          sourceDescription={FIELD_DATA_SOURCE_DESCRIPTIONS[FIELD_DATA_SOURCES.IDENTIFIED_BY_FLATIRON]}
          activeIconPath='/images/flatiron-icon-active.svg'
          inactiveIconPath='/images/flatiron-icon-inactive.svg'
          alt='Flatiron logo'
        />
      );
      break;
    case FIELD_DATA_SOURCES.EHR:
      indicatorComponent = (
        <DataSourceIcon
          isActive={isActive}
          sourceDescription={FIELD_DATA_SOURCE_DESCRIPTIONS[FIELD_DATA_SOURCES.EHR]}
          activeIconPath='/images/ehr-icon-active.svg'
          inactiveIconPath='/images/ehr-icon-inactive.svg'
          alt='EHR icon'
        />
      );
      break;
    default:
      indicatorComponent = null;
  }

  return (
    <div className='data-source-indicator'>
      {indicatorComponent}
    </div>
  );
};

const DataSourceIcon = ({ isActive, sourceDescription, activeIconPath, inactiveIconPath, alt }) => {
  return (
    <>
      {isActive && (
        <span data-testid='field-data-source-indicator-description'>
          {sourceDescription}
        </span>
      )}
      <img
        data-testid='field-data-source-icon'
        className='field-data-source-icon'
        src={
          isActive
            ? activeIconPath
            : inactiveIconPath
        }
        alt={alt}
      />
    </>
  );
};
