import {DocumentsData} from "./DocumentsData";

export const DocumentsDataContainer = (props) => {
  const {
    portalUtils,
    documentsData,
    resetDocumentsData
  } = props;

  if (!documentsData || !documentsData.data) {
    return null;
  }

  const { protocol_id, protocol_number, branch_name, docs, is_edit_allowed } = documentsData.data;

  const documentsDataComponent = (
    <DocumentsData
      protocolId={protocol_id}
      protocolNumber={protocol_number}
      branchName={branch_name}
      docs={docs}
      isEditAllowed={is_edit_allowed}
    />
  );

  return portalUtils.attachDocumentsDataContent(
    documentsDataComponent,
    documentsData.data,
    resetDocumentsData
  );
}
