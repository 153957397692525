import {
  Badge,
  Space,
} from 'antd';


import './FieldQueries.scss';

const presentationStatus = {
  open: 'Open',
  closed: 'Closed',
  answered: 'Answered',
};

export const DataItemQueryStatus = props => {
  const { queryCounts } = props;

  return (
      <Space className='DataItemQueryStatus'>
        <Badge
          className='open'
          count={queryCounts.open}
          showZero={true}
          title={presentationStatus['open']}
        />
        <Badge
          className='answered'
          count={queryCounts.answered}
          showZero={true}
          title={presentationStatus['answered']}
        />
        <Badge
          className='closed'
          count={queryCounts.closed}
          showZero={true}
          title={presentationStatus['closed']}
        />
      </Space>
  );
};

