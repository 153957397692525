import { useEffect, useState, useCallback } from 'react';

import { Popover } from 'antd';
import { FlagOutlined } from '@ant-design/icons';

import { useDataRequest } from '../hook/useDataRequest';
import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';

import { DataItemQueryStatus } from './FieldQueries';

const SubjectLogDataQueryRowFlag = props => {

  const { portalUtils, context, count, userData } = props;

  const principalQueryRole = findQueryRole(
    protocolUserRoleFromUserData( userData, context.protocolId ));

  if( !principalQueryRole ) {
    return null;
  }

  const countType = {
    'initiator': 'answered',
    'recipient': 'open',
  };

  if(count[countType[principalQueryRole]] <= 0) {
    return null;
  }

  const queryOrQueries = count[countType[principalQueryRole]] > 1
                         ? 'queries' : 'query';

  const title = principalQueryRole === 'initiator'
    ? `Answered data item ${queryOrQueries} available`
    : `Open data item ${queryOrQueries} available`;

  return portalUtils.attachSubjectLogRowQueryFlag( context,
    <Popover
      className='SubjectLogDataQueryRowFlag'
      overlayClassName='SubjectLogDataQueryRowFlagOverlay'
      title={title}
      content={ <DataItemQueryStatus queryCounts={count} />}
    >
      <FlagOutlined />
    </Popover>
  );
};


const SubjectLogDataQueryRowFlags = props => {
  const {
    context,
    portalUtils,
    userData,
  } = props;

  const queryCountRequest = useDataRequest({
    endpoint: 'gui/queryCountList',
    context,
    paging: {
      maxResults: null,
    },
    sort: {
      isAscending: true,
    },
    filter: {},
    config: { enabled: !!context?.procedureId && !context?.visitId, retryOnMount: true },
  });

  if(! queryCountRequest?.data?.items ) {
    return null;
  }

  return (
    <>
      { queryCountRequest.data.items.map( item => (
          <SubjectLogDataQueryRowFlag
            key={item.etag}
            portalUtils={portalUtils}
            context={item.context}
            count={item.count}
            userData={userData}
          />
        ))
      }
    </>
  );


};

export const SubjectLogComponents = props => {

  const { classic, portalUtils } = props;

  const [ userData, setUserData ] = useState( null );
  const [ siteInfo, setSiteInfo ] = useState( null );
  const [ subjectLogProcedureData,
          setSubjectLogProcedureData ] = useState( null );
  
  const handleLeaveSubjectContext = useCallback(() => {

    setSubjectLogProcedureData(() => null);

  }, []);

  useEffect(() => {

    if (!classic) {
      return;
    }
    classic.subscribe('SubjectLogComponents', 'protocolListData', setUserData);
    classic.subscribe('SubjectLogComponents', 'subjectLogProcedureData',
                                               setSubjectLogProcedureData);
    classic.subscribe('SubjectLogComponents', 'enterSiteContext', setSiteInfo);
    classic.subscribe('SubjectLogComponents', 'leaveSubjectContext',
                                             handleLeaveSubjectContext);

  }, [ classic, handleLeaveSubjectContext ]);

  if( !subjectLogProcedureData?.procedure_id ) {
    return null;
  }

  const context = {
    protocolId: siteInfo?.protocol_id,
    //queryCountList ignores protocolVersionName
    siteId: siteInfo?.siteId,
    subjectId: subjectLogProcedureData?.subject_id,
    procedureId: subjectLogProcedureData?.procedure_id,
  };


  return (
    <SubjectLogDataQueryRowFlags
      { ...{
        context,
        portalUtils,
        userData,
      }}
    />
  );


};
