import { useQuery } from 'react-query';
import { useWebSocketRequest } from './useWebSocketRequest';
import { v4 as uuid } from 'uuid';

/*

I found react-query from

  https://tkdodo.eu/blog/using-web-sockets-with-react-query

which is one of 12 blog posts.

The docs for react-query are pretty good,

  https://react-query.tanstack.com/overview

Check 'em out.

*/

const endpointNames = [
  'gui/queryList',
  'gui/queryCountList',
  'gui/queryDownload',
];

export const useDataRequest = ({
  endpoint,
  context,
  paging, // exclusiveStartKey, maxResults
  sort, // field, isAscending
  filter, // field, value
  //pageIndex,
  //pageLength,
  config,
}) => {

  if (!endpointNames.includes(endpoint)) {
    throw new Error(`unrecognized endpoint name '${endpoint}'`);
  }

  const { sendRequest } = useWebSocketRequest();

  const getData = async ({ queryKey, pageParam, ...more }) => {

    const messageId = uuid();

    const response = await sendRequest(
      JSON.stringify({
        messageId,
        name: endpoint,
        context,
        paging,
        sort,
        filter,
      })
    );

    if (!response) {
      return;
    }

    const result = JSON.parse(response);
    if(messageId !== result?.messageId) {
      console.log({
        what: 'useDataRequest.getData[ unexpected message ID]',
        resultMessageId: result.messageId,
        messageId,
        name: endpoint,
        context,
        paging,
        sort,
        filter,
        result,
      });
      throw new Error(`Expected response ID '${messageId}', found '${result.messageId}'`);
    }

    return result;
  };

  const qf = async (...args) => {

/*
    const { queryKey, pageParams, meta, signal } = args[0];

    console.log({
      what: 'qf',
      queryKey, pageParams, meta, signal,
    });
*/
    return getData(endpoint, context, paging, sort, filter);

  }; // qf

  return useQuery(
    [
      endpoint,
      context,
      paging,
      sort,
      filter,
    ],
    qf,
    {
      ...config,
      keepPreviousData: true,
    }
  );
};
