import { useState, useEffect } from 'react';
import { 
  Tabs,
} from 'antd';

import {
  protocolUserRoleFromUserData,
  isOkRoleForDataExport,
  isOkRoleForListingQuery,
  isOkRoleForListingArtifact,
} from '../lib/roleUtils';

import { ProtocolLevelQueryList } from './ProtocolLevelQueryList';

import {
  requestSiteList,
  requestExportList,
  requestArtifactList,
  requestReportList,
  setDashboardContentVisibility,
} from '../legacy/LegacyFacade';

import {
  isEdcFromProtocolDataProtocolId,
} from '../lib/protocolDefinitionUtils';

import './ProtocolNavigation.scss';

export const ProtocolNavigation = props => {

  const {
    userData,
    siteData,
    setProtocolNavigationSelection,
    protocolData,
  } = props;

  const [ protocolContext, setProtocolContext ] = useState({});


  useEffect(() => {
    setProtocolNavigationSelection('sites'); // initial
    return () => setProtocolNavigationSelection( null ); // final
  }, [ setProtocolNavigationSelection ]);

  const getLatestProtocolVersionName = (userData, siteData) => {

    // little function to keep the main scope clear

    if(!userData || !siteData) {
      return;
    }

    const sortedProtocolVersions = JSON.parse(JSON.stringify(
        userData.protocols.find(
          p => p.protocol_id === siteData.protocol_id
        ).branches
      ));
    // date desc
    sortedProtocolVersions.sort((a, b) => b.date_updated - a.date_updated);
    return sortedProtocolVersions[0].name;
  };

  useEffect(() => {
    setProtocolContext({
      protocolId: siteData.protocol_id,
      protocolVersionName: getLatestProtocolVersionName(userData, siteData),
    });
  }, [ siteData, userData ]);

  const userRole = protocolUserRoleFromUserData(userData, siteData.protocol_id);
  const handleSelection = key => {

    switch (key) {

      case 'sites':
        requestSiteList({
          protocolId: siteData.protocol_id,
          protocolNumber: siteData.protocol_number,
        });
        setDashboardContentVisibility(true);
        break;

      case 'exports':
        requestExportList({
          protocolId: siteData.protocol_id,
          protocolNumber: siteData.protocol_number,
        });
        setDashboardContentVisibility(true);
        break;

      case 'queries':
        setDashboardContentVisibility(false);
        break;

      case 'artifacts':
        requestArtifactList({
          protocolId: siteData.protocol_id,
        });
        setDashboardContentVisibility(true);
        break;

      case 'reports':
        requestReportList({
          protocolId: siteData.protocol_id,
        });
        setDashboardContentVisibility(true);
        break;

      default:
        throw new Error(`Unrecognized key '${key}'`);

    }
    // call now that we know key is valid
    setProtocolNavigationSelection(key);

  };

  const items = [];

  items.push({
    label: 'Sites',
    key: 'sites',
  });

  if(isOkRoleForDataExport(userRole)) {
    items.push({
      label: 'Exports',
      key: 'exports',
    });
  }

  if(isEdcFromProtocolDataProtocolId(protocolData, siteData.protocol_id)
        && isOkRoleForListingQuery(userRole)){
    items.push({
      label: 'Queries',
      key: 'queries',
      children: (
        <ProtocolLevelQueryList
          context={ protocolContext }
          userData={userData}
          siteData={siteData}
        />
      ),
    });
  }

  if( isOkRoleForListingArtifact(userRole) ){
    items.push({
      label: 'Artifacts',
      key: 'artifacts',
    });
  }

  if( isOkRoleForListingArtifact(userRole) ){
    items.push({
      label: 'Reports',
      key: 'reports',
    });
  }


  return (
    <Tabs {...{
      id: 'ProtocolNavigation',
      onChange: handleSelection,
      items,
    }}/>
  );
};
