import { useState, useCallback, useEffect, useMemo } from 'react';
import { Steps, Button, Modal, Card, Checkbox, Spin } from 'antd';
import { getCsrf, getApplicationEnvironment } from '../legacy/LegacyFacade';
import { displayNotification } from '../lib/notificationUtils';
import { getSubjects } from '../lib/fetchers/restApiHttpFetchers';

import './ClinicalListingModal.scss';

const { rest: restapiUrl } = getApplicationEnvironment();

const errorHandlerWrapper = async (fn, args) => {
  try {
      await fn.apply(this, Array.isArray(args) ? args : [args]);
  } catch(error) {
      throw error;
  }
};

const aggregateFileChunks = async (reader) => {
  const chunks = [];

  let done;
  let value;

  while (!done) {
    ({ value, done } = await reader.read());
    if (done) {
      return chunks;
    }
    chunks.push(value);
  }
};

const SitesSelector = props => {

  const {
    protocolId,
    siteData,

    selectedSites,
    isAllSitesSelection,

    setSelectedSites,
    setIsAllSitesSelection,
  } = props;

  const sortedSiteRecords = useMemo(() => {

    const result = JSON.parse(JSON.stringify( siteData?.sites ));
    for( const s of result ) {
      if( 'composite_site_name' in s ) {
        continue;
      }
      // add composite_site_name since we rely on it for sort
      s.composite_site_name = `${
        s.given_site_id ?? '' } - ${
        s.site_name ?? '' } - ${
        s.pi_name ?? '' }`;
    }
    result.sort( (a, b) => a.composite_site_name.localeCompare(
                                           b.composite_site_name ));
    return result;

  }, [ siteData ]);

  const onChangeCheckboxGroupSites = siteList => {

    setSelectedSites(siteList);
    setIsAllSitesSelection(siteList.length === sortedSiteRecords.length);
  };

  const onCheckboxAllChangeSites = isChecked => {

    setIsAllSitesSelection(isChecked);
    setSelectedSites(isChecked ? sortedSiteRecords : []);

  };

  if(!sortedSiteRecords) {
    return null;
  }

  const selectedSitesIds = selectedSites.map(r => r.site_id);

  return (
    <Card
      className='SitesSelector'
      title='Select sites'
    >
      <p className='advice'>

        Select only required sites

      </p>

      <Checkbox.Group
        className='options'
        value={ sortedSiteRecords.filter(
          r => selectedSitesIds.includes( r.site_id ))}
        onChange={onChangeCheckboxGroupSites}
      >
        {
          sortedSiteRecords.map(r => (
            <p
              className='option'
              key={r.site_id}
              >
              <Checkbox value={r} >
                { r.composite_site_name }
              </Checkbox>
            </p>
          ))
        }
      </Checkbox.Group>

      <Checkbox
        className='select-all'
        data-clinical-listing-site-check-all={protocolId}
        onChange={e => onCheckboxAllChangeSites(e.target.checked, e)}
        checked={isAllSitesSelection}
      >
        Select All
      </Checkbox>

    </Card>
  );
};

const SubjectsSelector = props => {

  const {
    protocolId,

    selectedSites,
    selectedSubjects,
    isAllSitesSelection,
    isAllSubjectsSelection,

    setSelectedSubjects,
    setIsAllSubjectsSelection,

    setIsDataDownloadInProgress,

    previousSelectedSites,
    setPreviousSelectedSites,
  } = props;

  const [ subjectRecords, setSubjectRecords ] = useState([]);
  const [error, setError] = useState(null);

  if (error) {
    throw error;
  }

  useEffect( () => {
    const fetchSubjects = async ( protocolId, siteIds, isAllSites) => {
      await errorHandlerWrapper(prepareSubjectsForDisplay, [setIsDataDownloadInProgress, siteIds, protocolId, setSubjectRecords]);
    };

    const siteIds = selectedSites.map( r => r.site_id );

    fetchSubjects(protocolId, siteIds, isAllSitesSelection).catch(err => setError(err));

    return () => setSubjectRecords([]); // clean up on detach


  }, [
    isAllSitesSelection,
    selectedSites,
    protocolId,
    setIsDataDownloadInProgress,
  ]);

  useEffect(() => {

    const isSiteSelectionChanged = (a, b) => {
      // assert: a and b are both arrays
      if(a.length !== b.length) {
        return true;
      }

      const aIds = a.map(r => r.site_id);
      const bIds = b.map(r => r.site_id);

      // any a not in b ?
      if( aIds.some(ai => !bIds.includes(ai) )) {
        return true;
      }
      // any b not in a ?
      if( bIds.some(bi => !aIds.includes(bi) )) {
        return true;
      }

      return false;

    }

    if(isSiteSelectionChanged( previousSelectedSites, selectedSites )) {

      setPreviousSelectedSites( selectedSites );

      setSelectedSubjects([]);
      setIsAllSubjectsSelection( false );
    }

  }, [
    selectedSites,
    setIsAllSubjectsSelection,
    setSelectedSubjects,
    previousSelectedSites,
    setPreviousSelectedSites,
  ]);

  const onChangeCheckboxGroupSubjects = subjectList => {

    setSelectedSubjects(subjectList);
    setIsAllSubjectsSelection(subjectList.length === subjectRecords.length);

  };

  const onCheckboxAllChangeSubjects = isChecked => {

    setIsAllSubjectsSelection(isChecked);
    setSelectedSubjects(isChecked ? subjectRecords : []);

  };

  const selectedSubjectsIds = selectedSubjects.map(r => r.subject_id);

  return (
    <Card
      className='SubjectsSelector'
      title='Select subjects'
    >
      <p className='advice'>

        Select only required subjects

      </p>

      <Checkbox.Group
        className='options'
        value={ subjectRecords.filter(
          r => selectedSubjectsIds.includes( r.subject_id ))}
        onChange={onChangeCheckboxGroupSubjects}
      >
        {
          subjectRecords.map(r => (
            <p
              className='option'
              key={r.subject_id}
            >
              <Checkbox
                value={r}
              >
                { r.display_id[0].value }
              </Checkbox>
            </p>
          ))
        }
      </Checkbox.Group>

      <Checkbox
        className='select-all'
        data-clinical-listing-subject-check-all={protocolId}
        onChange={e => onCheckboxAllChangeSubjects(e.target.checked)}
        checked={isAllSubjectsSelection}>
        Select All
      </Checkbox>

    </Card>
  )
};

const SubjectLogsSelector = props => {

  const {
    protocolId,
    protocolData,
    selectedProcedures,
    setSelectedProcedures,

  } = props;

  const [ isWarningActive, setIsWarningActive ] = useState( false );

  const procedures = useMemo( () => {

    const procedureDefinitions = {};

    for (const p of protocolData.protocol_list.flatMap(
      protocolDefinition => protocolDefinition.study.procedure)) {

      procedureDefinitions[p.id] = p; // to dedup from multi versions
    }

    const sortedProcedures = Object.values( procedureDefinitions )
      .filter( p => !('log' in p) || p.log !== 'site' )
      .map( p => ({
        id: p.id,
        name: p.name,
        protocolId,
      }));
    sortedProcedures.sort((a, b) => a.name.localeCompare(b.name));
    return sortedProcedures;

  }, [
    protocolId,
    protocolData.protocol_list,
  ]);

  const handleProcedureSelection = (procedure, addProcedure) => {

    if (selectedProcedures.length >= 10 && addProcedure){
      setIsWarningActive(true);
      return;
    }

    const selectedCheckboxes = [...selectedProcedures];
    const isChecked = selectedCheckboxes.includes(procedure);

    if (!isChecked) {
      selectedCheckboxes.push(procedure);
    } else {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(procedure), 1);
    }

    setSelectedProcedures(selectedCheckboxes);

  };

  const handleCancelCRFWarning = () => {
    setIsWarningActive(false);
  };

  return (
    <Card
      className='SubjectLogsSelector'
      title='Select CRFs'
      >
      <p className='advice'>

        Select no more than 10 CRFs

      </p>
      <div className='options'>
        {procedures.map((procedure, index) => (
          <p
            className='option'
            key={procedure.id}
          >
            <Checkbox
              data-clinical-listing-procedure-check-id={procedure.id}
              checked={selectedProcedures.includes(procedure.id)}
              onChange={(e) => handleProcedureSelection(
                                 procedure.id, e.target.checked)}
            >
              {procedure.name}
            </Checkbox>
          </p>
        ))}
        {(
          <Modal
          data-clinical-listing-procedure-cancel-modal={protocolId}
          visible={ Boolean( isWarningActive ) }
          onCancel={handleCancelCRFWarning}
          cancelText={ 'Done' }
          closable={ false }
          okButtonProps={{ style: { display: 'none' } }}
        >
          <div>
            No more than 10 CRFs are allowed.
          </div>
        </Modal>
        )}
      </div>
    </Card>
  );
};

const ClinicalListingDialog = props => {

  const {

    protocolId,

    protocolData,
    siteData,

    selectedSites,
    selectedSubjects,
    selectedProcedures,
    isAllSitesSelection,
    isAllSubjectsSelection,

    setSelectedSites,
    setIsAllSitesSelection,
    setSelectedSubjects,
    setIsAllSubjectsSelection,
    setSelectedProcedures,

    isDataDownloadInProgress,
    setIsDataDownloadInProgress,
    step,

  } = props;

  // subjectsSelectorPreviousSelectedSites is the SubjectsSelector's
  // idea of the last sites selection it saw. Seems like the Steps component
  // keeps reconstructing its pages, though, so we'll keep this state here
  // instead of putting it in the SubjectsSelector, where it doesn't work as
  // intended
  const [ subjectsSelectorPreviousSelectedSites,
          setSubjectsSelectorPreviousSelectedSites ] = useState( [] );

  const steps = [
    {
      title: 'Select Sites',
      content: (
        <SitesSelector {...{
          protocolId,
          siteData,

          selectedSites,
          isAllSitesSelection,

          setSelectedSites,
          setIsAllSitesSelection,

        }}/>
      ),
    },
    {
      title: 'Select Subjects',
      content: (
        <SubjectsSelector {...{
          protocolId,

          selectedSites,
          selectedSubjects,
          isAllSitesSelection,
          isAllSubjectsSelection,

          setSelectedSubjects,
          setIsAllSubjectsSelection,

          setIsDataDownloadInProgress,

          previousSelectedSites: subjectsSelectorPreviousSelectedSites,
          setPreviousSelectedSites: setSubjectsSelectorPreviousSelectedSites,

        }}/>
      ),
    },
    {
      title: 'Select Forms and Submit',
      content: (
        <SubjectLogsSelector {...{
          protocolId,
          protocolData,
          selectedProcedures,
          setSelectedProcedures,
        }}/>
      ),
    },
  ];

  const items = steps.map(item => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div className='ClinicalListingDialog'>
      <Spin spinning={isDataDownloadInProgress} >
        <Steps
          current={ step }
          items = { items }
        />
          { steps[step].content }
      </Spin>
    </div>
  );
};

export const ClinicalListingModal = props => {

  const {
    siteData,
    userData,
    protocolData,
    displayClinicalListing,
  } = props;

  const [ step, setStep ] = useState(0);
  const [ isModalOpen, setIsModalOpen ] = useState(displayClinicalListing);
  const [isDataDownloadInProgress,
    setIsDataDownloadInProgress] = useState(false);

  const [isFileDownloadInProgress,
    setIsFileDownloadInProgress] = useState(false);

  const [ isRequestConfirmed, setIsRequestConfirmed ] = useState(false);

  const [ selectedSites, setSelectedSites ] = useState([]);
  const [ isAllSitesSelection, setIsAllSitesSelection ] = useState(false);

  const [ selectedSubjects, setSelectedSubjects ] = useState([]);
  const [ isAllSubjectsSelection, setIsAllSubjectsSelection ] = useState(false);

  const [ selectedProcedures, setSelectedProcedures ] = useState([]);
  const [ error, setError ] = useState(false);

  const protocolId = protocolData.protocol_list[0].protocol_id;
  const protocolNumber = userData.protocols.find(
    protocol => protocol.protocol_id === protocolId)?.protocol_number;

  if (error) {
    throw error;
  }

  useEffect(() => {
    setIsModalOpen(displayClinicalListing);
  }, [displayClinicalListing]);

  const generateReportAndDownload = () => {
    setIsRequestConfirmed(true);
  };

  const handleCancel = () => {
    resetModal();
    setIsModalOpen(false);
    turnOffFileDownloadingFlags();
    setIsRequestConfirmed(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const turnOffFileDownloadingFlags = () => {
    setIsFileDownloadInProgress(false);
    setIsDataDownloadInProgress(false);
  };

  const turnOnFileDownloadingFlags = () => {
    setIsFileDownloadInProgress(true);
    setIsDataDownloadInProgress(true);
  };

  const sendDataToClient = useCallback(data => {

    const blob = new Blob(data, { type: 'application/vnd.ms-excel' });
    const objectUrl = window.URL.createObjectURL(blob);


    const fileName = protocolNumber + "-ClinicalListingReport-" + (new Date()).toISOString() + ".xlsx";

    const a = document.createElement('a');
    a.href = objectUrl;
    a.style = 'display: none';
    a.download = fileName;
    document.body.appendChild(a);

    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);

  }, [protocolNumber]);

  const downloadClinicalListingReport = useCallback(async ({
      name,
      protocol_id,
      procedure,
      responseFile,
      siteIds,
      subjectIds,
    }) => {

    const input = {
      route: name,
      protocol_id,
      procedure,
      responseFile,
      sites: siteIds,
      subjects: subjectIds,
    };

    const readableStream = await fetch(restapiUrl + input.route, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'CSRF-Token': getCsrf()
      },
      body: JSON.stringify(input),
      duplex: 'half',
    });

    if(readableStream.status === 409){
      displayNotification('One of your reports is currently generating. Once that completes or after 15 minutes have passed, you may download another report.', 'Warning');
      return;
    }
    if(!readableStream.ok){
      throw new Error(readableStream.status);
    }
    await errorHandlerWrapper(saveStream, [readableStream, setIsDataDownloadInProgress, setIsRequestConfirmed, resetModal, setIsModalOpen, sendDataToClient]);

  }, [sendDataToClient]);

  useEffect(() => {

    const getData = async (
      selectedProcedures, selectedSiteIds, selectedSubjectIds ) => {
        if(!selectedProcedures.length > 0){
          turnOffFileDownloadingFlags();
          return;
        }
      turnOnFileDownloadingFlags();
      await errorHandlerWrapper(downloadClinicalListingReport, {
        name: '/report/clinical',
        protocol_id: protocolId,
        procedure: selectedProcedures,
        responseFile: true,
        siteIds: selectedSiteIds,
        subjectIds: selectedSubjectIds,
      });
      turnOffFileDownloadingFlags();
      setIsRequestConfirmed(false);
    };

    if (!isRequestConfirmed) {
      return;
    }

    const uniqueSiteIdsOfSelectedSubjects = [];

    for( const s of selectedSubjects ) {
      if( uniqueSiteIdsOfSelectedSubjects.includes( s.site_id )) {
        continue;
      }
      uniqueSiteIdsOfSelectedSubjects.push( s.site_id );
    }

    getData(
      selectedProcedures,
      uniqueSiteIdsOfSelectedSubjects,
      isAllSubjectsSelection ? null : selectedSubjects.map(
        r => r.subject_id)
    ).catch(err => setError(err));
  }, [
    protocolId,
    siteData,

    selectedProcedures,
    selectedSites,
    selectedSubjects,
    isAllSitesSelection,
    isAllSubjectsSelection,

    isRequestConfirmed,
    downloadClinicalListingReport,

  ]);

  const handleNext = () => setStep( step + 1 );
  const handlePrevious = () => setStep( step - 1 );
  const resetModal = () => {

    setStep(0);

    setSelectedProcedures([]);
    setSelectedSubjects([]);
    setSelectedSites([]);
    setIsAllSubjectsSelection(false);
    setIsAllSitesSelection(false);
  };

  if( !isModalOpen ) {
    return null;
  }

  if (isFileDownloadInProgress) {
    return <Modal
      className='ClinicalListingModal'
      maskClosable={false}
      footer={[
        <Button
          key="back"
          data-clinical-listing-cancel-button-id={protocolId}
          onClick={handleClose}
        >
          Close
        </Button>,
      ]}
      title={<h2>Clinical listing report is being generated.</h2>}
      open={isFileDownloadInProgress}
      onCancel={handleClose}
    >
      The report is being generated, and will automatically download to your browser when complete.
      You may click “Close” and navigate to other Vessel pages in the meantime, as it will not interfere with the report download.
      <br />
      <p><b>Do not refresh or close this page, and do not log out of your current session.</b> This will prevent the report from downloading.</p>
      <br />
      <p>If the download fails, an error pop-up will show.</p>
    </Modal>
  }

  return (
    <Modal
      className='ClinicalListingModal'
      maskClosable={false}
      confirmLoading={isDataDownloadInProgress}
      footer={[
        <Button
          data-clinical-listing-prev-button-id={protocolId}
          key="prev"
          onClick={handlePrevious}
          disabled={isDataDownloadInProgress || step === 0}
        >
          Previous
        </Button>,
        <Button
          key="next"
          data-clinical-listing-next-button-id={protocolId}
          onClick={handleNext}
          disabled={(isDataDownloadInProgress || step === 2) ? true : false}
        >
          Next
        </Button>,
        <Button
          key="back"
          data-clinical-listing-cancel-button-id={protocolId}
          onClick={handleCancel}
          disabled={isDataDownloadInProgress}
        >
          Cancel
        </Button>,
        <Button
          disabled={isDataDownloadInProgress
                     || selectedProcedures.length === 0 }
          key="submit"
          data-clinical-listing-generate-report-button-id={protocolId}
          type="primary"
          onClick={generateReportAndDownload}
        >
          Generate Report
        </Button>
      ]}
      title={ <h2>Clinical Listing</h2> }
      open={isModalOpen}
      onOk={generateReportAndDownload}
      onCancel={handleCancel}
    >
      <ClinicalListingDialog {...{

        protocolId,

        protocolData,
        siteData,

        selectedSites,
        selectedSubjects,
        selectedProcedures,
        isAllSitesSelection,
        isAllSubjectsSelection,

        setSelectedSites,
        setIsAllSitesSelection,
        setSelectedSubjects,
        setIsAllSubjectsSelection,
        setSelectedProcedures,

        isDataDownloadInProgress,
        setIsDataDownloadInProgress,
        step,

      }}/>
    </Modal>
  );

};
const saveStream = async (
                          readableStream,
                          setIsDataDownloadInProgress,
                          setIsRequestConfirmed,
                          resetModal,
                          setIsModalOpen,
                          sendDataToClient) => {
  const file = await aggregateFileChunks(readableStream.body.getReader());

  setIsDataDownloadInProgress(false);
  setIsRequestConfirmed(false);
  resetModal();
  setIsModalOpen(false);

  sendDataToClient(file);
}

const prepareSubjectsForDisplay = async (setIsDataDownloadInProgress, siteIds, protocolId, setSubjectRecords) => {
  setIsDataDownloadInProgress(true);
  // !isAllSites or isAllSites. don't care
  const requests = siteIds.map(siteId => getSubjects({protocolId, siteId}));
  const results = await Promise.all(requests);

  const sortedSubjectRecords = results.flat();
  sortedSubjectRecords.sort((a, b) => a.display_id[0].value.localeCompare(b.display_id[0].value));

  setSubjectRecords(sortedSubjectRecords);
  setIsDataDownloadInProgress(false);
};

