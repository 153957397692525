import { ApiScopeAssociateEditor } from "./ApiScopeAssociateEditor";

export const ApiScopeAssociateContainer = props => {

  const {
    portalUtils,
    apiAssociateScopeData,
    resetAssociateScopeData,
  } = props;

  if(!apiAssociateScopeData) {
    return null;
  }

  return portalUtils.attachAdminApiScopeAssociateContent(
    <ApiScopeAssociateEditor {...{
      apiAssociateScopeData,
    }}/>,
    apiAssociateScopeData,
    resetAssociateScopeData,
  );


};
