import { Version } from './Version';

export const VersionContainer = (props) => {
  const {
    portalUtils,
    versionData,
    resetVersionData
  } = props;

  if (!versionData || !versionData.data) {
    return null;
  }

  const { protocol_id, protocol_number, branch_list, is_edit_allowed } = versionData.data;
  const versionComponent = (
    <Version
      protocolId={protocol_id}
      protocolNumber={protocol_number}
      branches={branch_list}
      isEditAllowed={is_edit_allowed}
    />
  );

  return portalUtils.attachVersionContent(
    versionComponent,
    versionData.data,
    resetVersionData,
  );
};
