import { useCreateFieldReview } from '../../hook/http-restapi/useCreateFieldReview';
import { FieldReviewActionText, FieldReviewAction, FieldReviewBadgeText } from './constants';

export const CreateFieldReviewButton = props => {
  const {
    context,
    fieldDefinitionId,
    userData,
    protocolUserRole,
    isActive,
  } = props;

  const fieldReviewCreateHook = useCreateFieldReview(
    {
      ...context,
      fieldDefinitionId
    },
    {
      date: Date.now(),
      action: FieldReviewAction.Review,
      author: {
        email: userData?.user?.email,
        firstName: userData?.user?.first_name,
        lastName: userData?.user?.last_name,
        role: protocolUserRole
      }
    }
  );

  const createFieldReviewRequest = () => {
    if (fieldReviewCreateHook.isLoading) {
      return null;
    };
    
    return fieldReviewCreateHook.mutate()
  };

  return (
    <div
      id='create-field-review-button'
      data-testid='create-field-review-button-container'
    >
      {isActive ?
        <div
          className='field-review-create-button reviewable-field-create-button'
          id={`reviewable-field-create-button-${fieldDefinitionId}`}
          onClick={createFieldReviewRequest}
        >
          {FieldReviewActionText.MarkReviewed}
        </div>
        : FieldReviewBadgeText.Unreviewed
      }
    </div>
  );
};

export default CreateFieldReviewButton;