export const protocolDefinitionFromData = (protocolData,
                                     protocolId, protocolVersionName) =>
  protocolData.protocol_list.find(
    v => v.protocol_id === protocolId
    && v.protocol_branch === protocolVersionName);

export const procedureDefinitionFromProtocolDefinition = (
                                         protocolDefinition, procedureId) =>
  protocolDefinition.study.procedure.find( p => p.id === procedureId);

export const procedureDefinitionFromData = (protocolData,
                             protocolId, protocolBranchName, procedureId) => {
  const protocolDefinition = protocolDefinitionFromData(protocolData,
                                             protocolId, protocolBranchName );
  return procedureDefinitionFromProtocolDefinition(protocolDefinition,
                                                   procedureId)
};

export const dataEntryFromProcedureDefinition = procedureDefinition => {
  return procedureDefinition?.dataentry
                    || procedureDefinition?.collection?.[0]?.dataentry;
};

export const fieldDefinitionFromProcedureDefinition = ( procedureDefinition,
                                                  fieldDefinitionId ) => {
  const dataEntry = dataEntryFromProcedureDefinition(procedureDefinition);

  for (const dataEntryType in dataEntry) {

    const fieldDefinition = dataEntry
            [dataEntryType].find( f => f.id === fieldDefinitionId);

    if(fieldDefinition) {
      return fieldDefinition;
    }

    for(const fieldDefinition of dataEntry[dataEntryType]){

      if('row' in fieldDefinition){
        const fieldRowDefinition = fieldDefinition.row.find( rowDefinition => rowDefinition.id === fieldDefinitionId );
        if(fieldRowDefinition){
          return fieldDefinition;
        }
      }

      if('column' in fieldDefinition){ // eg. preg > Sample Type
        const fieldColumnDefinition = fieldDefinition.column.find( columnDefinition => columnDefinition.id === fieldDefinitionId );
        if(fieldColumnDefinition){
          return fieldDefinition;
        }
      }
    }
  }
  throw new Error(`unable to find field definition '${fieldDefinitionId}'`);
};

export const fieldTextFromProtocolDefinitionFromData = (protocolData,
  protocolId, protocolBranchName, procedureId, fieldDefinitionId ) => {

  if(!protocolData) return '';

  const protocolDefinition = protocolDefinitionFromData(
    protocolData,
    protocolId,
    protocolBranchName,
  );

  let textLabel = '';
  protocolDefinition.study.procedure.forEach( proc => {
    if(proc.id === procedureId){
      // default to procedure name, even though function name promises fieldText
      textLabel = proc.name;
      proc.dataentry?.regular.forEach( reg => {
        if(reg.id === fieldDefinitionId){
          textLabel = reg.text;
        }
        if(reg.text === undefined){
          reg?.row?.forEach(rw => {
            if(rw.id === fieldDefinitionId){
              protocolDefinition.study.measurement.forEach(measure => {
                if(rw.measurement_id === measure.id){
                  textLabel = measure.name;
                }
              }); // forEach measure (protocolDefinition.study.measurement)
            }
          }); // forEach rw (dataentry.regular.row)
        }
      }); // forEach reg (dataentry.regular
    }
  }); // forEach proc (procedure)
  return textLabel;

};

export const fieldTextFromProtocolDefinitionFromSubjectLogProcedureData = (
  protocolData, protocolId, protocolBranchName, procedureId, fieldDefinitionId
                                                                        ) => {

  if(!protocolData) return '';

  const protocolDefinition = protocolDefinitionFromData(
    protocolData,
    protocolId,
    protocolBranchName,
  );
  let textLabel = '';
  protocolDefinition.study.procedure.forEach( proc => {
    if(proc.id === procedureId){
      textLabel = proc.name;
      proc.collection[0].dataentry.regular.forEach( reg => {
        if(reg.id === fieldDefinitionId){
          textLabel = reg.text;
        }
      })
    }
  })
  return textLabel;

};

export const isEdcFromProtocolData = (protocolData, protocolVersionName) => {

  const protocol = protocolData.protocol_list.find(v => v.protocol_branch === protocolVersionName)

  if('edc' in protocol.study.protocolDetails){
    return protocol.study.protocolDetails.edc;
  }
  // assert: edc not there
  return true;

};

export const isEdcFromProtocolDataProtocolId = (protocolData, protocolId) => {

  const protocol = protocolData.protocol_list.find(v => v.protocol_id === protocolId)

  if(protocol && 'edc' in protocol.study.protocolDetails){
    return protocol.study.protocolDetails.edc;
  }

  return true;

};

export const fieldDefinitionOrGridFromProcedureDefinition = ( procedureDefinition,
  fieldDefinitionId ) => {
  const dataEntry = dataEntryFromProcedureDefinition(procedureDefinition);

  for (const dataEntryType in dataEntry) {

    const fieldDefinition = dataEntry[dataEntryType].find( f => f.id === fieldDefinitionId);

    if (fieldDefinition) {
      return fieldDefinition;
    }

    for (const fieldDefinition of dataEntry[dataEntryType]){

      if ('row' in fieldDefinition){
        const fieldRowDefinition = fieldDefinition.row.find( rowDefinition => rowDefinition.id === fieldDefinitionId );
        if(fieldRowDefinition){
          return fieldRowDefinition;
        }
      }

      if ('column' in fieldDefinition){
        const fieldColumnDefinition = fieldDefinition.column.find( columnDefinition => columnDefinition.id === fieldDefinitionId );
          if (fieldColumnDefinition){
            return fieldColumnDefinition;
        }
      }
    }
  }
  throw new Error(`unable to find field definition '${fieldDefinitionId}'`);
};

export const isProtocolDefinedAsEdcStudy = ({
  protocolData,
  siteInfo,
}) => {
  const protocolVersionName =  siteInfo.protocolVersionName;
  const protocolDefinition = protocolDefinitionFromData(
    protocolData,
    siteInfo.protocol_id,
    protocolVersionName,
  );
  if(!('edc' in (protocolDefinition.study.protocolDetails))) {
    return true;
  }
  return protocolDefinition.study.protocolDetails.edc;

};

export const isProcedureDefinitionHasFieldAlias = (procedureDefinition) => (
  (procedureDefinition?.dataentry?.regular ?? []).some(
    fieldDefinition => 'alias' in fieldDefinition
      || (fieldDefinition?.row ?? []).some((rowItem) => 'alias' in rowItem)
  )
);
